/** @flow */
import React, { useState } from 'react'
import { rootContextMenu } from 'app/main/rootContainers'
import { Link } from 'react-router-dom'
import innerText from 'react-innertext'
import cx from 'classnames'
import { ContextMenu, type ContextMenuItemProps } from 'app/components/ContextMenu'
import type { ID, IconType } from 'app/core/types'
import { colors } from 'app/styles/colors'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'

import FontIcon from '../FontIcon/FontIcon.jsx'
import classes from './TreeRow.module.scss'
import LoaderSmall from '../LoaderSmall/LoaderSmall.jsx'

export type Node = {|
  id: ID,
  state?: { [stateKey: string]: any },
  children?: Array<Node>,

  depth: number,
  isExpanded: boolean,
  isDefaultExpand: boolean,
  selected: boolean,
  expand: () => void,
  unexpand: () => void,
  onClick: () => void,
  asyncRequested: boolean,
  getAsyncChildren: () => Promise<any>,

  data: {|
    id: ID,
    label: ?React$Node,
    parentId?: ?ID,
    metadata?: Object,
    contextMenuItems?: Array<ContextMenuItemProps>,
    icon?: IconType,
    displayArrow?: React$Node,
    to?: string,
    removed?: boolean,
    children?: Array<$ElementType<Node, 'data'>>,
    isRoot: boolean,
  |},
|}

export type TreeRowProps = {|
  node: Node,
  style: Object,
|}

export function TreeRow(props: TreeRowProps): React$Node {
  const { node, style } = props
  const {
    id,
    depth,
    isExpanded,
    selected,
    expand,
    unexpand,
    onClick,
    data,
    getAsyncChildren,
    children,
    asyncRequested,
  } = node
  const { label, icon, displayArrow, to, removed, contextMenuItems } = data

  const [isLoading, setIsLoading] = useState(false)

  function onClickArrow() {
    if (isExpanded) {
      unexpand()
    } else if (!asyncRequested) {
      setIsLoading(true)
      getAsyncChildren().then(() => setIsLoading(false))
    } else {
      expand()
    }
  }

  function onContextMenu(event: SyntheticMouseEvent<>) {
    event.preventDefault()
    const { pageX, pageY } = event

    if (!contextMenuItems || contextMenuItems.length === 0) return

    setTimeout(() => {
      rootContextMenu.render(<ContextMenu clientX={pageX} clientY={pageY} items={contextMenuItems} />)
    }, 0)
  }

  const El = to ? Link : 'div'

  return (
    <div
      style={{ ...style, paddingLeft: depth * 25 }}
      data-id={id}
      onContextMenu={onContextMenu}
      className={cx(classes.container, {
        [classes.highlighted]: selected,
      })}
    >
      {children || getAsyncChildren ? (
        <div className={classes.arrowContainer} onClick={() => onClickArrow()}>
          {displayArrow ? (
            <FontIcon
              className={classes.arrow}
              icon={!isExpanded ? 'fas-caret-right' : 'fas-caret-down'}
              style={{ fontSize: 15, color: colors.grey }}
            />
          ) : null}
        </div>
      ) : (
        <div className={classes.ghostArrow} />
      )}

      <El
        className={cx(classes.label, {
          [classes.removed]: removed,
        })}
        to={to || ''}
        data-testid={cyLabelFormater('treeItem', id || label)}
        onClick={() => onClick()}
        style={removed ? { textTransform: '' } : undefined}
        title={innerText(label)}
      >
        {isLoading ? (
          <div className={classes.iconContainer}>
            <LoaderSmall style={{ marginRight: 9 }} />
          </div>
        ) : (
          <div>
            {icon ? (
              <FontIcon className={classes.icon} icon={icon} style={{ marginRight: 6 }} width={17} height={14} />
            ) : null}
          </div>
        )}
        <div style={{ width: `calc(100% - ${icon ? '22px' : ''})` }}>{label}</div>
      </El>
    </div>
  )
}
