/** @flow */

import React, { useEffect, useMemo, useState } from 'react'
import { find } from 'lodash'
import type { ID, AssetLinkTypes, AssetTypes, Category } from 'app/core/types'
import store from 'app/store/index.ts'
import resources from 'app/store/resources'
import { MUIButton } from 'app/components/Form'
import { getAssetsAttributValues } from 'app/store/selectors/getAssetsAttributValues'
import { sortCategories } from 'app/pages/Project/Breakdown/utils'
import { NODE_DIRECTORY, NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import classes from './SettingsFollowUp.module.scss'

type Props = {|
  assetType: ?AssetTypes,
  projectId: ID,
|}

export function AssetLinks(props: Props): React$Node {
  const { projectId, assetType } = props

  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [assetLinkType, setAssetLinkType] = useState()
  const [aggregated, setAggregated] = useState(false)
  const toggleAggregated = () => setAggregated(!aggregated)

  useEffect(() => {
    const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }
    Promise.all([
      resources.assetsCustomAttributValues.fetch('category', requestsConfig),
      resources.assetLinkTypes.fetchAll(requestsConfig),
    ]).then(() => {
      const state = store.getState()
      const attributCategory = getAssetsAttributValues(state, 'category')
      const categories: Array<string> | Array<Category> = sortCategories(attributCategory)
      setCategories(categories)
      const assetLinkTypes: AssetLinkTypes = find(state.assetLinkTypes.resources, { name: 'breakdown' })
      setAssetLinkType(assetLinkTypes)
      setIsLoading(false)
    })
  }, [])

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: categories.flatMap((category) => {
        if (typeof category === 'object') {
          return [
            new Node({
              key: category.name,
              name: 'aggregated breakdown',
              parent: rootNode,
              icon: 'fas-grip-vertical',
              data: category,
              type: NODE_ITEM,
            }),
            new Node({
              key: `${category.name}-count`,
              name: 'aggregated breakdown (count)',
              parent: rootNode,
              icon: 'fas-grip-vertical',
              data: {
                ...category,
                length: true,
              },
              type: NODE_ITEM,
            }),
          ]
        }

        const data = (count?: true) => ({
          category,
          assetLinkType,
          id: assetLinkType?.id,
          name: `breakdown - ${aggregated ? 'aggregated ' : ''}${category}${count ? ' (count)' : ''}`,
          type: aggregated ? 'aggregation' : 'assetLinks',
          parentType: 'assetLinks',
          attr: 'assetLinks',
          columnType: `breakdown - ${aggregated ? 'aggregated ' : ''}${category}${count ? ' (count)' : ''}`,
          aggregateType: aggregated ? 'assetLink' : undefined,
          aggregateDatas: aggregated ? {} : undefined,
          length: count || undefined,
        })

        const categoryData = data()
        const categoryDataCount = data(true)

        return [
          new Node({
            key: category,
            name: categoryData.name,
            parent: rootNode,
            icon: 'fas-grip-vertical',
            data: categoryData,
            type: NODE_ITEM,
          }),
          new Node({
            key: `${category}-count`,
            name: categoryDataCount.name,
            parent: rootNode,
            icon: 'fas-grip-vertical',
            data: categoryDataCount,
            type: NODE_ITEM,
          }),
        ]
      }),
    })

    return rootNode
  }, [categories.length, assetLinkType, assetType, aggregated])

  return (
    <div className={classes.AttributesBlockContainer} style={{ padding: '0 10px 10px 10px' }}>
      <div className="fullWidth flex end">
        {['fo', 'ep'].includes(assetType) ? (
          <MUIButton
            tooltip="Aggregated attributes"
            onClick={toggleAggregated}
            color={aggregated ? 'primary' : undefined}
          >
            Aggregated
          </MUIButton>
        ) : null}
      </div>
      {isLoading ? null : <TreeDnD rootNode={rootNode} />}
    </div>
  )
}
