import moment from 'moment';
class Datetime extends HTMLElement {
    constructor() {
        super();
        const date = this.getAttribute('value');
        this.shadow = this.attachShadow({ mode: 'open' });
        const formattedDate = moment(date).format('DD/MM/YYYY [à] h:mm:ss');
        this.shadow.innerHTML = `<span>${formattedDate}</span>`;
    }
}
export const datetime = { customElementName: 'date-time', customElementClass: Datetime };
