var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cx from 'classnames';
import { cyLabelFormater } from 'app/libs/helpers/cyTools.js';
import classes from './Toggle.module.scss';
function Option({ option: { value, label, disabled }, currentValue, className }) {
    return _jsx("div", { className: cx(className, { [classes.active]: currentValue === value }), children: label });
}
const Toggle = (_a) => {
    var { value, onChange, option1, option2, size, style, className, dataCy } = _a, rest = __rest(_a, ["value", "onChange", "option1", "option2", "size", "style", "className", "dataCy"]);
    const onClick = (event) => {
        const newValue = value !== (option1 === null || option1 === void 0 ? void 0 : option1.value) ? option1 === null || option1 === void 0 ? void 0 : option1.value : option2 === null || option2 === void 0 ? void 0 : option2.value;
        onChange(newValue, event);
    };
    const isDisabled = () => {
        return Boolean((value === (option1 === null || option1 === void 0 ? void 0 : option1.value) && (option1 === null || option1 === void 0 ? void 0 : option1.disabled)) || (value === (option2 === null || option2 === void 0 ? void 0 : option2.value) && (option2 === null || option2 === void 0 ? void 0 : option2.disabled)));
    };
    const styleBtn = value === (option1 === null || option1 === void 0 ? void 0 : option1.value) ? {} : { width: '100%' };
    const color = value === (option1 === null || option1 === void 0 ? void 0 : option1.value) ? option1 === null || option1 === void 0 ? void 0 : option1.color : option2 === null || option2 === void 0 ? void 0 : option2.color;
    const _style = Object.assign(Object.assign({ backgroundColor: color, borderColor: color }, style), { height: 30 });
    const _className = cx(classes.toggle, {
        [classes.disabled]: isDisabled(),
        [classes.small]: size === 'small',
        className,
    });
    return (_jsxs("div", Object.assign({ "data-testid": cyLabelFormater('toggle', dataCy || ''), className: _className, onClick: onClick, style: Object.assign({}, _style) }, rest, { children: [_jsx("div", { className: classes.btnContainer, style: styleBtn, children: _jsx("div", { className: classes.btn }) }), _jsxs("div", { className: classes.ghost, children: [_jsx("div", { className: classes.btnContainer, children: _jsx("div", { className: classes.btn }) }), (option1 === null || option1 === void 0 ? void 0 : option1.label) ? _jsx("div", { className: classes.ghostLabel, children: option1.label }) : null] }), _jsxs("div", { className: classes.ghost, children: [_jsx("div", { className: classes.btnContainer, children: _jsx("div", { className: classes.btn }) }), (option2 === null || option2 === void 0 ? void 0 : option2.label) ? _jsx("div", { className: classes.ghostLabel, children: option2.label }) : null] }), option1 ? _jsx(Option, { className: classes.option1, option: option1, currentValue: value }) : null, option2 ? _jsx(Option, { className: classes.option2, option: option2, currentValue: value }) : null] })));
};
export default Toggle;
