// @flow
import * as React from 'react'
import cx from 'classnames'
import { Menu } from 'app/components/Menu/Menu.jsx'
import FontIcon from '../FontIcon/FontIcon.jsx'
import type { ModulableTreeProps } from './ModulableTree.jsx'
import defaultClasses from './ModulableTree.module.scss'
import { Tooltip } from '../Tooltip/Tooltip.jsx'
import { cyLabelFormater } from '../../libs/helpers/cyTools.js'

export class ModulableTreeActions extends React.PureComponent<ModulableTreeProps> {
  classes: { [key: string]: string } = {
    // prettier-ignore

    ...defaultClasses,
    ...(this.props.treeProps ? this.props.treeProps.classes : {}),
    ...this.props.classes,
  }

  defaultIcons: { [key: string]: React$Node } = {
    add: <FontIcon icon="fas-plus" />,
    edit: <FontIcon icon="fas-pencil-alt" />,
    delete: <FontIcon icon="trash" />,
  }

  renderActions(): Array<any> {
    const { actions, compactMenuToLength = 3 } = this.props
    const menuFormat = actions && actions.length > compactMenuToLength

    return !actions
      ? []
      : actions.map((action) => {
          switch (action.key) {
            case 'add':
              return {
                tooltip: 'Add',
                label: (
                  <div className={cx(menuFormat && this.classes.actionsLabelContainer)}>
                    {this.defaultIcons.add} {menuFormat && <span style={{ marginLeft: 10 }}>Add</span>}
                  </div>
                ),
                key: 'modulable-tree-actions-add',
                'data-testid': 'modulable-tree-actions-add',
                ...action,
              }
            case 'edit':
              return {
                tooltip: 'Edit',
                label: (
                  <div className={cx(menuFormat && this.classes.actionsLabelContainer)}>
                    {this.defaultIcons.edit} {menuFormat && <span style={{ marginLeft: 10 }}>Edit</span>}
                  </div>
                ),
                key: 'modulable-tree-actions-edit',
                'data-testid': 'modulable-tree-actions-edit',
                ...action,
              }
            case 'delete':
              return {
                tooltip: 'Delete',
                label: (
                  <div className={cx(menuFormat && this.classes.actionsLabelContainer)}>
                    {this.defaultIcons.delete} {menuFormat && <span style={{ marginLeft: 10 }}>Delete</span>}
                  </div>
                ),
                key: 'modulable-tree-actions-delete',
                'data-testid': 'modulable-tree-actions-delete',
                ...action,
              }
            default:
              return action
          }
        })
  }

  render(): React$Node {
    const actions = this.renderActions()
    const { compactMenuToLength = 3 } = this.props

    return (
      <div className={this.classes.actionsMenuIcon}>
        {actions && actions.length > compactMenuToLength ? (
          <Menu
            actions={this.renderActions()}
            button={<FontIcon className={this.classes.menuIconContainer} icon="fas-ellipsis-v" />}
          />
        ) : (
          <div className={this.classes.actionsIconsContainer}>
            {actions &&
              actions.map((action, index) => {
                const { onClick, label, tooltip, key, ...rest } = action

                const Tt = tooltip ? Tooltip : 'div'

                return onClick ? (
                  <Tt title={tooltip} key={String(index)}>
                    <div
                      onClick={onClick}
                      data-testid={cyLabelFormater('modulableTree', action.label || action.tooltip)}
                      className={this.classes.actionsIconContainer}
                      {...rest}
                    >
                      {label}
                    </div>
                  </Tt>
                ) : (
                  <Tt
                    key={key || String(index)}
                    data-testid={cyLabelFormater('modulableTree', action.label || action.tooltip)}
                    title={tooltip}
                    {...rest}
                  >
                    {label}
                  </Tt>
                )
              })}
          </div>
        )}
      </div>
    )
  }
}
