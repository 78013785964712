/** @flow */
import { resourceReducer } from 'redux-resource'
import { paginatedList } from 'app/libs/redux-resource-plugins/paginated-list'
import includedResources from 'app/libs/redux-resource-plugins/includedResources'
import * as resources from '../resources/resources'

import user from './user'
import project from './project'
import assetTalks from './assetTalks'
import notifsCount from './notifsCount'
import organizationChart from './organizationChart.ts'
import calculatedFields from './calculatedFields.ts'
import projectConfig from './config.ts'
import library from './library.ts'
import externalJobs from './externalJobs.ts'
import lockedFilters from './lockedFilters.ts'
import collectiveAgreementTitles from './collectiveAgreementTitles.ts'
import leaves from './leaves.ts'
import allUsersWithActivities from './usersWithActivities.ts'
import tablesPrefs from './tablesPrefs.ts'
import activitiesApprovals from './activitiesApprovals.ts'
import reduxActivities from './reduxActivities.ts'
import taskTypes from './taskTypes.ts'

const reducersFromResources: Object = {}

Object.entries(resources).forEach(([key, value]) => {
  reducersFromResources[key] = resourceReducer(key, {
    plugins: [includedResources, paginatedList],
    initialState: {
      paginatedLists: {},
    },
  })
})

export default {
  user,
  project,
  assetTalks,
  notifsCount,
  organizationChart,
  calculatedFields,
  projectConfig,
  library,
  externalJobs,
  lockedFilters,
  collectiveAgreementTitles,
  leaves,
  allUsersWithActivities,
  tablesPrefs,
  activitiesApprovals,
  reduxActivities,
  taskTypes,
  ...reducersFromResources,
}
