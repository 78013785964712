var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
import { setLeaves } from './leaves';
import { setActivitiesApprovals } from './activitiesApprovals';
import { setActivities } from './reduxActivities';
const initialState = {
    fetchLoading: false,
    data: [],
    userData: [],
    count: 0,
    error: null,
    stats: null,
    statsIsLoading: false,
    httpProgress: 0,
};
export const fetchAllUsersActivities = createAsyncThunk('allUsersActivities/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.groups.allUsersWithActivities({}, Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }), {}, (progress) => {
            dispatch(setHttpProgress(progress));
        });
        const results = res.results;
        const leaves = results.flatMap((user) => user.leavesInst);
        const activities = results.flatMap((user) => user.activitiesInst);
        const activitiesApprovals = results.flatMap((user) => user.activitieApprovalsInst);
        dispatch(setActivitiesApprovals(activitiesApprovals));
        dispatch(setLeaves(leaves));
        dispatch(setActivities(activities));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchUsersActivities = createAsyncThunk('usersActivities/fetchAll', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ id, queries }, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.groups.usersWithActivities({ id: id !== null && id !== void 0 ? id : '' }, Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }), {}, (progress) => {
            dispatch(setHttpProgress(progress));
        });
        const results = res.results;
        const leaves = results.flatMap((user) => user.leavesInst);
        const activities = results.flatMap((user) => user.activitiesInst);
        const activitiesApprovals = results.flatMap((user) => user.activitieApprovalsInst);
        dispatch(setActivitiesApprovals(activitiesApprovals));
        dispatch(setLeaves(leaves));
        dispatch(setActivities(activities));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchAllUsersActivitiesStats = createAsyncThunk('allUsersActivities/fetchAllStats', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.groups.allUsersWithActivities({}, Object.assign(Object.assign({}, queries), { statMode: 'statsOnly' }));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchUsersActivitiesStats = createAsyncThunk('usersActivities/fetchAllStats', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ id, queries }, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.groups.usersWithActivities({ id }, Object.assign(Object.assign({}, queries), { statMode: 'statsOnly' }));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchMyActivities = createAsyncThunk('myActivities/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue, dispatch }) {
    try {
        const res = yield api.users.myActivities({}, Object.assign(Object.assign({}, queries), { statMode: 'datasOnly' }));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'usersWithActivities',
    initialState,
    reducers: {
        setAllUsersActivities: (state, { payload }) => {
            state.data = payload;
        },
        setHttpProgress: (state, { payload }) => {
            return Object.assign(Object.assign({}, state), { httpProgress: payload });
        },
    },
    extraReducers: (builder) => {
        // fetch all users activities
        builder.addCase(fetchAllUsersActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllUsersActivities.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.count = payload.count;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllUsersActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // fetch users activities
        builder.addCase(fetchUsersActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchUsersActivities.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.count = payload.count;
            state.fetchLoading = false;
        });
        builder.addCase(fetchUsersActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // fetch my activities
        builder.addCase(fetchMyActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchMyActivities.fulfilled, (state, { payload }) => {
            state.userData = payload.results;
            state.count = payload.count;
            state.fetchLoading = false;
        });
        builder.addCase(fetchMyActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // fetch all users activities stats
        builder.addCase(fetchAllUsersActivitiesStats.pending, (state) => {
            state.statsIsLoading = true;
        });
        builder.addCase(fetchAllUsersActivitiesStats.fulfilled, (state, { payload }) => {
            state.stats = payload.stats;
            state.statsIsLoading = false;
        });
        builder.addCase(fetchAllUsersActivitiesStats.rejected, (state, { payload }) => {
            state.error = payload;
            state.statsIsLoading = false;
        });
        // fetch users activities stats
        builder.addCase(fetchUsersActivitiesStats.pending, (state) => {
            state.statsIsLoading = true;
        });
        builder.addCase(fetchUsersActivitiesStats.fulfilled, (state, { payload }) => {
            state.stats = payload.stats;
            state.statsIsLoading = false;
        });
        builder.addCase(fetchUsersActivitiesStats.rejected, (state, { payload }) => {
            state.error = payload;
            state.statsIsLoading = false;
        });
    },
});
export const { setAllUsersActivities, setHttpProgress } = actions;
export default reducer;
