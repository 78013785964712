/** @flow */
import React, { useEffect, useMemo, useState } from 'react'
import { map } from 'lodash'
import type { Step, ID, Asset, Option, Group, GroupUser, ResourcesList, AssetTypes } from 'app/core/types'
import type { TableInstance } from 'app/components/Table/types'
import { MUIModal, ModalTrigger, type ModalProps } from 'app/components/Modal'
import { MUIAutocomplete, MUIButton } from 'app/components/Form'
import resources from 'app/store/resources'
import { ModalAssetsRelations } from 'app/containers/AssetsRelations/ModalAssetsRelations.jsx'
import { getResources } from 'app/store/selectors'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import api from 'app/core/api/api.js'

import { filtersToQueries } from '../../hooks/useFiltersUtils'
import classes from './FastScheduler.module.scss'

let timer: TimeoutID | void

type Props = {|
  instance: TableInstance,
  defaultStep: Step,
  rootAsset: ID,
  assetType: AssetTypes,
  projectId?: ID,
  ...$Rest<ModalProps, { children: React$Node }>,
|}

export function FastScheduler(props: Props): React$Node {
  const { defaultStep, instance, rootAsset, assetType, projectId = getProjectIdFromURL(), ...modalProps } = props

  const [steps, setSteps] = useState<Array<Option>>([{ label: defaultStep.name, value: defaultStep.id }])
  const [users, setUsers] = useState<Array<Asset>>([])
  const [workers, setWorkers] = useState<Array<number>>([])
  const [dichotomyIsLoading, setDichotomyIsLoading] = useState(false)
  const [estimatedWorkers, setEstimatedWorkers] = useState<$Exact<{ workers: number, errors: number }> | void>()

  function onSearchStep(input: string): Promise<Array<Option>> {
    return resources.steps.search({ query: input }).then((res) => {
      const steps: Array<Step> | void = res?.resources
      if (!steps) return []
      return map(steps, (step) => ({ label: step.name, value: step.id }))
    })
  }

  function addUsers(_newUsers: Array<Asset>) {
    const newUsers = _newUsers.filter((user) => !users.find((u) => u.id === user.id))

    if (newUsers.length < 1) return

    setUsers([...users, ...newUsers])
    setWorkers([...workers, ...newUsers.map((_) => 100)])
  }

  function addGroup(groups: Array<Group>) {
    const config = {
      params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' }, queries: { page_size: 1000 } },
    }

    return Promise.all(groups.map((group) => resources.groupUsers.fetchAllByGroup(group.id, config))).then((allRes) => {
      groups.forEach((group) => {
        const groupUsers: ResourcesList<GroupUser> = getResources(undefined, `groupUsers`, { group: group.id }, [
          'userInst',
        ])

        const newUsers = map(groupUsers, (groupUser) => groupUser.userInst).filter(
          (user) => !users.find((u) => u.id === user.id),
        )

        if (newUsers.length < 1) return

        setUsers([...users, ...newUsers])
        setWorkers([...workers, ...newUsers.map((_) => 100)])
      })
    })
  }

  function removeUser(index: number) {
    const newUsers = [...users]
    const newWorkers = [...workers]
    newUsers.splice(index, 1)
    newWorkers.splice(index, 1)
    setUsers(newUsers)
    setWorkers(newWorkers)
  }

  function changeWorker(index: number, value: number) {
    const newWorkers = [...workers]
    newWorkers[index] = Number(value)
    setWorkers(newWorkers)
  }

  function renderStepTags(options: Array<Option>, getTagProps: Function) {
    return steps.map((option: Option, index: number) => {
      const tagProps = getTagProps({ index })
      const { onDelete, className, ...rest } = tagProps
      const { label } = option

      return (
        <div {...rest} key={label} className={`${classes.chip} ${className}`}>
          {label}
          <FontIcon icon="close" className="pointer paddingLeft8 fontSize13" onClick={() => onDelete(index)} />
        </div>
      )
    })
  }

  function getRequestParams() {
    const { filtersTools } = instance.getLastestInstance()
    const { filters } = filtersTools || {}

    const usersObj = workers.reduce((acc, value, index) => {
      if (users[index].id) return { ...acc, [users[index].id]: value / 100 }
      return acc
    }, {})

    const { queries, headers } = filtersToQueries(filters)
    return { usersObj, queries, headers }
  }

  function onValidate() {
    const { usersObj, queries, headers } = getRequestParams()

    return api.fastScheduler.run(
      { rootAsset, users: usersObj, steps: steps.map((s) => s.value), assetType },
      queries,
      headers,
    )
  }

  const totalWorker = useMemo(() => {
    const sumOfWorkers = workers.reduce((acc, perc) => acc + perc, 0)
    return Math.floor((sumOfWorkers / 100) * 10) / 10
  }, [workers])

  function calculateDichotomy() {
    const { usersObj, queries, headers } = getRequestParams()
    setDichotomyIsLoading(true)
    return api.fastScheduler
      .dichotomy({ rootAsset, users: usersObj, steps: steps.map((s) => s.value), assetType }, queries, headers)
      .then((res) => {
        setEstimatedWorkers(res)
        setDichotomyIsLoading(false)
      })
  }

  useEffect(() => {
    if (workers.length && steps.length) {
      clearTimeout(timer)
      timer = setTimeout(calculateDichotomy, 2000)
    }
  }, [workers, steps])

  return (
    <MUIModal
      title="Schedule tasks"
      width={800}
      height={800}
      resizable={true}
      draggable={true}
      onValidate={onValidate}
      {...modalProps}
    >
      <div style={{ maxHeight: '100%', height: '100%' }}>
        <MUIAutocomplete
          multiple={true}
          onSearch={onSearchStep}
          placeholder="Steps to schedule..."
          onChange={setSteps}
          value={steps}
          clearOnValidate={true}
          fullWidth={true}
          renderTags={renderStepTags}
          style={{ margin: 0, padding: 0 }}
          clearOnEscape={false}
        />
        <div className="marginTop20 flex fullWidth column" style={{ height: 'calc(100% - 64px)' }}>
          <div>
            <ModalTrigger
              modal={
                <ModalAssetsRelations
                  assetTypes={['us']}
                  placeholder="Select users"
                  inputProps={{ inputProps: { style: { padding: '0 5px' } } }}
                  assets={[]}
                  onSave={({ assets }) => {
                    addUsers(assets.map((asset) => asset.assetInst))
                    return Promise.resolve()
                  }}
                />
              }
            >
              <MUIButton
                style={{ color: 'grey', fontWeight: 'bolder', marginRight: 10 }}
                variant="text"
                icon="fas-plus"
              >
                Add user
              </MUIButton>
            </ModalTrigger>
            <ModalTrigger
              modal={
                <ModalAssetsRelations
                  assetTypes={['gp']}
                  placeholder="Select group"
                  inputProps={{ inputProps: { style: { padding: '0 5px' } } }}
                  assets={[]}
                  onSave={({ assets }) => addGroup(assets.map((asset) => asset.assetInst))}
                />
              }
            >
              <MUIButton style={{ color: 'grey', fontWeight: 'bolder' }} variant="text" icon="fas-plus">
                Add group
              </MUIButton>
            </ModalTrigger>
          </div>
          <div style={{ overflowY: 'auto', width: '100%' }}>
            <ModulableTree
              noItemsLabel={
                <div
                  className="padding10 flex center alignCenter lightgrey"
                  style={{ border: '1px solid rgba(0,0,0,0.1)' }}
                >
                  Select group or users
                </div>
              }
              treeProps={{
                centerContent: (index) => (
                  <div className="fullWidth">
                    <div className="flex end alignCenter row marginRight20">
                      <input
                        type="number"
                        step={5}
                        max={100}
                        min={0}
                        value={workers[index] || ''}
                        onChange={(e) => changeWorker(index, Number(e.target.value))}
                        className={classes.workersInput}
                        data-testid={`fastscheduler-worker-input-${index}`}
                      />
                      <span className="bold fontSize18">%</span>
                    </div>
                  </div>
                ),
              }}
              items={map(users, (user, index) => ({
                label: user.name,
                actions: [
                  {
                    key: 'delete',
                    onClick: () => removeUser(index),
                  },
                ].filter((_) => _),
              }))}
            />
          </div>
          <div className="flex row spaceBetween marginTop7">
            <div className="bold">Total workers:</div>
            <div className="bold fontSize18" style={{ marginRight: 90 }}>
              {totalWorker}
            </div>
          </div>
          <div className={classes.dichotomyContainer}>
            <div>
              <div className="bold">Optimal team size: {estimatedWorkers?.workers || '?'}</div>
              <div>Errors: {estimatedWorkers?.errors || '?'}</div>
            </div>
            <div>
              <MUIButton loader={dichotomyIsLoading} onClick={() => calculateDichotomy()}>
                Calculate
              </MUIButton>
            </div>
          </div>
        </div>
      </div>
    </MUIModal>
  )
}
