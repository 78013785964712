// @flow
/* eslint-disable import/first */

import React from 'react'
import moment from 'moment'
import 'whatwg-fetch'
import fr from 'moment/dist/locale/fr'
import './core/utils/btoa_atob.js'
import { rootContainer } from 'app/main/rootContainers.js'
import Main from './main/Main.jsx'
// import { initSentry } from './core/sentry/sentry.js'
import './styles/index.js'
import { customJSXElements } from './customJSXElements/index.ts'

customJSXElements.forEach(({ customElementName, customElementClass }) => {
  customElements.define(customElementName, customElementClass)
})

function initMoment() {
  moment.updateLocale('fr', fr)
}

// initSentry()
initMoment()

rootContainer.render(<Main />)
