// @flow
import React from 'react'
import { map, sortBy, startCase } from 'lodash'
import { Link } from 'react-router-dom'
import { getResources } from 'app/store/selectors/getResources'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { useSelector } from 'react-redux'
import classes from './Projects.module.scss'
import type { Asset } from '../../core/types/Asset'

function Projects(): React$Node {
  const projects: Array<Asset> = useSelector((state) => {
    const getProjects: Array<$ElementType<Node, 'data'>> = map(state.user.projects, (project) =>
      getResources(state, 'assets', project, ['thumbnailInst']),
    ).filter((_) => _)
    return sortBy(getProjects, ['name'])
  })

  return (
    <div className={classes.container} data-testid="page-project">
      <ul className={classes.projects}>
        {projects.map((project) => (
          <li key={project.id} className={classes.item} data-testid={`projects-project-${project.name}`}>
            <Link to={`/projects/${project.id}`}>
              <div className={classes.imgTop}>
                {project.thumbnailInst ? (
                  <div className={classes.img} style={{ backgroundImage: `url(${project.thumbnailInst.url})` }} />
                ) : (
                  <FontIcon icon="fas-image" className={classes.iconImage} />
                )}
              </div>
              <div className={classes.bottomContent}>
                <div className={classes.title}>
                  <div className={classes.itemName}>{startCase(project?.name)}</div>
                  {project?.attributes?.trigram ? (
                    <div className={classes.trigram}>{project.attributes.trigram.toUpperCase()}</div>
                  ) : null}
                </div>
                {project?.attributes?.season ? <div>Season: {project.attributes.season}</div> : null}
                {project?.attributes?.nbEpisodes ? <div>Nb of Episodes: {project.attributes.nbEpisodes}</div> : null}
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Projects
