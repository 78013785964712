/** @flow */
import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  RefObject,
  SyntheticEvent,
} from 'react'
import moment from 'moment'
import cx from 'classnames'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import type { IconType } from 'app/core/types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import type { IconType as TSIconType } from 'app/core/types/types'
import Validators from '../Validators/Validators.jsx'
import classes from './Input.module.scss'

// TS type
export type TSInputProps = {
  dataCy: string,
  value?: any,
  defaultValue?: any,
  onChange?: (event: SyntheticEvent, value: any) => void,
  validOnBlur?: boolean,
  isRequired?: boolean,
  multilines?: boolean,
  validatorStyle?: {},
  validators?: {},
  errors?: {}[],
  styleContainer?: {},
  type?: string,
  disabled?: boolean,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void,
  onKeyDown?: (event: KeyboardEvent) => void,
  className?: string,
  serverErrors?: {}[],
  contentRef?: RefObject<any>,
  inputRef?: RefObject<any>,
  componentRef?: RefObject<any>,
  // TO DO: check how to declare exact type with TS
  // validatorRef?: $Exact<{ current: RefObject<any> }>,
  validatorRef?: { current: RefObject<any> },
  adornmentIcon?: TSIconType,
  adornment?: ReactNode,
  adornmentWidth?: number,
  placeholder?: string,
  showDateDay?: boolean,
  resize?: boolean,
  alignRight?: boolean,
  name?: string,
  style?: {},
  min?: number | string | Date,
  max?: number | string | Date,
}

// Flow type
export type InputProps = {|
  dataCy: string,
  value?: mixed,
  defaultValue?: mixed,
  onChange?: (event: SyntheticInputEvent<>, value: ?mixed) => void,
  validOnBlur?: boolean,
  isRequired?: boolean,
  multilines?: boolean,
  validatorStyle?: Object,
  validators?: Object,
  errors?: Array<Object>,
  styleContainer?: Object,
  type?: string,
  disabled?: boolean,
  onBlur?: (event: SyntheticFocusEvent<>) => void,
  onFocus?: (event: SyntheticFocusEvent<>) => void,
  onKeyDown?: (event: SyntheticKeyboardEvent<>) => void,
  className?: string,
  serverErrors?: Array<Object>,
  contentRef?: React$ElementRef<any>,
  inputRef?: React$ElementRef<any>,
  componentRef?: React$ElementRef<any>,
  validatorRef?: $Exact<{ current: ?React$ElementRef<any> }>,
  adornmentIcon?: IconType,
  adornment?: React$Node,
  adornmentWidth?: number,
  placeholder?: string,
  showDateDay?: boolean,
  resize?: boolean,
  alignRight?: boolean,
  name?: string,
  style?: Object,
  min?: ?(number | string | Date),
  max?: ?(number | string | Date),
|}

function InputElement(props: InputProps) {
  const {
    validOnBlur = true,
    isRequired = false,
    multilines = false,
    type = 'text',
    validators = {},
    contentRef = null,
    validatorRef: _validatorRef,
    onBlur: _onBlur,
    onChange: _onChange,
    errors,
    className,
    dataCy,
    placeholder,
    styleContainer,
    validatorStyle,
    serverErrors,
    value,
    inputRef,
    componentRef,
    adornmentIcon,
    adornment,
    adornmentWidth,
    showDateDay,
    resize = false,
    alignRight = false,
    style,
    ...rest
  } = props

  const [error, setError] = useState(false)

  const $validatorRef = useRef()
  const validatorRef: React$ElementRef<any> = _validatorRef || $validatorRef

  function onBlur(event: SyntheticFocusEvent<>) {
    if (validOnBlur && validatorRef) validatorRef.current?.valid()
    if (_onBlur) _onBlur(event)
  }

  function onChange(event: SyntheticInputEvent<>) {
    let { value } = event.target
    if ((value || value === 0) && type === 'number') value = Number(value)
    if (type === 'number' && Number(value) > Number.MAX_SAFE_INTEGER) value = Number.MAX_SAFE_INTEGER
    return _onChange?.(event, value)
  }

  const propsInput = {
    'data-testid': cyLabelFormater('input', dataCy || placeholder),
    ref: inputRef,
    className: cx(classes.input, className, {
      [classes.error]: error || (errors && errors.length > 0),
      [classes.inputAdornmentIcon]: !adornmentWidth && (!!adornmentIcon || !!adornment),
      [classes.inputShowDateDay]: showDateDay,
      [classes.resize]: resize || multilines,
      [classes.alignRight]: alignRight,
    }),
    onBlur,
    onChange,
    value,
    placeholder,
  }

  const _style = {
    paddingRight: undefined,
    height: undefined,
    ...style,
  }
  if (adornmentWidth) _style.paddingRight = adornmentWidth

  let input
  if (multilines) {
    input = <textarea {...propsInput} {...rest} style={_style} />
  } else {
    input = <input {...propsInput} {...rest} type={type === 'datetime' ? 'datetime-local' : type} style={_style} />
  }

  useEffect(() => {
    if (componentRef) {
      const componentProperties = {
        valid: () => validatorRef.current.valid(),
      }
      if (typeof componentRef === 'function') componentRef(componentProperties)
      else if (componentRef?.current) componentRef.current = componentProperties
    }
  }, [componentRef])

  return (
    <div ref={contentRef} className={classes.container} style={{ height: _style.height, ...styleContainer }}>
      {showDateDay && value && typeof value === 'string' ? (
        <div className={classes.showDateDay}>{moment(value).format('dd')}</div>
      ) : undefined}
      {input}
      {adornmentIcon || adornment ? (
        <div className={classes.adornmentIcon} style={adornmentWidth ? { width: adornmentWidth } : undefined}>
          {adornment}
          {adornmentIcon ? <FontIcon icon={adornmentIcon} /> : null}
        </div>
      ) : null}
      <Validators
        ref={validatorRef}
        className={classes.fieldError}
        style={validatorStyle}
        value={value}
        errors={errors}
        onSuccess={() => setError(false)}
        onError={() => setError(true)}
        validators={{
          required: isRequired,
          email: type === 'email',
          URL: type === 'url',
          ...validators,
        }}
      />
    </div>
  )
}

export const Input: React$AbstractComponent<*, *> = forwardRef<InputProps, React$ElementRef<'input'>>((props, ref) => (
  <InputElement {...props} componentRef={ref} />
))
