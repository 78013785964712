var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { CellText, CellSelect } from 'app/components/Table/Cells';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLeave, updateLeave } from 'app/store/reducers/leaves';
import { TableRedux } from 'app/components/Table/TableRedux';
import { userPrefLocalResource } from '../../../../core/utils/localUserPrefs.js';
import { map, uniq } from 'lodash';
import { confirmDelete } from 'app/components/Modal';
import { leavesByUserAndDateRangeSelector, leavesByUserAndDateSelector } from 'app/store/selectors/leaves';
const TableTimeRecapLeaves = (props) => {
    const { date, userId, projects, tableHoursTools, formatView, selectedWeek } = props;
    const dispatch = useDispatch();
    const tableId = 'table-time-recap-leaves';
    const table = userPrefLocalResource.getData('tables')[tableId];
    const tableUserPrefs = useSelector((state) => state.tablesPrefs.data[tableId]);
    const userProjects = useSelector((state) => state.user.projects);
    const { fetchLoading, count } = useSelector((state) => state.leaves);
    const userLeaves = useSelector(selectedWeek && formatView
        ? leavesByUserAndDateRangeSelector(userId, moment(selectedWeek).startOf(formatView).format('YYYY-MM-DD'), moment(selectedWeek).endOf(formatView).format('YYYY-MM-DD'))
        : leavesByUserAndDateSelector(userId, date));
    const deleteLeavesAction = (instance, cell) => {
        const { selectedCells } = instance.getLastestSelection();
        const toDeleteActivities = map(selectedCells, (cell) => cell.row.original.id);
        const nb = toDeleteActivities.length;
        return {
            label: `Delete ${nb > 1 ? `${nb} ` : ''}leave${nb > 1 ? 's' : ''}`,
            editAction: true,
            onClick: (event) => {
                const toDelete = uniq(map(selectedCells, (cell) => cell.row.original.id));
                return confirmDelete({
                    render: `Are you sur you want to delete ${toDelete.length > 1 ? 'these' : 'this'} leave${toDelete.length > 1 ? 's' : ''} ?`,
                    onValidate: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield dispatch(deleteLeave(toDelete.length > 1 ? toDelete : toDelete[0]));
                    }),
                    validateMessage: `Leave${toDelete.length > 1 ? 's' : ''} deleted`,
                });
            },
        };
    };
    const columns = useMemo(() => [
        {
            Header: 'Leaves',
            id: 'leaves',
            columns: [
                CellSelect({
                    id: 'status',
                    Header: 'Status',
                    hiddenable: true,
                    noFilterOption: true,
                    // readOnly: true,
                    accessor: 'approvalStatus',
                    width: 110,
                    actions: (instance, cell) => [!formatView && 'edit', deleteLeavesAction(instance, cell)],
                    RenderRead: (cell, value) => {
                        let content;
                        let bgColor;
                        let color;
                        if (value === 0) {
                            content = 'Waiting';
                            bgColor = '#eeb413';
                            color = 'white';
                        }
                        else if (value === 1) {
                            content = 'Approved';
                            bgColor = 'rgb(84, 170, 255)';
                            color = 'inherit';
                        }
                        else {
                            bgColor = 'rgb(84, 170, 255)';
                            color = 'inherit';
                        }
                        return (_jsx("div", { style: {
                                backgroundColor: bgColor,
                                color,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }, children: content }));
                    },
                    options: () => [
                        { label: 'Waiting', value: 0 },
                        { label: 'Approved', value: 1 },
                        { label: 'Refused', value: 2 },
                    ].map((status) => {
                        return status;
                    }),
                    customSave: (newValue, data) => {
                        const newData = Object.assign(Object.assign({}, data), { approvalStatus: newValue });
                        dispatch(updateLeave(newData));
                    },
                }),
                CellText({
                    id: 'startDate',
                    Header: 'Start Date',
                    hiddenable: true,
                    noFilterOption: true,
                    readOnly: false,
                    actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                    accessor: (item) => {
                        const startDate = Number(moment.utc(item.startDate).format('HH'));
                        let dayPart = '';
                        if (startDate > 8)
                            dayPart = 'p.m.';
                        return `${moment.utc(item.startDate).format('YYYY-MM-DD')} ${dayPart}`;
                    },
                    width: 130,
                }),
                CellText({
                    id: 'endDate',
                    Header: 'End Date',
                    hiddenable: true,
                    noFilterOption: true,
                    readOnly: false,
                    actions: (instance, cell) => [deleteLeavesAction(instance, cell)],
                    accessor: (item) => {
                        const endDate = Number(moment.utc(item.endDate).format('HH'));
                        let dayPart = '';
                        if (endDate < 18)
                            dayPart = 'a.m.';
                        return `${moment.utc(item.endDate).format('YYYY-MM-DD')} ${dayPart}`;
                    },
                    width: 130,
                }),
                CellSelect({
                    id: 'project',
                    Header: 'Project',
                    accessor: 'project',
                    width: 150,
                    actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                    options: () => userProjects.map((userProject) => {
                        const project = projects.find((project) => project.id === userProject);
                        return { label: project === null || project === void 0 ? void 0 : project.name, value: project === null || project === void 0 ? void 0 : project.id };
                    }),
                    customSave: (newValue, data) => {
                        const newData = Object.assign(Object.assign({}, data), { project: newValue });
                        dispatch(updateLeave(newData));
                    },
                }),
                CellText({
                    id: 'comment',
                    Header: 'Comment',
                    hiddenable: true,
                    noFilterOption: true,
                    readOnly: false,
                    accessor: 'comment',
                    width: 250,
                    actions: (instance, cell) => ['edit', deleteLeavesAction(instance, cell)],
                    customSave: (newValue, data) => __awaiter(void 0, void 0, void 0, function* () {
                        const newData = Object.assign(Object.assign({}, data), { comment: newValue });
                        dispatch(updateLeave(newData));
                    }),
                }),
            ],
        },
    ], [projects]);
    return _jsx(TableRedux, { tableId: tableId, data: userLeaves, columns: columns, loading: fetchLoading, count: count });
};
export default TableTimeRecapLeaves;
