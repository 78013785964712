/** @flow */
import { upsertResources, setResourceMeta, requestStatuses, actionTypes } from 'redux-resource'

export default function includedResources(resourceType: string, options: * = {}): Object {
  return (state, action) => {
    const { initialResourceMeta } = options
    const { includedResources, mergeMeta, mergeResources, type } = action

    const isReadType = type === actionTypes.READ_RESOURCES_SUCCEEDED

    if (!isReadType && type !== actionTypes.CREATE_RESOURCES_SUCCEEDED) return state
    if (!includedResources) return state

    const includedResourceList = includedResources[resourceType]

    if (!includedResourceList) return state

    const resources = upsertResources(state.resources, includedResourceList, mergeResources)

    const newMeta = {
      readStatus: requestStatuses.SUCCEEDED,
      createStatus: undefined,
    }

    if (!isReadType) newMeta.createStatus = requestStatuses.SUCCEEDED

    const meta = setResourceMeta({
      resources: includedResourceList,
      meta: state.meta,
      newMeta,
      initialResourceMeta,
      mergeMeta,
    })
    return { ...state, meta, resources }
  }
}
