/** @flow */
import React from 'react'
import pipe from 'app/core/utils/pipe'
import { Link } from 'react-router-dom'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { getResources } from 'app/store/selectors/getResources'
import type { ID, Asset } from 'app/core/types'
import { router } from 'app/containers/Assets/AssetDetail/router'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { MUIButton } from 'app/components/Form'
import classes from './projectView.module.scss'

type Props = {|
  project: {
    ...Asset,
    name: string,
  },
|}

function ProjectComponent(props: Props): React$Node {
  const { project } = props
  const { thumbnailInst, name } = project

  return (
    <Link to={`/projects/${project.id}`} className={`${classes.container} flex row`}>
      <div>
        {thumbnailInst && (
          <div
            className={classes.imageContainer}
            style={{ background: `center / cover no-repeat url('${thumbnailInst.url}')` }}
          />
        )}
      </div>
      <div className="marginLeft10 flex alignCenter spaceBetween">
        <div className="fontSize22 bold">{name}</div>
      </div>
      <MUIButton
        tooltip="Open asset sheet"
        dataCy={name}
        style={{
          minWidth: 25,
          minHeight: 25,
          width: 25,
          height: 25,
          borderRadius: 25,
          padding: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          margin: 'auto 0 auto auto',
        }}
      >
        <FontIcon
          className="fontSize25"
          icon="fas-info-circle"
          style={{ color: 'grey' }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            router.goTo('index', { assetId: project.id }, { rightPanel: true })
          }}
        />
      </MUIButton>
    </Link>
  )
}

const pipeInst: Pipe<{ projectId: ID }, typeof ProjectComponent> = pipe()

export const ProjectView: React$AbstractComponent<any, any> = pipeInst
  .connect((state, props) => ({
    project: getResources(state, 'assets', props.projectId, ['thumbnailInst']),
  }))
  .render(({ projectId, ...rest }) => <ProjectComponent {...rest} />)
