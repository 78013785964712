var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// $FlowFixMe
import React, { useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { getSequenceColor } from 'app/libs/helpers/getSequenceColor';
import { ObjectValues } from 'app/libs/flowPolyfills';
import BoundingComponent from 'app/components/BoundingComponent/BoundingComponent.jsx';
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground';
import { cyLabelFormater } from 'app/libs/helpers/cyTools';
import { targetIsChild } from 'app/libs/helpers/dom';
import FontIcon from '../../../FontIcon/FontIcon.jsx';
import 'moment-business-days';
import { groupingFns } from './groupingFns';
import classes from './CellText.module.scss';
moment.updateLocale('fr', {
    workingWeekdays: [1, 2, 3, 4, 5],
});
export function ReadSimpleText(cell, value, isRowExpanded, type, prefs, description, colored, format, editorType) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { minLineHeight, maxLineHeight } = prefs;
    const color = getSequenceColor(Number(value));
    let coloredStyle = {};
    let coloredClassName = '';
    const now = useMemo(() => moment().format('YYYY-MM-DD HH:mm'), []);
    const today = useMemo(() => moment().format('YYYY-MM-DD'), []);
    const endOfWeek = useMemo(() => moment().endOf('week').format('YYYY-MM-DD'), []);
    const endOfCurrentMonth = useMemo(() => moment().endOf('month').format('YYYY-MM-DD'), []);
    const endOfNextMonth = useMemo(() => moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'), []);
    if (colored && (value || value === 0)) {
        coloredStyle = { backgroundColor: color, color: getColorFromBackground(color), fontWeight: 'bold' };
        coloredClassName = 'flex center alignCenter fullHeight fullWidth';
    }
    if (description === 'endDate' && value) {
        if (value <= today) {
            coloredStyle = { backgroundColor: '#ff4949', fontWeight: 'bold', color: 'white' };
        }
        if (value >= today && value <= endOfWeek) {
            coloredStyle = { color: 'red', fontWeight: 'bold' };
        }
        if (value > endOfWeek && value <= endOfCurrentMonth) {
            coloredStyle = { color: 'red' };
        }
        if (value > endOfCurrentMonth && value <= endOfNextMonth) {
            coloredStyle = { color: 'green' };
        }
    }
    let textExpiration = '';
    if (description === 'expirationDate' && value && typeof value === 'string') {
        const formattedValue = moment(value).format('YYYY-MM-DD HH:mm');
        if (formattedValue < now) {
            coloredStyle = { backgroundColor: '#cccccca2', width: '100%', height: '100%' };
            textExpiration = 'Expiré le ';
        }
        else if (moment(value).subtract(30, 'days').format('YYYY-MM-DD HH:mm') < now) {
            coloredStyle = { backgroundColor: 'red', width: '100%', height: '100%', color: 'white' };
        }
        else if (moment(value).subtract(90, 'days').format('YYYY-MM-DD HH:mm') < now) {
            coloredStyle = { backgroundColor: 'orange', width: '100%', height: '100%' };
        }
    }
    const isLink = type === 'url';
    if (type === 'datetime' && value && typeof value === 'string') {
        value = moment(value).format(format || 'DD/MM/YYYY HH:mm');
    }
    if (type === 'date' && value && typeof value === 'string') {
        value = moment(value).format(format || 'DD/MM/YYYY');
    }
    const isIban = type === 'text' && editorType === 'iban';
    if (isIban && value && typeof value === 'string') {
        const iban = value.replace(/\s/g, '');
        value = `${iban.slice(0, 4)} ${iban.slice(4, 8)} ${iban.slice(8, 12)} ${iban.slice(12, 16)} ${iban.slice(16, 20)} ${iban.slice(20, 24)} ${iban.slice(24, 28)} ${iban.slice(28)}`;
    }
    const isSsn = type === 'text' && editorType === 'ssn';
    if (isSsn && value && typeof value === 'string') {
        const ssn = value.replace(/[^\d]/g, '');
        value = `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3, 5)} ${ssn.slice(5, 7)} ${ssn.slice(7, 10)} ${ssn.slice(10, 13)} | ${ssn.slice(13)}`;
    }
    let numberOfContract = null;
    if (typeof value === 'object' && description === 'contractsSummary') {
        let days = 0;
        if ((value === null || value === void 0 ? void 0 : value.startDate) && (value === null || value === void 0 ? void 0 : value.endDate)) {
            days =
                moment(value === null || value === void 0 ? void 0 : value.startDate).format('YYYY-MM-DD') <= today
                    ? moment(value === null || value === void 0 ? void 0 : value.endDate).businessDiff(moment(value === null || value === void 0 ? void 0 : value.startDate)) + 1
                    : 0;
        }
        if ((value === null || value === void 0 ? void 0 : value.startDate) && !(value === null || value === void 0 ? void 0 : value.endDate)) {
            days =
                moment(value === null || value === void 0 ? void 0 : value.startDate).format('YYYY-MM-DD') <= today
                    ? moment(today).businessDiff(moment(value === null || value === void 0 ? void 0 : value.startDate)) + 1
                    : 0;
        }
        numberOfContract = (_jsxs("span", { style: {
                color: value.numberOfContract >= 4 ? 'red' : 'black',
                fontWeight: value.numberOfContract >= 4 ? 'bold' : 'initial',
            }, children: ["Contrat ", value === null || value === void 0 ? void 0 : value.numberOfContract] }));
        const arrayName = ((_a = value === null || value === void 0 ? void 0 : value.projectPlanInst) === null || _a === void 0 ? void 0 : _a.name.split('-')) || [];
        const name = (arrayName === null || arrayName === void 0 ? void 0 : arrayName.length) > 1
            ? (_e = (_d = (_c = (_b = value === null || value === void 0 ? void 0 : value.projectPlanInst) === null || _b === void 0 ? void 0 : _b.projectInst) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.match(/\b\w/g)) === null || _e === void 0 ? void 0 : _e.join('').toUpperCase()
            : (_h = (_g = (_f = value === null || value === void 0 ? void 0 : value.projectPlanInst) === null || _f === void 0 ? void 0 : _f.projectInst) === null || _g === void 0 ? void 0 : _g.name) === null || _h === void 0 ? void 0 : _h.slice(0, 3).toUpperCase();
        value = `${((_m = (_l = (_k = (_j = value === null || value === void 0 ? void 0 : value.projectPlanInst) === null || _j === void 0 ? void 0 : _j.projectInst) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.trigram) === null || _m === void 0 ? void 0 : _m.toUpperCase()) || name || 'unknown'} | ${days}d | ${value === null || value === void 0 ? void 0 : value.name} | `;
    }
    return (_jsxs("div", { className: cx(classes.renderRead, { [classes.url]: type === 'url', [classes.reduced]: !isRowExpanded && type !== 'number' }, coloredClassName), style: Object.assign(Object.assign({}, coloredStyle), { maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }), children: [isLink && typeof value === 'string' ? (_jsx("a", { target: "_blank", rel: "noreferrer", href: value, children: _jsx("div", { className: classes.linkButton, children: _jsx(FontIcon, { icon: "fas-external-link-alt" }) }) })) : null, textExpiration, numberOfContract && typeof value === 'string' ? (_jsxs(_Fragment, { children: [_jsx("span", { children: value }), numberOfContract] })) : typeof value !== 'object' ? (value) : null] }));
}
class InputCell extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.isUnmount = false;
        this.state = { value: this.props.value, error: false, showError: false };
        this.inputRef = React.createRef();
        this.onChange = (event) => {
            const { type, onChangeFormat, editorType } = this.props;
            let { value } = event.target;
            if (type === 'number' && Number(value) > Number.MAX_SAFE_INTEGER)
                value = String(Number.MAX_SAFE_INTEGER);
            if (type === 'datetime' && value) {
                value = moment(new Date(String(value)))
                    .set('millisecond', 0)
                    .format('YYYY-MM-DDTHH:mm');
            }
            if (type === 'text' && editorType === 'iban' && value) {
                value = this.formatIban(value, 4);
            }
            if (type === 'text' && editorType === 'ssn' && value) {
                value = this.formatSsn(value);
            }
            if (!this.isUnmount) {
                this.setState({ value: onChangeFormat(value), error: false, showError: false });
            }
        };
        this.validate = () => {
            const { validators, type } = this.props;
            let { value } = this.state;
            if (validators) {
                let reject = false;
                for (const validator of ObjectValues(validators)) {
                    const error = validator(value);
                    if (error) {
                        !this.isUnmount && this.setState({ error, showError: true });
                        reject = true;
                    }
                }
                if (reject)
                    return Promise.reject();
            }
            if (type === 'number')
                value = Number(value);
            return Promise.resolve(value);
        };
        this.onKeyDown = (event, keyName, editionTools, navigationTools) => {
            const { onValidate, onExit } = this.props;
            if (keyName === 'enter') {
                event.preventDefault();
                this.validate().then((value) => onValidate === null || onValidate === void 0 ? void 0 : onValidate(value));
            }
            if (keyName === 'escape') {
                event.preventDefault();
                onExit === null || onExit === void 0 ? void 0 : onExit();
            }
        };
        this.onBlur = (event) => {
            const { cell } = this.props;
            if (targetIsChild(event, null, document.querySelector(`div[cellid="${cell.id}"]`)))
                return;
            const { onBlur } = this.props;
            if (onBlur)
                onBlur(event, this.state.value || null);
        };
    }
    componentDidMount() {
        const { getEditionKeyActions, type, value } = this.props;
        if (type === 'datetime' && value) {
            this.setState({
                value: moment(new Date(String(value)))
                    .set('millisecond', 0)
                    .format('YYYY-MM-DDTHH:mm'),
            });
        }
        if (this.inputRef.current) {
            this.inputRef.current.select();
        }
        if (getEditionKeyActions) {
            getEditionKeyActions(this.onKeyDown);
        }
    }
    componentWillUnmount() {
        this.isUnmount = true;
    }
    formatIban(value, after) {
        if (!value)
            return value;
        const iban = value.replace(/\s/g, '').toUpperCase();
        const reg = new RegExp(`.{${after}}`, 'g');
        return iban.replace(reg, (a) => {
            return `${a} `;
        });
    }
    formatSsn(value) {
        if (!value)
            return value;
        const ssn = value.replace(/[^\d]/g, '');
        const ssnLength = ssn.length;
        if (ssnLength < 2)
            return ssn;
        if (ssnLength < 4) {
            return `${ssn.slice(0, 1)} ${ssn.slice(1)}`;
        }
        if (ssnLength < 6) {
            return `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3)}`;
        }
        if (ssnLength < 8) {
            return `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3, 5)} ${ssn.slice(5)}`;
        }
        if (ssnLength < 11) {
            return `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3, 5)} ${ssn.slice(5, 7)} ${ssn.slice(7)}`;
        }
        if (ssnLength < 14) {
            return `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3, 5)} ${ssn.slice(5, 7)} ${ssn.slice(7, 10)} ${ssn.slice(10)}`;
        }
        return `${ssn.slice(0, 1)} ${ssn.slice(1, 3)} ${ssn.slice(3, 5)} ${ssn.slice(5, 7)} ${ssn.slice(7, 10)} ${ssn.slice(10, 13)} | ${ssn.slice(13)}`;
    }
    render() {
        const _a = this.props, { value: propValue, className, onChangeFormat, getEditionKeyActions, validators, type: defaultType, editorType, onValidate, onExit, onBlur, cell, step } = _a, rest = __rest(_a, ["value", "className", "onChangeFormat", "getEditionKeyActions", "validators", "type", "editorType", "onValidate", "onExit", "onBlur", "cell", "step"]);
        let { type } = this.props;
        const { value, error, showError } = this.state;
        if (type === 'datetime')
            type = 'datetime-local';
        return (_jsxs(_Fragment, { children: [_jsx("input", Object.assign({}, rest, { type: type, maxLength: editorType === 'iban' ? 42 : editorType === 'ssn' ? 23 : undefined, ref: this.inputRef, value: value === undefined ? '' : value, step: step || (type === 'time' ? '1' : undefined), onChange: this.onChange, onBlur: this.onBlur, className: cx(classes.input, className, error && classes.error), "data-testid": cyLabelFormater('input', type) })), showError && (_jsx(BoundingComponent, { onAfterRender: () => {
                        setTimeout(() => {
                            !this.isUnmount && this.setState({ showError: false });
                        }, 3000);
                    }, children: _jsxs("div", { children: [_jsx("div", { className: classes.triangle }), _jsx("div", { className: classes.errorTooltip, children: error })] }) }))] }));
    }
}
InputCell.defaultProps = {
    onChangeFormat: (value) => value,
};
const getCellType = (type) => {
    // prettier-ignore
    switch (type) {
        case 'text': return 'CellText_text';
        case 'number': return 'CellText_number';
        case 'url': return 'CellText_url';
        case 'ip': return 'CellText_ip';
        case 'date': return 'CellText_date';
        case 'time': return 'CellText_time';
        case 'datetime': return 'CellText_datetime';
        case 'email': return 'CellText_email';
        default: return 'CellText_text';
    }
};
export const CellText = (_a) => {
    var { inputProps, getStyle, RenderRead = ReadSimpleText, colored = false, noReadStyle, containerStyle, format, width } = _a, data = __rest(_a, ["inputProps", "getStyle", "RenderRead", "colored", "noReadStyle", "containerStyle", "format", "width"]);
    const type = (inputProps === null || inputProps === void 0 ? void 0 : inputProps.type) || 'text';
    const editorType = (inputProps === null || inputProps === void 0 ? void 0 : inputProps.editorType) || 'str';
    return Object.assign(Object.assign({}, data), { cellType: getCellType(type), Cell: (instance) => {
            const { value, cell, prefs, column, row } = instance;
            const { getCellProps } = cell;
            const { original: data } = row;
            const { isRowExpanded, edition } = getCellProps();
            const { isBeingEdit, getEditionKeyActions, save, endEdit } = edition;
            const { description } = column;
            if (isBeingEdit) {
                return (_jsx(InputCell, Object.assign({}, inputProps, { editorType: editorType, type: type, cell: cell, value: value || '', getEditionKeyActions: getEditionKeyActions, onValidate: (newValue) => __awaiter(void 0, void 0, void 0, function* () {
                        if (save && value !== newValue)
                            save(newValue, data);
                        else
                            endEdit();
                        cell.focusCell();
                    }), onBlur: (e, newValue) => {
                        if (save && value !== newValue)
                            save(newValue, data);
                        else
                            endEdit();
                    }, onExit: () => {
                        endEdit();
                    } })));
            }
            if (noReadStyle) {
                return RenderRead(cell, value, isRowExpanded, type, prefs, description, colored, format, editorType);
            }
            return (_jsx("div", { className: classes.read, style: containerStyle, children: RenderRead(cell, value, isRowExpanded, type, prefs, description, colored, format, editorType) }));
        }, width: editorType === 'iban' ? 250 : width ? width : 200, groupingFns: groupingFns(type) });
};
