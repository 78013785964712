var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState, useMemo } from 'react';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import { getResources } from 'app/store/selectors';
import resources from 'app/store/resources';
import AssetsSelect from 'app/containers/Assets/AssetsSelect/AssetsSelect.jsx';
import { getMinMaxDatesFromObjects } from 'app/core/utils/dates';
import Loader from 'app/components/Loader/Loader.jsx';
import api from 'app/core/api/api';
import { getContractStatusOptions } from 'app/core/utils/getContractsStatus';
import FontIcon from '../../../../components/FontIcon/FontIcon';
import classes from './ModalEditContract.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { contractTypesSelector } from 'app/store/selectors/serverConfig';
import { getContractTypesOptions } from 'app/containers/Modals/Contracts/ModalEditContract/utils';
import { fetchCollectiveAgreementTitles } from 'app/store/reducers/collectiveAgreementTitles';
export function ModalEditContract(props) {
    var _a, _b;
    const dispatch = useDispatch();
    const { data: collectiveAgreementTitles } = useSelector((state) => state.collectiveAgreementTitles);
    const { contract, onSave, onChange, person: defaultPerson, parentSubRows } = props, modalProps = __rest(props, ["contract", "onSave", "onChange", "person", "parentSubRows"]);
    const [person, setPerson] = useState(defaultPerson || (contract === null || contract === void 0 ? void 0 : contract.personInst));
    const [projectPlans, setProjectPlans] = useState([]);
    const [offices, setOffices] = useState([]);
    const [resourcesLoaded, setResourcesLoaded] = useState(false);
    const [selectedProject, setSelectedproject] = useState(null);
    const [personProjectContracts, setPersonProjectContracts] = useState([]);
    const contractTypes = useSelector(contractTypesSelector);
    const contractTypesOptions = getContractTypesOptions(contractTypes);
    useEffect(() => {
        if (projectPlans.length && (contract === null || contract === void 0 ? void 0 : contract.projectPlan)) {
            const projectPlan = projectPlans.find((projectPlan) => projectPlan.id === (contract === null || contract === void 0 ? void 0 : contract.projectPlan));
            setSelectedproject({ value: projectPlan === null || projectPlan === void 0 ? void 0 : projectPlan.id, label: projectPlan === null || projectPlan === void 0 ? void 0 : projectPlan.name });
        }
    }, [projectPlans, contract]);
    useEffect(() => {
        var _a;
        if (selectedProject && person) {
            const projectContracts = (_a = person.contractsInst) === null || _a === void 0 ? void 0 : _a.filter((contract) => contract.projectPlan === selectedProject.value);
            setPersonProjectContracts(projectContracts);
        }
    }, [selectedProject, person]);
    useEffect(() => {
        const config = { params: { queries: { page_size: 1000 } } };
        Promise.all([
            dispatch(fetchCollectiveAgreementTitles()),
            resources.projectPlans.fetchAll({ params: { queries: { page_size: 1000 } } }).then((res) => {
                setProjectPlans(res.resources);
            }),
            resources.assets.fetchAll({ params: { queries: { page_size: 1000, assetType: 'of' } } }).then((res) => {
                setOffices(res.resources);
            }),
            (contract === null || contract === void 0 ? void 0 : contract.person) &&
                resources.people.fetchOne(contract.person).then(() => {
                    setPerson(getResources(undefined, 'people', contract.person, ['contractsInst']));
                }),
        ])
            .then(() => setResourcesLoaded(true))
            .catch(() => setResourcesLoaded(true));
    }, []);
    function save(data) {
        const { person, startDate, endDate, contractType, collectiveAgreementTitle, salary, contractStatus, office, projectPlan, } = data;
        const newContract = {
            id: contract === null || contract === void 0 ? void 0 : contract.id,
            startDate,
            endDate: contractType === 'cdi' && !contract ? null : endDate,
            person: person === null || person === void 0 ? void 0 : person.value,
            contractType,
            collectiveAgreementTitle: collectiveAgreementTitle === null || collectiveAgreementTitle === void 0 ? void 0 : collectiveAgreementTitle.value,
            contractStatus,
            office,
            projectPlan,
        };
        if (onSave)
            return onSave(newContract, salary);
        return resources.contracts[(contract === null || contract === void 0 ? void 0 : contract.id) ? 'update' : 'create'](newContract).then((res) => {
            var _a;
            const resContract = res.resources[0];
            const secureDataId = (_a = resContract === null || resContract === void 0 ? void 0 : resContract.secureDataInst) === null || _a === void 0 ? void 0 : _a.id;
            return api.contractSecureDatas[secureDataId ? 'update' : 'create']({
                id: secureDataId,
                salary,
                contract: resContract.id,
            }).then((res) => {
                onChange === null || onChange === void 0 ? void 0 : onChange(Object.assign(Object.assign({}, resContract), { secureDataInst: res, secureData: res.id }));
            });
        });
    }
    function getPersonName(person) {
        if (!person)
            return undefined;
        return `${person.firstName} ${person.lastName}`;
    }
    const defaultStartDate = useMemo(() => {
        const lastDate = getMinMaxDatesFromObjects(parentSubRows)[1];
        if (!lastDate)
            return contract === null || contract === void 0 ? void 0 : contract.startDate;
        return moment(lastDate).add(1, 'day').format('YYYY-MM-DD');
    }, [parentSubRows]);
    const personName = useMemo(() => getPersonName(person), [person]);
    const length = useMemo(() => {
        if (((contract === null || contract === void 0 ? void 0 : contract.startDate) || defaultStartDate) && ((contract === null || contract === void 0 ? void 0 : contract.endDate) || defaultStartDate)) {
            return (moment((contract === null || contract === void 0 ? void 0 : contract.endDate) || defaultStartDate).diff(moment((contract === null || contract === void 0 ? void 0 : contract.startDate) || defaultStartDate), 'days') + 1);
        }
        return 1;
    }, [contract, defaultStartDate]);
    const defaultData = Object.assign(Object.assign({}, contract), { contractType: (contract === null || contract === void 0 ? void 0 : contract.contractType) || 'cddu', person: person ? { label: personName, value: person.id } : undefined, startDate: (contract === null || contract === void 0 ? void 0 : contract.startDate) || defaultStartDate, endDate: (contract === null || contract === void 0 ? void 0 : contract.endDate) || defaultStartDate, salary: (_a = contract === null || contract === void 0 ? void 0 : contract.secureDataInst) === null || _a === void 0 ? void 0 : _a.salary, contractStatus: (contract === null || contract === void 0 ? void 0 : contract.contractStatus) || 'creation', collectiveAgreementTitle: (contract === null || contract === void 0 ? void 0 : contract.collectiveAgreementTitle)
            ? {
                value: contract === null || contract === void 0 ? void 0 : contract.collectiveAgreementTitle,
                label: (_b = contract === null || contract === void 0 ? void 0 : contract.collectiveAgreementTitleData) === null || _b === void 0 ? void 0 : _b.jobTitle,
            }
            : undefined, length });
    return (_jsx(ModalConfirmForm, Object.assign({ title: contract ? `Edit contract of ${personName || ''}` : 'Create new contract', draggable: true }, modalProps, { children: !resourcesLoaded ? (_jsx("div", { style: { height: 300 }, className: "flex center alignCenter fullWidth", children: _jsx(Loader, {}) })) : (_jsx(FormData, { onSave: save, defaultData: defaultData, properties: [
                {
                    label: 'People',
                    key: 'person',
                    type: 'autocomplete',
                    element: AssetsSelect,
                    elementProps: {
                        model: 'people',
                        isRequired: true,
                        disabled: !!contract,
                        placeholder: 'Select a person',
                    },
                },
                {
                    label: 'Contract type',
                    key: 'contractType',
                    type: 'select',
                    elementProps: {
                        options: contractTypesOptions,
                        fullWidth: true,
                        isRequired: true,
                        disabled: !!contract,
                        placeholder: 'Select a contract type',
                    },
                },
                {
                    label: 'Collective agreement title',
                    key: 'collectiveAgreementTitle',
                    type: 'muiautocomplete',
                    elementProps: (data, setData) => ({
                        value: data.collectiveAgreementTitle || null,
                        onChange: (opt) => {
                            var _a;
                            setData(Object.assign(Object.assign({}, data), { collectiveAgreementTitle: opt, salary: ((_a = opt.data) === null || _a === void 0 ? void 0 : _a.minimumSalary) || 0 }));
                        },
                        options: sortBy(collectiveAgreementTitles, ['jobTitle']).map((collectiveAgreementTitle) => ({
                            value: collectiveAgreementTitle.id,
                            label: collectiveAgreementTitle.jobTitle,
                            data: collectiveAgreementTitle,
                        })),
                        isRequired: true,
                        placeholder: 'Select a collective agreement title',
                        searchInOptions: true,
                    }),
                },
                {
                    label: 'Office',
                    key: 'office',
                    type: 'select',
                    elementProps: {
                        options: offices.map((office) => {
                            const name = office.name[0].toUpperCase() + office.name.slice(1);
                            return { value: office.id, label: name };
                        }),
                        isRequired: true,
                        fullWidth: true,
                        placeholder: 'Select an office',
                    },
                },
                {
                    label: 'Project',
                    key: 'projectPlan',
                    type: 'select',
                    elementProps: (data, setData) => {
                        return {
                            value: data.projectPlan || null,
                            options: projectPlans.map((projectPlan) => {
                                const name = projectPlan.name[0].toUpperCase() + projectPlan.name.slice(1);
                                return { value: projectPlan.id, label: name };
                            }),
                            isRequired: true,
                            fullWidth: true,
                            placeholder: 'Select a project',
                            onChange: (val) => {
                                setData(Object.assign(Object.assign({}, data), { projectPlan: val.value }));
                                setSelectedproject(val);
                            },
                        };
                    },
                },
                selectedProject && {
                    key: 'contractNumber',
                    label: 'Contract number',
                    type: 'custom',
                    element: () => {
                        return (_jsx("div", { className: classes.contractNumberContrainer, children: _jsxs("h4", { style: {
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: (personProjectContracts === null || personProjectContracts === void 0 ? void 0 : personProjectContracts.length) + 1 === 3
                                        ? 'orange'
                                        : (personProjectContracts === null || personProjectContracts === void 0 ? void 0 : personProjectContracts.length) + 1 > 3
                                            ? 'red'
                                            : 'black',
                                }, children: [_jsx("span", { children: (personProjectContracts === null || personProjectContracts === void 0 ? void 0 : personProjectContracts.length) + 1 }), (personProjectContracts === null || personProjectContracts === void 0 ? void 0 : personProjectContracts.length) + 1 > 2 && (_jsx("span", { className: classes.icon, children: _jsx(FontIcon, { icon: "fas-exclamation-triangle" }) }))] }) }));
                    },
                },
                {
                    label: 'Salary',
                    key: 'salary',
                    type: 'string',
                    elementProps: {
                        type: 'number',
                        isRequired: true,
                    },
                },
                {
                    label: 'Contract status',
                    key: 'contractStatus',
                    type: 'select',
                    elementProps: {
                        options: getContractStatusOptions(),
                        fullWidth: true,
                        isRequired: true,
                        disabled: (contract === null || contract === void 0 ? void 0 : contract.contractStatus) === 'signed',
                        placeholder: 'Select a contract status',
                    },
                },
                {
                    label: 'Start date',
                    key: 'startDate',
                    type: 'string',
                    elementProps: (data, setData) => {
                        const { startDate } = data;
                        return {
                            type: 'date',
                            isRequired: true,
                            value: startDate,
                            showDateDay: true,
                            onChange: (e) => {
                                const { value } = e.target;
                                const endDate = data.length && value ? moment(value).add(data.length - 1, 'days') : undefined;
                                setData(Object.assign(Object.assign({}, data), { startDate: value, endDate: endDate === null || endDate === void 0 ? void 0 : endDate.format('YYYY-MM-DD') }));
                            },
                        };
                    },
                },
                {
                    label: 'End date',
                    key: 'endDate',
                    type: 'string',
                    elementProps: (data, setData) => {
                        const { contractType, endDate } = data;
                        return {
                            type: contractType === 'cdi' ? 'string' : 'date',
                            showDateDay: true,
                            value: contractType === 'cdi' ? '' : endDate,
                            disabled: contractType === 'cdi' && !contract,
                            onChange: (e) => {
                                const { value } = e.target;
                                const length = data.startDate && value ? moment(value).diff(moment(data.startDate), 'days') + 1 : 1;
                                setData(Object.assign(Object.assign({}, data), { endDate: value, length }));
                            },
                        };
                    },
                },
                {
                    label: 'Duration',
                    key: 'length',
                    type: 'string',
                    elementProps: (data, setData) => {
                        const { contractType, length } = data;
                        return {
                            value: contractType === 'cdi' ? '' : length,
                            onChange: (e) => {
                                const { value } = e.target;
                                if (Number(value) < 1)
                                    return;
                                const startDate = moment(data.startDate);
                                const endDate = moment(startDate).add(Number(value) - 1, 'days');
                                setData(Object.assign(Object.assign({}, data), { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD'), length: Number(value) }));
                            },
                            type: 'number',
                            disabled: contractType === 'cdi' && !contract,
                            adornment: contractType === 'cdi' ? '' : _jsx("span", { className: "padding5", children: "days" }),
                            adornmentWidth: 40,
                            alignRight: true,
                        };
                    },
                },
            ] })) })));
}
