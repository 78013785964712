// @flow
import React from 'react'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { CalculatedField, ID, AssetLinkTypes } from 'app/core/types'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import { NODE_ITEM, Node } from 'app/components/TreeDnD/Node'
import { aggregationTypes } from './CalculatedFields.tsx'
import classes from './CalculatedFields.module.scss'

type Props = {|
  calculatedField: CalculatedField,
  onSelect: (calculatedFieldId: ID) => void,
  onDelete: (calculatedField: CalculatedField) => void,
  aggregation: 'children' | 'assetLink' | 'childrenAssetLink' | null,
  assetLinkType: ?AssetLinkTypes,
  parentNode: Node<>,
|}

export function getCalculatedFieldNode(props: Props): Node<> {
  const { calculatedField, onSelect, onDelete, aggregation, assetLinkType, parentNode } = props

  let label = ''
  if (!aggregation) label = calculatedField.name
  else {
    const aggregationTypeLabel = aggregationTypes.find((a) => a.value === aggregation)?.label
    if (!aggregationTypeLabel) label = calculatedField.name
    else label = `aggregated ${aggregationTypeLabel.toLowerCase()} - ${calculatedField.name}`
  }

  const content = !aggregation
    ? { ...calculatedField, type: 'calculatedField' }
    : {
        ...calculatedField,
        aggregatedName: label,
        type: 'aggregation',
        parentType: 'calculatedField',
        aggregateType: 'calculatedField',
        aggregateDatas: {
          name: `${aggregation}*${calculatedField.id}`,
          id: calculatedField.id,
          relation: aggregation,
          linkType: assetLinkType?.id,
        },
      }

  return new Node({
    key: calculatedField.id,
    icon: 'fas-grip-vertical',
    name: (
      <div
        className={classes.listItemContainer}
        onClick={() => onSelect(calculatedField.id)}
        data-testid={cyLabelFormater('calculatedField-item', calculatedField.name)}
      >
        <span className={classes.attributeLabel}>{label}</span>
        <div className={classes.listItem__actionContainer}>
          <FontIcon
            className={classes.iconButton}
            icon="trash"
            style={{ padding: 4 }}
            data-testid={cyLabelFormater('calculatedField-item-delete', calculatedField.name)}
            onClick={() => onDelete(calculatedField)}
          />
        </div>
      </div>
    ),
    collapsed: false,
    parent: parentNode,
    data: content,
    type: NODE_ITEM,
  })
}
