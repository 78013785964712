// @flow
import React, { useState } from 'react'
import resources from 'app/store/resources'
import FormData from 'app/components/Form/FormData/FormData.jsx'
import { ModalConfirmForm, type ModalProps } from 'app/components/Modal'
import { ColorPickerPopper } from 'app/components/Form/ColorPicker/ColorPickerPopper.jsx'
import Paper from '@material-ui/core/Paper'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import type { Flag } from 'app/core/types'
import { sortBy } from 'lodash'
import { categoriesAssets, categoriesWithoutAssets } from './Flags.jsx'

type Props = {|
  ...$Shape<$Rest<ModalProps, { children: React$Node }>>,
  category: string,
  color?: string,
  flag?: Flag,
  categories: Object,
  searchInput: string | void,
|}

function EditFlagModal(props: Props): React$Element<any> {
  const { color, flag, category, categories, searchInput, ...rest } = props
  const [newColor, setNewColor] = useState<string | void>(color)
  const [newCategory, setNewCategory] = useState<string>('')
  const allCategories = categoriesWithoutAssets.concat(categoriesAssets)

  async function onSave(item: Flag) {
    const editedFlag = {
      id: flag?.id,
      name: item.name,
      description: item.description,
      color: flag ? newColor : '#B3B9c7',
      category: flag ? flag.category : !searchInput ? category : newCategory,
    }

    const res = await resources.flags[flag?.id ? 'update' : 'create'](editedFlag)
    return res
  }

  async function onChangeColor(item: Flag, color: string) {
    setNewColor(color)
    return Promise.resolve()
  }

  return (
    <ModalConfirmForm
      title={flag ? `Update ${categories[category]} flag` : `Create ${!searchInput ? categories[category] : ''} flag`}
      validateLabel={flag ? 'Update flag' : 'Create flag'}
      {...rest}
    >
      <>
        <FormData
          properties={[
            {
              key: 'name',
              label: 'Name',
              type: FormData.types.string,
              elementProps: { isRequired: true },
            },
            {
              label: 'Category',
              key: 'category',
              type: 'select',
              elementProps: (data, setData) => {
                return {
                  value: searchInput ? data.category : category,
                  onChange: (opt) => {
                    setData({ ...data, category: opt.value })
                    setNewCategory(opt.value)
                  },
                  options: sortBy(allCategories, ['title']).map((category) => ({
                    label: category.title,
                    value: category.category,
                  })),
                  disabled: !!category,
                  placeholder: 'Select a category',
                }
              },
            },
            {
              key: 'description',
              label: 'Description',
              type: FormData.types.textarea,
              elementProps: {
                style: { width: '100%' },
                rows: 10,
              },
            },
          ]}
          defaultData={flag && flag}
          onSave={onSave}
          flashNotifSuccessLabel={`Flag ${flag ? 'updated' : 'created'}`}
        />
        {flag ? (
          <div style={{ marginLeft: '15px' }}>
            <Paper style={{ width: '100px', borderRadius: '10px' }}>
              <div
                style={{
                  backgroundColor: newColor,
                  color: getColorFromBackground(newColor),
                  borderRadius: '10px',
                  textAlign: 'center',
                  display: 'flex',
                  padding: '0 10px',
                }}
                data-testid="form-color"
              >
                {newColor}
                <ColorPickerPopper
                  style={{ paddingLeft: '5px', color: getColorFromBackground(newColor) }}
                  color={color}
                  onChange={(color) => onChangeColor(flag, color)}
                />
              </div>
            </Paper>
          </div>
        ) : null}
      </>
    </ModalConfirmForm>
  )
}

export default EditFlagModal
