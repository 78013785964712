/** @flow */
import React from 'react'
import cx from 'classnames'
import Popover from '@material-ui/core/Popover'
import { ColorPicker, type ColorPickerProps } from 'app/components/Form'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'

import classes from './ColorPickerPopper.module.scss'
import { Tooltip } from '../../Tooltip/Tooltip.jsx'

type Props = {|
  onChange: (color: string) => Promise<any>,
  color?: string,
  style?: Object,
  className?: string,
  colors?: $ElementType<ColorPickerProps, 'colors'>,
  closeOnChange?: boolean,
  tooltip?: string,
|}

type State = {|
  colorPickerIsOpen: boolean,
|}

export class ColorPickerPopper extends React.PureComponent<Props, State> {
  state: State = {
    colorPickerIsOpen: false,
  }

  anchorEl: ?HTMLElement = undefined

  isMounted: boolean = true

  componentWillUnmount() {
    this.isMounted = false
  }

  handleClose: () => void = () => {
    if (this.isMounted === true) this.setState({ colorPickerIsOpen: false })
  }

  handleChange: (color: string) => Promise<any> = (color) => {
    const { onChange, closeOnChange = true } = this.props
    return onChange(color).finally(() => {
      if (closeOnChange) this.handleClose()
    })
  }

  render(): React$Node {
    const { color, style, className, colors, tooltip } = this.props
    return (
      <>
        <div
          className={cx(classes.container, className)}
          style={style}
          ref={(ref) => {
            this.anchorEl = ref
          }}
          onClick={(e) => this.setState({ colorPickerIsOpen: true })}
        >
          <Tooltip title={tooltip}>
            <FontIcon icon="fas-paint-brush" style={{ cursor: 'pointer' }} />
          </Tooltip>
        </div>
        <Popover
          open={this.state.colorPickerIsOpen}
          anchorEl={this.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <div className="relative" data-testid="color-picker">
            <ColorPicker value={color} colors={colors} onChange={this.handleChange} />
          </div>
        </Popover>
      </>
    )
  }
}
