// @flow
import React from 'react'
import { map } from 'lodash'
import { viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import type { Attribute, PersonAttribute, ContractAttribute } from 'app/core/types'

import classes from './AttributesEditor.module.scss'
import { CONFIG_ENTRIES, getConfigEntries } from './attributeAssets'

type DisplayAttributeProps = {|
  EditButtons?: React$Element<any>,
  attribute: ContractAttribute | PersonAttribute | Attribute | void,
|}

export function DisplayAttribute(props: DisplayAttributeProps): React$Element<any> {
  const { attribute, EditButtons } = props

  if (!attribute) return <div className={classes.displayAttributeContainer} />

  function editorParams() {
    if (attribute.attrType === 'skill') {
      const entries = getConfigEntries('SKILL_LEVELS')

      if (!entries) return null

      return (
        <div key="skill" style={{ marginLeft: 20 }}>
          {CONFIG_ENTRIES.SKILL_LEVELS}: <br />
          {map(entries, (label: string, value: string) => (
            <div key={label} style={{ marginLeft: 20 }}>
              - <span data-testid="attribut-editorInfo-value">{label}</span>
              <br />
            </div>
          ))}
        </div>
      )
    }

    if (Array.isArray(attribute.editorParams) || typeof attribute.editorParams === 'object') {
      return map(attribute.editorParams, (params: any, type: any) => {
        if (type === 'choice' && params) {
          return (
            <div key={type} style={{ marginLeft: 20, height: '100%' }}>
              {type}: <br />
              <div style={{ height: '170px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                {params.map((param) => (
                  <div key={param} style={{ marginLeft: 20 }}>
                    - <span data-testid="attribut-editorInfo-value">{viewableValue(attribute.attrType, param)}</span>
                    <br />
                  </div>
                ))}
              </div>
            </div>
          )
        }
        return (
          <div key={type} style={{ marginLeft: 20 }}>
            <span data-testid="attribut-editorInfo-type">{type}</span>:{' '}
            <span data-testid="attribut-editorInfo-value">{viewableValue(attribute.attrType, params)}</span>
          </div>
        )
      })
    }

    return null
  }

  return (
    <div className={classes.displayAttributeContainer}>
      <div className={classes.displayAttributeEditButtons}>{EditButtons}</div>
      <div className={classes.attributeInfo}>
        <span>Name:</span> <span data-testid="attribut-name">{attribute.displayName || attribute.name}</span>
      </div>
      {attribute.required ? <div className={classes.attributeRequired}>Required</div> : null}
      <div className={classes.attributeInfo}>
        <span>Type:</span> <span data-testid="attribut-attrType">{attribute.attrType}</span>
      </div>
      {attribute.editorType ? (
        <div className={classes.attributeInfo}>
          <span>Editor type:</span> <span data-testid="attribut-editorType">{attribute.editorType}</span>
        </div>
      ) : null}
      {Object.keys(attribute.editorParams || {}).length > 0 || attribute.attrType === 'skill' ? (
        <div className={classes.attributeInfo} data-testid="attribut-editorInfo" style={{ height: '200px' }}>
          <span>Editor params:</span> <div style={{ height: '100%' }}>{editorParams()}</div>
        </div>
      ) : null}
    </div>
  )
}
