// @flow
import React, { useState } from 'react'
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx'
import { Input } from 'app/components/Form'
import FlagsComponents from './FlagsComponents.jsx'
import classes from './Flags.module.scss'

export const categoriesWithoutAssets = [
  {
    title: 'Articles',
    category: 'article',
  },
  {
    title: 'Takes',
    category: 'take',
  },
  {
    title: 'Dynamic Approval Values',
    category: 'dynApproval',
  },
]

export const categoriesAssets = [
  {
    title: 'Episodes',
    category: 'ep',
  },
  {
    title: 'Folders',
    category: 'fo',
  },
  {
    title: 'Models',
    category: 'mo',
  },
  {
    title: 'Projects',
    category: 'pj',
  },
  {
    title: 'Shots',
    category: 'sh',
  },
]

export function Flags(): React$Node {
  const [searchInput, setSearchInput] = useState<string>('')

  return (
    <div style={{ height: '100%', overflowY: 'auto' }}>
      <Input
        dataCy="input-search"
        placeholder="Search a flag"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        adornmentIcon="fas-search"
        styleContainer={{ maxWidth: 300, height: 'auto', margin: '15px' }}
      />
      {searchInput.length === 0 ? (
        <div className={classes.flagPanel}>
          <ExpansionPanel dataCy="assets" title="Assets">
            <div style={{ width: '100%' }}>
              {categoriesAssets.map((asset) => (
                <ExpansionPanel dataCy={asset.category} title={`${asset.title}`} key={asset.category}>
                  <FlagsComponents category={asset.category} />
                </ExpansionPanel>
              ))}
            </div>
          </ExpansionPanel>
          {categoriesWithoutAssets.map((asset) => (
            <ExpansionPanel dataCy={asset.category} title={`${asset.title}`} key={asset.category}>
              <FlagsComponents category={asset.category} />
            </ExpansionPanel>
          ))}
        </div>
      ) : (
        <div className={classes.flagPanel}>
          <h2 style={{ marginLeft: '5px' }}>Search Result</h2>
          <FlagsComponents category="" searchInput={searchInput} />
        </div>
      )}
    </div>
  )
}
