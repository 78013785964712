// @flow
import * as React from 'react'
import type { IconType } from 'app/core/types'
import FontIcon from '../FontIcon/FontIcon.jsx'
import { Tooltip } from '../Tooltip/Tooltip.jsx'
import { cyLabelFormater } from '../../libs/helpers/cyTools.js'
import classes from './NodeActions.module.scss'

export type NodeAction = {|
  label: React$Node,
  onClick: (e: SyntheticMouseEvent<HTMLElement>) => void,
  key: string,
  'data-testid': string,
  icon?: IconType,
  tooltip?: React$Node,
|}

type NodeActionsProps = {|
  actions: NodeAction[],
|}

export function NodeActions(props: NodeActionsProps): React$Node {
  const { actions } = props

  return (
    <div className={classes.container}>
      {actions &&
        actions.map((action, index) => {
          const { onClick, label, tooltip, key, icon, ...rest } = action

          const Tt = tooltip ? Tooltip : 'div'

          return onClick ? (
            <Tt title={tooltip} key={key}>
              <div
                onClick={onClick}
                data-testid={cyLabelFormater('nodeAction', action.label || action.tooltip)}
                className={classes.actionsIconContainer}
                {...rest}
              >
                {icon ? <FontIcon icon={icon} className={label ? 'marginRight10' : undefined} /> : null}
                {label}
              </div>
            </Tt>
          ) : (
            <Tt
              key={key || String(index)}
              data-testid={cyLabelFormater('modulableTree', action.label || action.tooltip)}
              title={tooltip}
              {...rest}
            >
              <span className={classes.actionsIconContainer}>
                {icon ? <FontIcon icon={icon} className={label ? 'marginRight10' : undefined} /> : null}
                {label}
              </span>
            </Tt>
          )
        })}
    </div>
  )
}
