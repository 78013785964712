import React from 'react'
import PropTypes from 'prop-types'
import { MUIButton, ButtonList } from 'app/components/Form'
import { connect } from 'react-redux'
import history from 'app/main/routerHistory'
import { getStatusRequest } from 'app/store/resources/utils/getStatusRequest'
import { getResources } from 'app/store/selectors/getResources'
import resources from 'app/store/resources'
import Loader from 'app/components/Loader/Loader.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { colors } from 'app/styles/colors'
import classes from './NotificationsRightPanel.module.scss'
import Notification from './Notification/Notification.jsx'
import { Permission } from '../Permissions'

export const maxNotifsToDisplay = 100

class NotificationsRightPanel extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    onCloseNotifications: PropTypes.func,
    notifications: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    noNotificationsLabel: PropTypes.string.isRequired,
  }

  static defaultProps = {
    title: 'Notifications',
    noNotificationsLabel: 'No notifications',
  }

  closeTimeout = null

  constructor(props) {
    super(props)

    this.state = {}

    this.onManageSubscriptions = this.onManageSubscriptions.bind(this)
    this.onShowAll = this.onShowAll.bind(this)
  }

  UNSAFE_componentWillMount() {
    if (this.closeTimeout) clearTimeout(this.closeTimeout)
  }

  onCloseNotifications() {
    if (!this.props.onCloseNotifications) return

    this.closeTimeout = setTimeout(this.props.onCloseNotifications, 300)
  }

  onShowAll() {
    this.onCloseNotifications()
    history.push('/notifications/all')
  }

  onManageSubscriptions() {
    this.onCloseNotifications()
    history.push('/notifications/manage')
  }

  onClickNotif = (notification) => {
    this.onCloseNotifications()
    const _notif = { ...notification, unread: false }
    resources.notifications.update(_notif)
  }

  renderNotifs() {
    const { notifications } = this.props
    if (!notifications) return null

    const endIndex = notifications.length < maxNotifsToDisplay ? notifications.length : maxNotifsToDisplay
    const notifsToReturn = []
    for (let i = 0; i < endIndex; i += 1) {
      const notif = notifications[i]
      notifsToReturn.push(
        <div key={notif.id}>
          <Notification notification={notif} isLastNotif={i === endIndex - 1} onClickNotif={this.onClickNotif} />
        </div>,
      )
    }

    return notifsToReturn
  }

  render() {
    const { isLoading, notifications, noNotificationsLabel, title } = this.props

    return (
      <div className={classes.container} data-testid="notifications-righPanel">
        <div className={classes.title}>
          <FontIcon
            icon="fas-bell"
            className={classes.notificationsIcon}
            style={{ color: colors.grey, fontSize: 18 }}
          />

          <div className={classes.titleString}>{title}</div>

          <ButtonList>
            <MUIButton onClick={this.onShowAll}>Show all</MUIButton>
            <Permission actions={['notifications___read']}>
              <MUIButton onClick={this.onManageSubscriptions}>Manage subscriptions</MUIButton>
            </Permission>
          </ButtonList>
        </div>

        <div style={{ height: 'calc(100% - 36px)', maxHeight: 'calc(100% - 36px)', overflowY: 'auto' }}>
          <div className={classes.notifications}>
            {isLoading ? (
              <Loader />
            ) : notifications && notifications.length ? (
              this.renderNotifs()
            ) : (
              noNotificationsLabel
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect((state) => {
  const isLoading = getStatusRequest('notifications', 'fetchNotificationsForRightPanel').pending
  return {
    isLoading,
    notifications: Object.values(getResources(undefined, 'notifications.requests.fetchNotificationsForRightPanel')),
  }
})(NotificationsRightPanel)
