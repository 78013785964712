// @flow
import React, { useState, useCallback } from 'react'
import { type Match, type Location } from 'react-router-dom'
import filter from 'lodash/filter'
import pipe from 'app/core/utils/pipe'
import WikiEditor from 'app/containers/Wiki/WikiEditor/WikiEditor.tsx'
import WikiTree from 'app/containers/Wiki/WikiTree/WikiTree.jsx'
import { AssetsTreeSearch } from 'app/containers/Assets/AssetsTree'
import resources from 'app/store/resources'
import cx from 'classnames'
import type { ID, Asset, Pipe, History } from 'app/core/types'
import { ArrowReturnBottom } from 'app/components/Icons'
import { articleCountIsUpToDate, articleChildCountIsUpToDate } from 'app/core/constants/assetCountMeta'
import { permission } from 'app/containers/Permissions'
import Widget from 'app/components/Widget/Widget.jsx'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import classesMain from 'app/styles/main.module.scss'
import classes from 'app/pages/AssetsWiki/AssetsWiki.module.scss'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'

type Props = {|
  match: Match,
  location: Location,
  history: History,
  project: ?Asset,
  projectId: ?ID,
  asset: ?Asset,
  formatRoute: (projectId: ?ID, assetId: ?ID, articleVersionId?: ID, search?: string) => string,
  rootAssetId: ID,
  hideAssetTree?: boolean,
  hideBreadcrumb?: boolean,
  showRoot?: boolean,
  expandedLevel?: number,
|}

function WikiView(props: Props): React$Node {
  const {
    project,
    location,
    asset,
    formatRoute,
    rootAssetId,
    history,
    projectId,
    hideAssetTree,
    hideBreadcrumb,
    showRoot,
    expandedLevel,
  } = props
  const { assetId, articleId, articleVersion } = props.match.params

  const onClickAsset = useCallback(
    ({ metadata }) => {
      resources.assets.setTreeCountAsRead(metadata.id, 'articleCount')
      history.push(formatRoute(projectId, metadata.id, undefined, location.search))
    },
    [project, location.search],
  )

  const [concatenedColumns, concatColumn] = useState([!hideAssetTree, true])

  return (
    <div className={classes.container} data-testid="page-assetwiki">
      {!hideAssetTree ? (
        <Widget
          style={{ padding: '0 10px 0 0', minWidth: concatenedColumns[0] ? 250 : 30 }}
          enableHideColumn={(value) => concatColumn((state) => [value, state[1]])}
          collapseTitle="Asset list"
        >
          <AssetsTreeSearch
            showRoot={showRoot}
            rootAssetId={rootAssetId}
            projectId={projectId}
            expandedLevel={expandedLevel}
            onClickItem={onClickAsset}
            currentAssetId={assetId}
            onSearchAsset={(assetId) => {
              history.push(formatRoute(projectId, assetId, undefined, location.search))
            }}
            filterAssets={
              !permission(['wiki__articles_create', 'wiki_articles__create'], 'or')
                ? (assets) => filter(assets, (asset) => asset.articleCount > 0 || asset.articleChildCount > 0)
                : undefined
            }
            renderLabel={(asset) => {
              const articleCount = !asset[articleCountIsUpToDate] ? (
                <span className={classes.unread}>({asset.articleCount})</span>
              ) : (
                <span>({asset.articleCount})</span>
              )

              const articleChildCount = !asset[articleChildCountIsUpToDate] ? (
                <span className={classes.unread}>({asset.articleChildCount})</span>
              ) : (
                <span>({asset.articleChildCount})</span>
              )

              const title = asset.attributes && asset.attributes.title && ` - ${asset.attributes.title}`

              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 'inherit',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                    title={`${asset.name} ${title}`}
                  >
                    {asset.name} {title}
                  </div>
                  <div className={classes.countercontainer}>
                    {articleCount}
                    <ArrowReturnBottom width={10} height={15} />
                    {articleChildCount}
                  </div>
                </div>
              )
            }}
            onOpenChild={(asset) => {
              resources.assets.setTreeCountAsRead(asset.id, 'articleChildCount')
            }}
          />
        </Widget>
      ) : null}
      <Widget
        style={{ padding: '0 10px 0 0', minWidth: concatenedColumns[1] ? 250 : 30 }}
        enableHideColumn={(value) => {
          concatColumn((state) => [state[0], value])
        }}
        collapseTitle="Articles"
      >
        {asset ? (
          <div style={{ height: '100%' }}>
            <div className={classes.articleContentTitle}>{asset.name}</div>
            <div className={classes.wikiTreeContent}>
              {assetId ? (
                <WikiTree
                  currentArticleId={articleId}
                  onClickItem={({ metadata }) =>
                    history.push(formatRoute(projectId, assetId, metadata?.id, location.search))
                  }
                  onClickArticle={(assetId, articleId) => formatRoute(projectId, assetId, articleId, location.search)}
                  assetId={assetId}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </Widget>
      <div
        className={cx([classes.articleContent, classesMain.widget])}
        style={{
          maxWidth: `calc(100% - ${
            hideAssetTree ? 0 : (!concatenedColumns[0] ? 30 : 250) + (!concatenedColumns[1] ? 30 : 250)
          }px)`,
        }}
      >
        {articleId && assetId ? (
          <WikiEditor
            key={`${articleId}-${articleVersion || ''}`}
            assetId={assetId}
            articleId={articleId}
            articleVersion={articleVersion}
            enableEdit={true}
            hideBreadcrumb={hideBreadcrumb}
          />
        ) : null}
      </div>
    </div>
  )
}

const pipeInst: Pipe<{ match: Match, location: Location, history: History, projectId?: ID }, typeof WikiView> = pipe()

export const Wiki: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const { history, match, location, projectId: propsProjectId } = props
    const { assetId, projectId: currentProjectId } = match ? match.params : {}

    const projectId = propsProjectId || currentProjectId || getProjectIdFromURL()

    const project = projectId ? state.assets.resources[projectId] : undefined
    const asset = assetId ? state.assets.resources[assetId] : undefined

    documentTitle(`Wiki${asset?.name ? ` - ${asset.name}` : ''}`, 'page')

    return { project, asset, history, match, location, projectId }
  })
  .render(WikiView)
