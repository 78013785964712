// @flow
import React, { useState, useEffect, useRef } from 'react'
import cx from 'classnames'
import { isBoolean, uniqBy, values } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import type { Option, InputProps, AssetTypes } from 'app/core/types'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import vars from 'app/styles/vars.js'
import { colors } from 'app/styles/colors.js'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools.js'
import { assetIcons } from 'app/components/Icons/assetsIcons'

import Validators from '../Validators/Validators.jsx'
import { MUIAutocompleteMultiple } from './MUIAutocompleteMultiple.jsx'

const { tableFont } = vars

let timer: TimeoutID | void

export type AutocompleteProps = {|
  autoComplete?: boolean, // If true, the portion of the selected suggestion that has not been typed by the user, known as the completion string, appears inline after the input cursor in the textbox. The inline completion string is visually highlighted and has a selected state.
  autoHighlight?: boolean,
  autoSelect?: boolean, // If true, the selected option becomes the value of the input when the Autocomplete loses focus unless the user chooses a different option or changes the character string in the input.
  blurOnSelect?: 'mouse' | 'touch' | boolean,
  ChipProps?: Object, // Props applied to the Chip element.
  classes?: Object, // Override or extend the styles applied to the component. See CSS API below for more details.
  clearOnBlur?: boolean, // !props.freeSolo	If true, the input's text will be cleared on blur if no value is selected.
  clearOnEscape?: boolean, // false	If true, clear all values when the user presses escape and the popup is closed.
  clearOnFocus?: boolean,
  clearOnValidate?: boolean,
  clearText?: string, // 'Clear'	Override the default text for the clear icon button.
  closeIcon?: React$Element<any> | null, // <CloseIcon fontSize="small" />	The icon to display in place of the default close icon.
  closeText?: string, // 'Close'	Override the default text for the close popup icon button.
  debug?: boolean, // false	If true, the popup will ignore the blur event if the input is filled. You can inspect the popup markup with your browser tools. Consider this option when you need to customize the component.
  defaultValue?: any, // props.multiple ? [] : null	The default input value. Use when the component is not controlled.
  disableClearable?: boolean, // false	If true, the input can't be cleared.
  disableCloseOnSelect?: boolean, // false	If true, the popup won't close when a value is selected.
  disabled?: boolean, // false	If true, the input will be disabled.
  disabledItemsFocusable?: boolean, // false	If true, will allow focus on disabled items.
  disableListWrap?: boolean, // false	If true, the list box in the popup will not wrap focus.
  disablePortal?: boolean, // false	Disable the portal behavior. The children stay within it's parent DOM hierarchy.
  filterOptions?: (options: Array<Option>, state: Object) => void, // A filter function that determines the options that are eligible.
  filterSelectedOptions?: boolean, // false	If true, hide the selected options from the list box.
  forcePopupIcon?: 'auto' | boolean, // 'auto'	Force the visibility display of the popup icon.
  freeSolo?: boolean, // false	If true, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
  fullWidth?: boolean, // false	If true, the input will take up the full width of its container.
  getLimitTagsText?: (more: number) => React$Element<any> | string, // 	(more) => +${more}	The label to display when the tags are truncated (limitTags).
  getOptionDisabled?: (option: Option) => boolean, // Used to determine the disabled state for a given option.
  getOptionLabel?: (option: Option) => string, // (x) => x	Used to determine the string value for a given option. It's used to fill the input (and the list box options if renderOption is not provided).
  getOptionSelected?: (option: Option, value: any) => boolean, // 		Used to determine if an option is selected, considering the current value. Uses strict equality by default.
  groupBy?: (options: Option) => string, // If provided, the options will be grouped under the returned string. The groupBy value is also used as the text for group headings when renderGroup is not provided.
  handleHomeEndKeys?: boolean, // !props.freeSolo	If true, the component handles the "Home" and "End" keys when the popup is open. It should move focus to the first option and last option, respectively.
  id?: string, // This prop is used to help implement the accessibility logic. If you don't provide this prop. It falls back to a randomly generated id.
  includeInputInList?: boolean, // false	If true, the highlight can move to the input.
  inputValue?: string, // The input value.
  limitTags?: number, // -1	The maximum number of tags that will be visible when not focused. Set -1 to disable the limit.
  ListboxComponent?: (params: Object) => React$Element<any> | string, // 'ul'	The component used to render the listbox.
  ListboxProps?: Object, // Props applied to the Listbox element.
  loading?: boolean, // false	If true, the component is in a loading state.
  loadingText?: React$Element<any>, // 'Loading…'	Text to display when in a loading state.
  multiple?: boolean, // false	If true, value must be an array and the menu will support multiple selections.
  noOptionsText?: React$Element<any>, // 'No options'	Text to display when there are no options.
  onClose?: (event: Object, reason: string) => void, // Callback fired when the popup requests to be closed. Use in controlled mode (see open).
  onHighlightChange?: (event: Object, option: Option, reason: string) => void, // Callback fired when the highlight option changes.
  onInputChange?: (event: Object, value: string, reason: string) => void, // Callback fired when the input value changes.
  onOpen?: (event: Object) => void, // Callback fired when the popup requests to be opened. Use in controlled mode (see open).
  open?: boolean, // Control the popup` open state.
  openOnFocus?: boolean, // false	If true, the popup will open on input focus.
  openText?: string, // 'Open'	Override the default text for the open popup icon button.
  PaperComponent?: (params: Object) => React$Element<any> | string, // Paper	The component used to render the body of the popup.
  PopperComponent?: (params: Object) => React$Element<any> | string, // Popper	The component used to position the popup.
  popupIcon?: React$Element<any>, // <ArrowDropDownIcon />	The icon to display in place of the default popup icon.
  renderGroup?: (params: Object) => React$Element<any>, // Render the group.
  renderInput?: (params: Object) => React$Element<any>, // Render the input.
  renderOption?: (params: Object) => React$Element<any>, // Render the option, use getOptionLabel by default.
  renderTags?: (options: Array<Option>, getTagProps: Function) => React$Element<any> | Array<React$Element<any>>, // Render the selected value.
  selectOnFocus?: boolean, // !props.freeSolo	If true, the input's text will be selected on focus. It helps the user clear the selected value.
  size?: 'medium' | 'small', // 'medium'	The size of the autocomplete.
  options?: Array<Option>,
  value?: any,

  // ADDED PROPS
  ...InputProps,
  onChange?: (option: any) => any, // Callback fired when the value changes.
  disabledOptions?: Array<string>,
  inputRef?: React$ElementRef<any>,
  onSearch?: (inputValue: string) => Promise<any>, // Function for async search only. Returns a promise
  searchInOptions?: boolean, // Boolean that activate the sync search in options
  autoFocus?: boolean,
  additionnalOptions?: Array<Option>,
  defaultOpenOptions?: boolean,
  inputProps?: Object,
  renderLabel?: (opt: Option) => React$Element<any>,
  dataCy?: string | React$Node,
  customItemLabel?: (option: Option) => React$Node,
  subLabel?: React$Node,
  startAdornment?: React$Node,
  endAdornment?: React$Node,
  noStyle?: boolean,

  // Validators
  validatorRef?: Function,
  validatorStyle?: Object,
  errors?: Object,
  isRequired?: boolean,
  validators?: Object,
|}

const useStyles: (Object) => { [key: string]: string } = makeStyles(() => ({
  option: {
    margin: 0,
    padding: 0,
    fontFamily: tableFont,
    fontSize: 16,
    backgroundColor: 'transparent !important',
    color: '#000000',
    '&[data-focus="true"]>div': { boxShadow: '0 0 0 30px rgba(0,0,0,0.1) inset' },
    '&:hover>div': { boxShadow: '0 0 0 30px rgba(0,0,0,0.1) inset' },
    minWidth: 180,
  },
  groupUl: { padding: 0 },
  paper: { borderRadius: '3px', padding: 0, margin: '5px 0' },
  listbox: { padding: 0 },
  root: { width: '100%' },
  textFieldRoot: ({ noStyle, multiple }) => ({
    borderRadius: noStyle ? undefined : 4,
    border: noStyle ? undefined : '1px solid #B3B9c7',
    padding: multiple ? '5px 8px' : '0 8px',
  }),
  textFieldError: {
    borderColor: colors.red,
    boxShadow: `0 0 3px ${colors.red}`,
  },
  textFieldDisabled: {
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
  input: ({ multiple }) => ({
    color: '#4a4a4a',
    padding: '4px 0px !important',
    minWidth: multiple ? '100px !important' : undefined,
  }),
}))

export function MUIAutocomplete(props: AutocompleteProps): React$Node {
  const {
    disabledOptions,
    options: _options,
    onChange,
    onBlur,
    onFocus,
    inputRef,
    openOnFocus,
    defaultOpenOptions,
    onSearch,
    searchInOptions,
    closeIcon,
    open,
    multiple,
    placeholder,
    autoFocus,
    validatorRef,
    validatorStyle,
    errors,
    clearOnEscape = true,
    isRequired = false,
    validators,
    value,
    additionnalOptions,
    inputProps: unusedVar,
    disabled,
    renderLabel,
    defaultValue,
    clearOnValidate,
    clearOnFocus,
    loading,
    dataCy,
    customItemLabel,
    startAdornment: _startAdornment,
    endAdornment: _endAdornment,
    subLabel,
    renderOption,
    noStyle,
    ...rest
  } = props

  const [options, setOptions] = useState([])
  const [searchOptions, setSearchOptions] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedValue, setSelectedValue] = useState(value)
  const [startAdornment, setStartAdornment] = useState()
  const [focus, setFocus] = useState(false)
  const [error, setError] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const isMounted = useRef()
  const _inputRef = useRef()

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    if (typeof selectedValue === 'string' || typeof selectedValue === 'number') {
      setInputValue(selectedValue)
    } else if (Array.isArray(selectedValue)) {
      setInputValue('')
    } else if (!selectedValue || !selectedValue.label) {
      setInputValue('')
    } else {
      setInputValue(selectedValue.label)
      if (selectedValue?.icon) setStartAdornment(selectedValue.icon)
      else if (selectedValue?.assetType && typeof selectedValue.assetType === 'string')
        setStartAdornment(assetIcons(selectedValue.assetType))
      else if (selectedValue?.data?.icon) setStartAdornment(selectedValue.data.icon)
      else if (selectedValue?.data?.assetType) setStartAdornment(assetIcons(selectedValue.data.assetType))
    }
  }, [selectedValue])

  useEffect(() => {
    if (searchInOptions && inputValue) {
      setOptions(uniqBy(searchOptions, 'value'))
    } else {
      let sortedOptions = _options || []
      if (additionnalOptions) sortedOptions = sortedOptions.concat(additionnalOptions)
      if (disabledOptions) sortedOptions = sortedOptions.filter((opt) => !disabledOptions.includes(opt.value))
      if (searchOptions?.length > 0) sortedOptions = sortedOptions.concat(searchOptions)

      setOptions(uniqBy(sortedOptions, 'value'))
    }
  }, [_options, searchOptions, disabledOptions, additionnalOptions])

  useEffect(() => {
    if (loading) setIsSearching(true)
    return () => {
      setIsSearching(false)
    }
  }, [loading])

  type Value = { assetType?: AssetTypes, ...Option }
  function _onChange(event: SyntheticInputEvent<any>, value: Value) {
    if (Array.isArray(value) && onChange) onChange(value.filter((opt) => typeof opt === 'object'))
    else if (typeof value === 'object' && onChange) onChange(value)
    setSearchOptions([])
    if (clearOnValidate) {
      setSelectedValue()
      setInputValue('')
    } else setSelectedValue(value)
    setFocus(false)
  }

  function onSearchInOptions(input: string | number) {
    const inputVal = String(input).toLowerCase().trim()
    // NOTE : be aware to have a label. If no label in option, add a condition to consider another value (option.name, etc)
    const filteredValues = values(_options).filter((option) => option.label.toLowerCase().includes(inputVal))
    return filteredValues
  }

  function _onInputChange(event: SyntheticInputEvent<>, inputText: string) {
    if (startAdornment) setStartAdornment()

    if (searchInOptions && (!inputText || inputText.length === 0)) {
      if (isMounted.current) setSearchOptions(_options)
      return
    }
    if (searchInOptions && inputText && inputText.length > 0) {
      const results = onSearchInOptions(inputText)
      if (isMounted.current) {
        setSearchOptions(results)
        setIsSearching(false)
      }
    }

    if (onSearch && (!inputText || inputText.length === 0)) {
      if (isMounted.current) setSearchOptions([])
      return
    }
    if (onSearch && inputText && inputText.length > 0) {
      if (isMounted.current) setIsSearching(true)

      clearTimeout(timer)
      timer = setTimeout(() => {
        onSearch(inputText).then((results) => {
          if (isMounted.current) {
            setSearchOptions(results)
            setIsSearching(false)
          }
        })
      }, 500)
    }
  }

  function _renderOption(option: Option) {
    const { label, backgroundColor, icon, value, labelCustom } = option

    if (labelCustom) {
      return (
        <div
          className="flex row noWrap alignCenter fullWidth"
          style={{
            backgroundColor,
            padding: '5px 10px',
            color: backgroundColor && getColorFromBackground(backgroundColor),
          }}
          data-testid={cyLabelFormater('autocomplete-choice', value || customItemLabel?.(option) || label)}
        >
          {labelCustom}
        </div>
      )
    }

    return (
      <div
        className="flex row noWrap alignCenter fullWidth"
        style={{
          backgroundColor,
          padding: '3px 6px',
          color: backgroundColor && getColorFromBackground(backgroundColor),
        }}
        data-testid={cyLabelFormater('autocomplete-choice', value || customItemLabel?.(option) || label)}
      >
        {icon ? <FontIcon icon={icon} className="marginRight5" /> : null}
        {customItemLabel?.(option) || label}
      </div>
    )
  }

  function getInputRef(ref: React$ElementRef<any>) {
    if (autoFocus && !_inputRef.current) {
      ref?.select?.()
      _inputRef.current = ref
    }

    if (inputRef) {
      if (typeof inputRef === 'function') inputRef(ref)
      else if (typeof inputRef === 'object') inputRef.current = ref
    }
  }

  function _renderInput(InputParams: Object) {
    const { InputProps, inputProps, ...inputParams } = InputParams
    const { textFieldError, textFieldDisabled, textFieldRoot } = useStyles({ multiple, noStyle })

    const _onChangeInput = (e) => {
      if (e.target.value) inputProps.onChange(e)
      setInputValue(e.target.value)
      _onInputChange(e, e.target.value)
      setFocus(true)
    }

    return (
      <TextField
        InputProps={{
          ...InputProps,
          disableUnderline: true,
          startAdornment:
            startAdornment || _startAdornment ? (
              <>
                {_startAdornment}
                {startAdornment ? <FontIcon icon={startAdornment} className="grey marginRight5" /> : null}
              </>
            ) : undefined,
          endAdornment:
            _endAdornment ||
            (isSearching ? (
              <LoaderSmall />
            ) : !noStyle ? (
              <FontIcon icon="fas-search" className={focus ? undefined : 'grey'} />
            ) : undefined),
          inputProps: {
            ...inputProps,
            value: inputValue,
            onChange: _onChangeInput,
            'data-testid': cyLabelFormater('input', dataCy || placeholder),
          },
        }}
        classes={{
          root: cx(textFieldRoot, {
            [textFieldError]: error,
            [textFieldDisabled]: disabled,
          }),
        }}
        {...inputParams}
        onClick={(event: SyntheticMouseEvent<>) => {
          setFocus(true)
        }}
        inputRef={getInputRef}
        placeholder={placeholder}
      />
    )
  }

  function _getOptionSelected(option: Option, selectedValue: ?mixed) {
    if (!selectedValue) return true
    if (!option) return false

    if (typeof selectedValue === 'string' && selectedValue === '') {
      return false
    }
    if (typeof selectedValue === 'object') {
      if (Object.keys(selectedValue).length === 0) return true
      return option.value === selectedValue.value
    }
    if (['string', 'number'].includes(typeof selectedValue)) {
      return option.value === selectedValue
    }

    return false
  }

  const { option, listbox, root, input, paper, groupUl } = useStyles({ multiple, noStyle })

  if (multiple === true) return <MUIAutocompleteMultiple {...props} />

  return (
    <div className="relative fullWidth">
      <Autocomplete
        autoComplete={true}
        forcePopupIcon={false}
        clearOnEscape={clearOnEscape}
        disableClearable={true}
        autoSelect={false}
        clearOnBlur={false}
        openOnFocus={openOnFocus || defaultOpenOptions}
        renderInput={_renderInput}
        fullWidth={true}
        classes={{ option, listbox, root, input, paper, groupUl }}
        inputValue={inputValue}
        filterOptions={(options, state) => options}
        data-testid="autocomplete"
        PopperComponent={(popperProps: Object) => {
          const { style, ...popperPropsRest } = popperProps
          return <Popper style={{ ...style, width: undefined }} {...popperPropsRest} />
        }}
        PaperComponent={(paperProps: Object) => {
          const { style, ...paperPropsRest } = paperProps
          return <Paper style={{ ...style, width: undefined }} {...paperPropsRest} elevation={8} />
        }}
        noOptionsText="No results"
        onChange={_onChange}
        open={disabled ? false : isBoolean(open) ? open : focus && options.length > 0}
        disabled={disabled}
        loading={isSearching}
        onFocus={(event: SyntheticFocusEvent<>) => {
          if (onFocus) onFocus(event)
          if (clearOnFocus) setInputValue('')
          setFocus(true)
        }}
        onBlur={(event: SyntheticFocusEvent<>) => {
          if (onBlur) onBlur(event)
          if (clearOnFocus && !inputValue) {
            if (typeof value === 'string') setInputValue(value)
            else if (typeof value === 'object') setInputValue(value?.label)
          }
          setFocus(false)
        }}
        {...rest}
        options={options}
        renderOption={renderOption || _renderOption}
        getOptionLabel={(option: Option) => option.label || ''}
        getOptionSelected={_getOptionSelected}
        value={value || ''}
      />
      {subLabel}
      <Validators
        ref={(ref) => {
          if (validatorRef) validatorRef(ref)
        }}
        style={{ ...validatorStyle, position: 'absolute', right: 0 }}
        value={value || ''}
        errors={errors}
        onSuccess={() => setError(false)}
        onError={() => setError(true)}
        validators={{
          required: isRequired,
          ...validators,
        }}
      />
    </div>
  )
}
