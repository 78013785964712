var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import { MUIButton } from '../Form/index.js';
import LoaderSmall from '../LoaderSmall/LoaderSmall.jsx';
import { getColorFromBackground } from '../../libs/helpers/getColorFromBackground.js';
import classes from './MUIModal.module.scss';
function DraggableComponent(_a) {
    var { className, nodeRef, style, children, disableBackdrop, defaultPosition, setDefaultPosition } = _a, props = __rest(_a, ["className", "nodeRef", "style", "children", "disableBackdrop", "defaultPosition", "setDefaultPosition"]);
    if (!defaultPosition) {
        defaultPosition = {
            x: window.innerWidth / 2,
            y: 0,
        };
    }
    return (
    // @ts-ignore
    _jsx(Draggable, { cancel: '[class*="MuiDialogContent-root"]', position: disableBackdrop ? defaultPosition : undefined, onStop: disableBackdrop ? (e, data) => setDefaultPosition({ x: data.x, y: data.y }) : undefined, children: _jsx(Paper, Object.assign({ className: cx(className) }, props, { style: Object.assign(Object.assign({}, style), (disableBackdrop
                ? {
                    minWidth: 'min-content',
                    minHeight: 'min-content',
                }
                : {})), children: _jsx("div", { children: children }) })) }));
}
export function MUINewModal(props) {
    const { innerHeight, innerWidth } = window;
    const { disableBackdrop, disableBackdropClick = true, minWidth = 400, maxWidth = innerWidth - 100, minHeight = 200, maxHeight = innerHeight - 200, width = props.minWidth || 600, height = props.minHeight || 400, open = true, title, titleColor, childreContainerStyle, exponentTitle, resizable = false, draggable = false, keepMounted = false, disablePortal = false, disableEnforceFocus = true, extendsButtons, onRequestClose = () => { }, disableValidate = false, validateLabel, validateLabelColor, hideValidate, cancelLabel, cancelLabelColor, hideCancel, disableCancel, onValidate = () => { }, children, onCancel = () => { }, loading, setModalIsOpen, } = props;
    const [defaultPosition, setDefaultPosition] = useState();
    const [error, setError] = useState(null);
    const isMounted = useRef(false);
    const modalRef = useRef();
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });
    const handleClose = () => { };
    const handleCancel = () => setModalIsOpen(false);
    function wrapResizable(content) {
        if (!resizable)
            return content;
        return (_jsx("div", { onMouseDown: (e) => {
                e.stopPropagation();
            }, children: _jsx(ResizableBox, { width: Math.min(width, maxWidth), height: Math.min(height, maxHeight), minConstraints: [minWidth, minHeight], maxConstraints: [maxWidth, maxHeight], children: content }) }));
    }
    function viewableError(error) {
        if (typeof error === 'string')
            return error;
        if (Array.isArray(error))
            return error.map((err, index) => (
            // eslint-disable-next-line react/no-array-index-key
            _jsxs("span", { children: [String(err), _jsx("br", {})] }, index)));
        if (typeof error === 'object')
            return Object.values(error).map((err, index) => (
            // eslint-disable-next-line react/no-array-index-key
            _jsxs("span", { children: [String(err), _jsx("br", {})] }, index)));
        return null;
    }
    let computedChildren = children;
    if (typeof children === 'function')
        computedChildren = children({ handleClose });
    let content = (_jsxs("div", { className: classes.contentContainer, style: { width: !resizable ? width : undefined }, onMouseDown: (e) => {
            e.stopPropagation();
        }, children: [_jsx("div", { className: classes.childrenContainer, style: childreContainerStyle, children: computedChildren }), !!error && (_jsxs("div", { className: classes.error, children: [_jsx("span", { className: "fontSize18 marginRight10", children: "\u26A0" }), viewableError(error)] })), _jsxs("div", { className: `${classes.buttonsContainer} flex row noWrap marginRight10 marginTop10`, children: [!hideCancel ? (_jsx(MUIButton, { "data-testid": "modal-cancel", onClick: handleCancel, bgColor: cancelLabelColor, color: "default", disabled: disableCancel, children: cancelLabel || 'Cancel' })) : null, extendsButtons && (_jsx("div", { className: "marginLeft10 flex row noWrap", children: typeof extendsButtons === 'function' ? extendsButtons({ onRequestClose }) : extendsButtons })), !hideValidate ? (_jsxs(MUIButton, { style: { marginLeft: 8 }, onClick: onValidate, bgColor: validateLabelColor, color: validateLabelColor ? 'default' : 'secondary', disabled: loading || disableValidate, "data-testid": "modal-confirm", children: [loading && _jsx(LoaderSmall, { style: { marginRight: 10 } }), " ", validateLabel || 'Ok'] })) : null] })] }));
    if (resizable)
        content = wrapResizable(content);
    return (_jsxs(Dialog, { open: open, onClose: handleClose, 
        // @ts-ignore
        PaperComponent: draggable ? DraggableComponent : Paper, 
        // @ts-ignore
        PaperProps: disableBackdrop ? { disableBackdrop, defaultPosition, setDefaultPosition } : undefined, maxWidth: false, keepMounted: keepMounted, disablePortal: disablePortal, disableEnforceFocus: disableEnforceFocus, style: disableBackdrop ? { width: 1, height: 1 } : undefined, classes: {
            root: classes.dialogRoot,
            scrollPaper: disableBackdrop ? classes.dialogScrollPaper : undefined,
        }, ref: modalRef, children: [title && (_jsxs(DialogTitle, { style: {
                    backgroundColor: titleColor || '#3DC3D2',
                    cursor: draggable ? 'move' : 'normal',
                    padding: '8px 25px 8px 25px',
                    textAlign: 'center',
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    zIndex: 2,
                }, children: [exponentTitle ? (_jsx("div", { className: classes.exponentTitle, style: { color: titleColor ? getColorFromBackground(titleColor) : '#ffffff' }, children: exponentTitle })) : null, _jsx("span", { style: { color: titleColor ? getColorFromBackground(titleColor) : '#ffffff' }, children: title })] })), content] }));
}
