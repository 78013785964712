import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { capitalize } from 'lodash';
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx';
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js';
import { TableMyHours } from './TableMyHours';
import classes from './MyHours.module.scss';
const tableId = 'my-allotment-hours-tasks';
export function MyAllotmentHours(props) {
    const { match, history } = props;
    const { formatView = 'month', selectedWeek } = match.params;
    const _selectedWeek = useMemo(() => {
        if (selectedWeek)
            return moment(selectedWeek, formatView === 'month' ? 'MM-YYYY' : 'w-YYYY');
        return moment();
    }, [selectedWeek]);
    const _formatView = formatView === 'week' || formatView === 'month' ? formatView : 'week';
    useEffect(() => {
        documentTitle(_formatView === 'week' ? `Week ${_selectedWeek.format('w')}` : capitalize(_selectedWeek.format('MMMM')));
    }, [_formatView, _selectedWeek]);
    useEffect(() => {
        if (_formatView === 'week' || _formatView === 'month') {
            userPrefLocalResource.setData('hoursFormatView', _formatView);
        }
    }, [_formatView]);
    return (_jsx("div", { className: classes.container, "data-testid": "page-myAllotmentHours", children: _jsx(TableMyHours, { tableId: tableId, formatView: _formatView, selectedWeek: _selectedWeek, history: history, match: match, taskType: "alt" }) }));
}
