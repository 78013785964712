// @flow
import * as React from 'react'
import { useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import type { ElementProps, IconType } from 'app/core/types'
import { MUIButton } from '../Form'

import classes from './ButtonMenu.module.scss'

type Props = {|
  ...ElementProps,
  children: any,
  label: string | React.Element<any>,
  paperProps?: Object,
  component?: *,
  icon?: IconType,
  tooltip?: string | React.Element<any>,
|}

export function ButtonMenu(props: Props): React$Node {
  const { children, label, paperProps, component, ...rest } = props

  const Button = component || MUIButton

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick: Function = (event: Event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose: Function = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button onClick={handleClick} data-testid="buttonMenu" {...rest}>
        {label}
      </Button>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper className={classes.paper} {...paperProps}>
          <ClickAwayListener onClickAway={handleClose}>
            <div>{typeof children === 'function' ? children(handleClose) : children}</div>
          </ClickAwayListener>
        </Paper>
      </Popover>
    </>
  )
}
