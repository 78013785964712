var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    fetchLoading: false,
    updateLoading: false,
    data: [],
    count: 0,
    error: null,
};
export const fetchAllActivitiesApprovals = createAsyncThunk('activitiesApprovals/fetchAll', (queries_1, _a) => __awaiter(void 0, [queries_1, _a], void 0, function* (queries, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.activityApprovals.fetchAll(Object.assign({}, queries));
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createActivityApproval = createAsyncThunk('activitiesApprovals/create', (toCreate_1, _a) => __awaiter(void 0, [toCreate_1, _a], void 0, function* (toCreate, { fulfillWithValue, rejectWithValue }) {
    try {
        let res;
        if (Array.isArray(toCreate)) {
            res = yield api.activityApprovals.collection.create([...toCreate]);
        }
        else {
            res = yield api.activityApprovals.create(toCreate);
        }
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateActivityApproval = createAsyncThunk('activitiesApprovals/update', (toUpdate_1, _a) => __awaiter(void 0, [toUpdate_1, _a], void 0, function* (toUpdate, { fulfillWithValue, rejectWithValue }) {
    try {
        let res;
        if (Array.isArray(toUpdate)) {
            res = yield api.activityApprovals.collection.update([...toUpdate]);
        }
        else {
            res = yield api.activityApprovals.update(toUpdate);
        }
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteActivityApproval = createAsyncThunk('activitiesApprovals/delete', (toDelete_1, _a) => __awaiter(void 0, [toDelete_1, _a], void 0, function* (toDelete, { fulfillWithValue, rejectWithValue }) {
    try {
        if (toDelete.length > 1) {
            const promises = toDelete.map((aa) => {
                return api.activityApprovals.delete(aa);
            });
            yield Promise.all(promises);
        }
        else {
            yield api.activityApprovals.delete(toDelete[0]);
        }
        return fulfillWithValue(toDelete);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'activitiesApprovals',
    initialState,
    reducers: {
        setActivitiesApprovals: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL ACTIVITIES APPROVALS
        builder.addCase(fetchAllActivitiesApprovals.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllActivitiesApprovals.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.count = payload.count;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllActivitiesApprovals.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // CREATE ACTIVITY APPROVAL
        builder.addCase(createActivityApproval.pending, () => { });
        builder.addCase(createActivityApproval.fulfilled, (state, { payload }) => {
            state.data = [...state.data, ...payload];
        });
        builder.addCase(createActivityApproval.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // UPDATE ACTIVITY APPROVAL
        builder.addCase(updateActivityApproval.pending, (state) => {
            state.updateLoading = true;
        });
        builder.addCase(updateActivityApproval.fulfilled, (state, { payload }) => {
            if (Array.isArray(payload)) {
                let newData = [...state.data];
                payload.forEach((updatedApproval) => {
                    const aaToUpdate = newData.find((aa) => aa.id === updatedApproval.id);
                    newData = newData.filter((aa) => aa.id !== (aaToUpdate === null || aaToUpdate === void 0 ? void 0 : aaToUpdate.id));
                });
                state.data = [...newData, ...payload];
            }
            else {
                state.data = state.data.map((aa) => {
                    return aa.id === payload.id ? payload : aa;
                });
            }
            state.updateLoading = false;
        });
        builder.addCase(updateActivityApproval.rejected, (state, { payload }) => {
            state.error = payload;
            state.updateLoading = false;
        });
        // DELETE ACTIVITY APPROVAL
        builder.addCase(deleteActivityApproval.pending, () => { });
        builder.addCase(deleteActivityApproval.fulfilled, (state, { payload }) => {
            if (Array.isArray(payload)) {
                let newData = [...state.data];
                payload.forEach((deletedApprovalId) => {
                    const aaToDelete = newData.find((aa) => aa.id === deletedApprovalId);
                    newData = newData.filter((aa) => aa.id !== (aaToDelete === null || aaToDelete === void 0 ? void 0 : aaToDelete.id));
                });
                state.data = [...newData, ...payload];
            }
            else {
                state.data = state.data.filter((aa) => aa.id !== payload);
            }
        });
        builder.addCase(deleteActivityApproval.rejected, (state, { payload }) => {
            state.error = payload;
        });
    },
});
export const { setActivitiesApprovals } = actions;
export default reducer;
