/** @flow */
import React from 'react'
import cx from 'classnames'
import MuiToggleButton from '@material-ui/lab/ToggleButton'
import MuiToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import MuiMenu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core'
import type { ElementProps, IconType } from 'app/core/types'
import type { ElementProps as TSElementProps, IconType as TSIconType } from 'app/core/types/types.ts'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'

export interface TSToggleButtonProps extends TSElementProps {
  onClick?: (e: SyntheticMouseEvent<>) => void;
  icon?: TSIconType;
  label?: string;
  disabled?: boolean;
  selected?: boolean;
  tooltip?: string;
  value?: any | 'auto';
  items?: { label: string, onClick: Function, icon?: IconType, key: string }[];
  options?: {
    label: string,
    onClick: Function,
    icon?: IconType,
    key: string,
    selected?: boolean,
    // TO DO: typé les props de MuiMenuItem
    /* ...$Shape<typeof MuiMenuItem>, */
  }[];
  labelColor?: string;
  bgColor?: string;
  color?: string;
  loader?: boolean;
  classes?: { [key: string]: string };
  dataCy?: string;
  filterUnderContract?: boolean;
}

export type ToggleButtonProps = {|
  ...ElementProps,
  onClick?: (e: SyntheticMouseEvent<>) => void,
  icon?: IconType,
  label?: string,
  disabled?: boolean,
  selected?: boolean,
  tooltip?: string,
  value?: mixed | 'auto',
  items?: Array<{ label: string, onClick: Function, icon?: IconType, key: string }>,
  options?: Array<{
    label: string,
    onClick: Function,
    icon?: IconType,
    key: string,
    selected?: boolean,
    ...$Shape<typeof MuiMenuItem>,
  }>,
  labelColor?: string,
  bgColor?: string,
  color?: string,
  loader?: boolean,
  classes?: { [key: string]: string },
  dataCy?: string,
  filterUnderContract?: boolean,
|}

const useStyles = makeStyles((theme) => {
  return {
    root: ({ color, bgColor }) => ({
      '&:hover': {
        backgroundColor: bgColor,
        color: color || (bgColor && '#ffffff') || 'initial',
        boxShadow: 'none',
      },
      '&:active': { boxShadow: 'none' },
      backgroundColor: bgColor,
      color: color || (bgColor && '#ffffff') || 'initial',
      height: 30,
    }),
    selected: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: '#ffffff !important',
    },
    label: {
      textTransform: 'none',
      fontSize: 14,
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
  }
})

export function ToggleButton(props: ToggleButtonProps): React$Node {
  const {
    tooltip,
    value,
    disabled,
    icon,
    children,
    items,
    labelColor,
    bgColor,
    classes,
    style,
    loader,
    label,
    dataCy,
    options,
    selected,
    onClick,
    filterUnderContract,
    ...rest
  } = props

  const colorsClasses = useStyles({ color: labelColor, bgColor })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [optionsAnchorEl, setOptionsAnchorEl] = React.useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleOptionsClick = (event) => setOptionsAnchorEl(event.currentTarget)
  const handleOptionsClose = () => setOptionsAnchorEl(null)

  if (options && style) style.marginRight = 0

  function handleButtonClick(e) {
    if (onClick) onClick(e)
    else if (options) handleOptionsClick(e)
  }

  let Button = (
    <MuiToggleButton
      disabled={disabled}
      value={value || 'auto'}
      style={{
        height: 30,
        padding: !children && !label ? '0 0 0 11px' : '0 11px',
        borderTopRightRadius: filterUnderContract ? 0 : undefined,
        borderBottomRightRadius: filterUnderContract ? 0 : undefined,
        ...style,
      }}
      classes={{
        ...classes,
        root: cx([(bgColor || labelColor) && colorsClasses.root, classes && classes.root]),
        label: colorsClasses.label,
        selected: colorsClasses.selected,
      }}
      data-testid={cyLabelFormater('toggleButton', dataCy || label || children || tooltip)}
      selected={Boolean(selected)}
      onClick={handleButtonClick}
      {...rest}
    >
      {icon ? <FontIcon icon={icon} className="marginRight10" /> : null}
      {label || children}
    </MuiToggleButton>
  )
  if (tooltip && !disabled && !items) {
    Button = <Tooltip title={tooltip}>{Button}</Tooltip>
  }
  if (options) {
    Button = (
      <MuiToggleButtonGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 30, ...style }}>
        {Button}
        <MuiToggleButton
          style={{ height: 30, padding: '0 7px' }}
          onClick={handleOptionsClick}
          classes={{
            ...classes,
            root: cx([(bgColor || labelColor) && colorsClasses.root, classes && classes.root]),
            label: colorsClasses.label,
            selected: colorsClasses.selected,
          }}
          value="auto"
          data-testid={cyLabelFormater('toggleButton-menu', dataCy || label || children || tooltip)}
        >
          <FontIcon icon="fas-caret-down" />
        </MuiToggleButton>
        <MuiMenu
          MenuListProps={{ style: { padding: 0 } }}
          anchorEl={optionsAnchorEl}
          getContentAnchorEl={null}
          keepMounted={true}
          open={Boolean(optionsAnchorEl)}
          onClose={handleOptionsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {options.map(({ label, onClick, icon, key, selected, ...restOption }) => (
            <MuiMenuItem
              selected={selected}
              elevation={0}
              style={{
                padding: '5px 10px',
                fontFamily: '"Helvetica", "Arial", sans-serif',
                fontSize: 16,
                color: '#4a4a4a',
              }}
              data-testid={cyLabelFormater('button', label || key)}
              onClick={() => {
                onClick()
                handleOptionsClose()
              }}
              key={key}
              {...restOption}
            >
              <div className="flex row noWrap alignCenter">
                {loader ? (
                  <LoaderSmall style={{ marginRight: 5 }} />
                ) : icon ? (
                  <FontIcon icon={icon} className="marginRight10" />
                ) : null}
                {label}
              </div>
            </MuiMenuItem>
          ))}
        </MuiMenu>
      </MuiToggleButtonGroup>
    )
  }

  if (items) {
    Button = (
      <>
        {React.cloneElement(Button, { onClick: handleClick })}
        <MuiMenu
          MenuListProps={{ style: { padding: 0 } }}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {items.map(({ label, onClick, icon, key }) => (
            <MuiMenuItem
              elevation={0}
              style={{
                padding: '5px 10px',
                fontFamily: '"Helvetica", "Arial", sans-serif',
                fontSize: 16,
                color: '#4a4a4a',
              }}
              data-testid={cyLabelFormater('button', label || key)}
              onClick={() => {
                onClick()
                handleClose()
              }}
              key={key}
            >
              <div className="flex row noWrap alignCenter">
                {loader ? (
                  <LoaderSmall style={{ marginRight: 5 }} />
                ) : icon ? (
                  <span className="marginRight5">
                    <FontIcon icon={icon} />
                  </span>
                ) : null}
                {label}
              </div>
            </MuiMenuItem>
          ))}
        </MuiMenu>
      </>
    )
  }

  return Button
}

export function ToggleButtonGroup({ style, ...props }: Object): React$Node {
  return (
    <div>
      <MuiToggleButtonGroup
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', height: 30, ...style }}
        {...props}
      />
    </div>
  )
}
