// @flow
import React, { useEffect, useMemo, useState } from 'react'
import resources from 'app/store/resources'
import { useSelector } from 'react-redux'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'
import { MUIButton } from 'app/components/Form'
import { ModalTrigger, confirmDelete } from 'app/components/Modal'
import type { Flag } from 'app/core/types'
import { getResources } from 'app/store/selectors/getResources'
import { map, sortBy, filter, uniq } from 'lodash'
import { Permission } from 'app/containers/Permissions/Permission.jsx'
import Widget from 'app/components/Widget/Widget.jsx'
import Loader from 'app/components/Loader/Loader.jsx'
import useDebounce from 'app/hooks/useDebounce'
import Tooltip from '@material-ui/core/Tooltip'
import EditFlagModal from './EditFlagModal.jsx'
import classes from './Flags.module.scss'

type Props = {|
  category: string,
  searchInput?: string,
|}

const categories = {
  article: 'article',
  take: 'take',
  dynApproval: 'dynamic approval value',
  ep: 'episode',
  fo: 'folder',
  mo: 'model',
  sh: 'shot',
  pj: 'project',
  dynamicApprovalValue: 'dynamic approval value',
  post: 'post',
}

export default function FlagsComponent(props: Props): React$Node {
  const { category, searchInput } = props
  const flags = useSelector((state) => getResources(state, 'flags', { category }))
  const allFlags = useSelector((state) => getResources(state, 'flags'))
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const debouncedSearchTerm: string = useDebounce(searchInput, 300)
  const searchFlags: Flag[] = useMemo(() => {
    return filter(allFlags, (flag: Flag) => flag.name.includes(debouncedSearchTerm))
  })

  useEffect(() => {
    setIsLoading(true)
    searchInput
      ? resources.flags.search({ query: debouncedSearchTerm }).then(() => {
          setIsLoading(false)
        })
      : resources.flags
          .fetchFlags({
            params: { queries: { category } },
          })
          .then(() => {
            setIsLoading(false)
          })
  }, [searchInput])

  function onDelete(flag: Flag) {
    confirmDelete({
      title: 'Delete',
      render: (
        <div>
          Do you want to delete <b>{flag.name}</b> ?
        </div>
      ),
      validateLabelColor: '#E56D7A',
      validateLabel: 'Delete',
      onValidate: () => resources.flags.delete(flag.id),
      validateMessage: 'Flag deleted',
    })
  }

  const categoriesSearchFlags = uniq(map(sortBy(searchFlags, ['category']), (flag) => categories[flag.category]))
  const searchFlagsByCategories = categoriesSearchFlags.map((cat) =>
    map(
      filter(searchFlags, (flag) => {
        return categories[flag.category] === cat
      }),
      (flag) => ({
        cat,
        flag,
      }),
    ),
  )

  if (isLoading) return <Loader />

  return (
    <Widget className={classes.flagsContainer} style={{ width: '100%' }}>
      <div className={classes.newFlag}>
        <Permission actions={['projet_flags__update']}>
          <ModalTrigger modal={<EditFlagModal category={category} categories={categories} searchInput={searchInput} />}>
            <MUIButton icon="fas-plus" style={{ marginLeft: 5 }}>
              Create a new {categories[category]} flag
            </MUIButton>
          </ModalTrigger>
        </Permission>
      </div>

      {!isLoading && !searchInput && Object.keys(flags).length === 0 ? (
        <div className={classes.noFlag}>No flag</div>
      ) : !isLoading && searchInput && searchFlags.length === 0 ? (
        <div className={classes.noFlag}>No search result</div>
      ) : !searchInput ? (
        <div className={classes.flags}>
          {map(
            sortBy(flags, (flag) => {
              return flag.name.toLowerCase()
            }),
            (flag) => (
              <div
                key={flag.id}
                style={{
                  backgroundColor: flag.color,
                  color: getColorFromBackground(flag.color),
                }}
                className={classes.flagsItems}
              >
                <Tooltip title={`Description: ${flag.description || 'No description'}`}>
                  <span className={classes.flag}>{flag.name}</span>
                </Tooltip>

                <Permission actions={['projet_flags__update']}>
                  <ModalTrigger
                    modal={
                      <EditFlagModal
                        flag={flag}
                        category={flag.category}
                        color={flag.color}
                        categories={categories}
                        searchInput={searchInput}
                      />
                    }
                  >
                    <MUIButton
                      style={{
                        color: getColorFromBackground(flag.color),
                        minWidth: '20px',
                        minHeight: '20px',
                      }}
                      className={classes.icon}
                      size="small"
                      variant="text"
                      icon="fas-edit"
                      dataCy={`flags-edit-${flag.name}`}
                    />
                  </ModalTrigger>
                </Permission>
                <Permission actions={['projet_flags__update']}>
                  <MUIButton
                    style={{
                      color: getColorFromBackground(flag.color),
                      minWidth: '20px',
                      minHeight: '20px',
                    }}
                    className={classes.icon}
                    icon="fas-trash"
                    size="small"
                    variant="text"
                    onClick={() => onDelete(flag)}
                    dataCy={`flags-delete-${flag.name}`}
                  />
                </Permission>
              </div>
            ),
          )}
        </div>
      ) : (
        <div style={{ overflowY: 'auto', height: '100%' }}>
          {searchFlagsByCategories.map((array) => (
            <>
              <h3>{`${array[0].cat[0].toUpperCase() + array[0].cat.slice(1)} (${array.length}) :`}</h3>
              <div className={classes.flags} style={{ justifyContent: 'flex-start', margin: '0', width: '100%' }}>
                {array.map((flag) => (
                  <div
                    key={flag.flag.id}
                    style={{
                      backgroundColor: flag.flag.color,
                      color: getColorFromBackground(flag.flag.color),
                    }}
                    className={classes.flagsItems}
                  >
                    <Tooltip title={`Description: ${flag.flag.description || 'No description'}`}>
                      <span className={classes.flag}>{flag.flag.name}</span>
                    </Tooltip>

                    <Permission actions={['projet_flags__update']}>
                      <ModalTrigger
                        modal={
                          <EditFlagModal
                            flag={flag.flag}
                            category={flag.flag.category}
                            color={flag.flag.color}
                            categories={categories}
                            searchInput={searchInput}
                          />
                        }
                      >
                        <MUIButton
                          style={{
                            color: getColorFromBackground(flag.flag.color),
                            minWidth: '20px',
                            minHeight: '20px',
                          }}
                          className={classes.icon}
                          size="small"
                          variant="text"
                          icon="fas-edit"
                          dataCy={`flags-edit-${flag.flag.name}`}
                        />
                      </ModalTrigger>
                    </Permission>
                    <Permission actions={['projet_flags__update']}>
                      <MUIButton
                        style={{
                          color: getColorFromBackground(flag.flag.color),
                          minWidth: '20px',
                          minHeight: '20px',
                        }}
                        className={classes.icon}
                        icon="fas-trash"
                        size="small"
                        variant="text"
                        onClick={() => onDelete(flag.flag)}
                        dataCy={`flags-delete-${flag.flag.name}`}
                      />
                    </Permission>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
    </Widget>
  )
}
