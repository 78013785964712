// @flow
import React from 'react'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { confirmDelete } from 'app/components/Modal'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import { Permission } from 'app/containers/Permissions'

import { Node, NODE_ITEM } from 'app/components/TreeDnD/Node'
import type { DynamicApproval } from 'app/core/types'
import classes from './DynamicApprovalsEditor.module.scss'

type GetDynamicApprovalNodeProps = {|
  dynamicApproval: DynamicApproval,
  onSelect: Function,
  onDelete: Function,
  parentNode: Node<>,
|}

export function getDynamicApprovalNode(props: GetDynamicApprovalNodeProps): Node<> {
  const { dynamicApproval, onSelect, onDelete, parentNode } = props

  return new Node({
    key: dynamicApproval.id,
    icon: 'fas-grip-vertical',
    name: (
      <div
        className={classes.listItemContainer}
        data-testid={cyLabelFormater('dynapp-item', dynamicApproval.name)}
        onClick={() => onSelect(dynamicApproval.id)}
      >
        <span className={classes.dynamicApprovalLabel}>{dynamicApproval.name}</span>
        <div className={classes.listItem__actionContainer}>
          <Permission actions={['projet_follow-up_dynamic approval management_delete']}>
            <FontIcon
              className={classes.iconButton}
              icon="trash"
              style={{ padding: 4 }}
              data-testid="dynapp-node-delete-button"
              onClick={() => {
                confirmDelete({
                  render: (
                    <div>
                      <p style={{ fontSize: 48, fontWeight: 'bold' }}>⚠ ATTENTION ⚠</p>
                      Deleting a dynamic approval is irreversible.
                      <br />
                      You will no longer be able to use his name in the future.
                      <br />
                      If you want to make a change, prefer the edition button.
                    </div>
                  ),
                  onValidate: () => onDelete(dynamicApproval.id),
                  validateMessage: 'Dynamic approval deleted',
                })
              }}
            />
          </Permission>
        </div>
      </div>
    ),
    parent: parentNode,
    data: { ...dynamicApproval, type: 'dynamicApprovals' },
    type: NODE_ITEM,
  })
}
