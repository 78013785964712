// @flow
import React from 'react'
import { getInputByType, viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { regex } from 'app/libs/helpers/regex'
import type { ViewableType } from 'app/core/types'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import classes from './Choice.module.scss'
import keyCode from '../../../libs/keyCode'

type ChoiceProps = {|
  onChange: Function,
  data: Array<string>,
  type: ViewableType,
|}

type ChoiceState = {|
  value: any,
  error: boolean,
|}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = (isDraggingOver) => ({
  width: '100%',
})

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export class Choice extends React.PureComponent<ChoiceProps, ChoiceState> {
  state: ChoiceState = {
    value: undefined,
    error: true,
  }

  scrollContainer: React$ElementRef<any> = React.createRef()

  onChange: Function = (data: Object) => {
    const { type } = this.props
    const { value } = data
    this.setState({ error: false })
    if (type === 'email') this.setState({ error: !regex.is.email.test(value) })
    if (type === 'ip') this.setState({ error: !regex.is.ip.test(value) })
    if (type === 'url') this.setState({ error: !regex.is.url.test(value) })
    this.setState(data)
  }

  addItem: Function = () => {
    const { value } = this.state
    const { onChange, data = [] } = this.props
    if (!value || data.indexOf(value) !== -1) return

    data.push(value)

    if (onChange && typeof onChange === 'function') {
      this.onChange(data)
      this.setState({ value: undefined }, () => {
        this.scrollContainer.current.scrollTop = this.scrollContainer.current.scrollHeight
      })
    }
  }

  removeItem: Function = (value: string) => {
    const { onChange, data } = this.props
    const filterdData = data.filter((name) => name !== value)

    if (onChange && typeof onChange === 'function') {
      onChange(filterdData)
    }
  }

  onKeyDown: Function = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === keyCode.ENTER) {
      this.addItem()
    }
  }

  onDragEnd: Function = (result: any) => {
    const { onChange, data } = this.props
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const items = reorder(data, result.source.index, result.destination.index)

    onChange(items)
  }

  render(): React$Node {
    const { data, type } = this.props
    const { error } = this.state

    return (
      <div className={classes.container} data-testid="choice-component">
        <div className={classes.input}>
          {getInputByType({
            type,
            key: 'value',
            data: this.state,
            setData: this.onChange,
            props: { onKeyDown: this.onKeyDown },
          })}
          <FontIcon
            icon="fas-plus"
            data-testid="choice-validate"
            onClick={!error ? this.addItem : () => {}}
            className={classes.icon}
            style={error ? { color: 'lightgray', cursor: 'not-allowed' } : {}}
          />
        </div>
        <div className={classes.itemsScrollBlock} ref={this.scrollContainer}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {data.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => {
                        // react-beautiful-dnd library causes issues with react-modal library. To avoid this, we put the code below. See https://github.com/atlassian/react-beautiful-dnd/issues/1881
                        if (snapshot.isDragging) {
                          provided.draggableProps.style = {
                            ...provided.draggableProps.style,
                            left: provided.draggableProps.style.offsetLeft,
                            top: provided.draggableProps.style.offsetTop,
                          }
                        }
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <div className={classes.item} data-testid="choice-values">
                              <div>{viewableValue(type, item)}</div>
                              <FontIcon
                                icon="fas-minus"
                                onClick={() => this.removeItem(item)}
                                className={classes.icon}
                                data-testid="choice-delete"
                              />
                            </div>
                          </div>
                        )
                      }}
                    </Draggable>
                  ))}
                  {/* keep droppable container height on dragging draggable element */}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    )
  }
}
