var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useMemo, useState, useEffect } from 'react';
import { map, invert, uniq } from 'lodash';
import { getServerConfig } from 'app/core/utils/getServerConfig';
import { confirmDelete, openModal } from 'app/components/Modal';
import { CellText, CellDuration, CellSelect } from 'app/components/Table';
import { ModalAddCustomActivity } from './ModalAddCustomActivity';
import { ModalAddAbsenceActivity } from './ModalAddAbsenceActivity';
import moment from 'moment';
import { fetchMyActivities } from 'app/store/reducers/usersWithActivities';
import { useDispatch, useSelector } from 'react-redux';
import { TableRedux } from 'app/components/Table/TableRedux';
const tableId = 'my-hours-custom-activities';
export function TableMyHoursCustomActivities(props) {
    const { user, projects, onChange, getInstance, selectedWeek, formatView } = props;
    const dispatch = useDispatch();
    const [urlStartDate, setUrlStartDate] = useState('');
    const [urlEndDate, setUrlEndDate] = useState('');
    const { userData: activities, count } = useSelector((state) => state.allUsersWithActivities);
    const [paginatedList, setPaginatedList] = useState();
    useEffect(() => {
        const startDate = moment(selectedWeek).startOf(formatView);
        const endDate = moment(selectedWeek).endOf(formatView);
        setUrlStartDate(startDate.format('YYYY-MM-DD'));
        setUrlEndDate(endDate.format('YYYY-MM-DD'));
    }, [formatView, selectedWeek]);
    useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            if (selectedWeek) {
                const startDate = moment(selectedWeek).startOf(formatView).format('YYYY-MM-DD');
                const endDate = moment(selectedWeek).endOf(formatView).format('YYYY-MM-DD');
                dispatch(fetchMyActivities({ date__range: `${startDate},${endDate}`, task__isnull: true }));
            }
        }))();
    }, [selectedWeek]);
    const deleteActivitiesAction = (instance, cell) => ({
        label: 'Delete activity',
        editAction: true,
        onClick: (event) => {
            const { selectedCells } = instance.getLastestSelection();
            const toDelete = uniq(map(selectedCells, (cell) => cell.row.original.id));
            return confirmDelete({
                render: `Are you sur you want to delete ${toDelete.length > 1 ? 'these' : 'this'} activitie${toDelete.length > 1 ? 's' : ''} ?`,
                onValidate: () => {
                    var _a;
                    return (_a = cell
                        .getCellProps()) === null || _a === void 0 ? void 0 : _a.edition.save(toDelete).then((res) => {
                        instance.updateCells(selectedCells);
                    });
                },
                validateMessage: 'Activity deleted',
            });
        },
    });
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                Header: ' ',
                columns: [
                    CellText({
                        id: 'date',
                        Header: 'Date',
                        actions: (instance, cell) => [deleteActivitiesAction(instance, cell)],
                        inputProps: { type: 'date' },
                        readOnly: false,
                        sortingKey: 'date',
                        accessor: 'date',
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                if (Array.isArray(value))
                                    return value;
                                return null;
                            },
                        },
                    }),
                    CellDuration({
                        id: 'duration',
                        Header: 'Duration',
                        accessor: 'duration',
                        sortingKey: 'duration',
                        actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                        readOnly: false,
                        durationProps: {
                            hide: { days: true },
                        },
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                if (Array.isArray(value))
                                    return value;
                                return {
                                    id: item.id,
                                    duration: value,
                                };
                            },
                        },
                    }),
                    CellText({
                        id: 'comment',
                        Header: 'Activity name',
                        accessor: 'comment',
                        actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                        readOnly: false,
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                if (Array.isArray(value))
                                    return value;
                                return {
                                    id: item.id,
                                    comment: value,
                                };
                            },
                        },
                    }),
                    CellSelect({
                        id: 'activityType',
                        Header: 'Activity type',
                        actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                        sortingKey: 'activityType',
                        readOnly: false,
                        accessor: (item) => {
                            if (!item || !item.activityType)
                                return null;
                            return getServerConfig().ACTIVITY_TYPES[item.activityType];
                        },
                        options: () => map(getServerConfig().ACTIVITY_TYPES, (label) => ({
                            label,
                            value: label,
                        })),
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                if (Array.isArray(value))
                                    return value;
                                return {
                                    id: item.id,
                                    activityType: invert(getServerConfig().ACTIVITY_TYPES)[value],
                                };
                            },
                        },
                    }),
                    CellSelect({
                        id: 'project',
                        Header: 'On project',
                        actions: (instance, cell) => ['edit', deleteActivitiesAction(instance, cell)],
                        sortingKey: 'project',
                        readOnly: false,
                        options: () => [{ label: '-', value: null }].concat(projects.map((project) => ({ label: project === null || project === void 0 ? void 0 : project.name, value: project === null || project === void 0 ? void 0 : project.id }))),
                        accessor: 'project',
                        save: {
                            resource: 'activities',
                            formatData: (item, value, cell, instance, type) => {
                                if (Array.isArray(value))
                                    return value;
                                return {
                                    id: item.id,
                                    project: value,
                                };
                            },
                        },
                    }),
                ],
            },
        ];
    }, []);
    const ToggleButtons = (instance) => [
        {
            key: 'custom',
            onClick: () => openModal(_jsx(ModalAddCustomActivity, { userId: user.asset, paginatedList: paginatedList || '', preSelectedDate: new Date(), onChange: (activities) => {
                    const { setStateData, data } = instance;
                    const newData = [...data, ...activities];
                    setStateData(newData.reduce((acc, activity) => {
                        acc[activity.id] = activity;
                        return acc;
                    }, {}));
                    onChange === null || onChange === void 0 ? void 0 : onChange(activities);
                } })),
            icon: 'fas-calendar-plus',
            label: 'Add a custom activity',
            options: [
                {
                    key: 'leave',
                    icon: 'fas-clock',
                    onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: user.asset, preSelectedDate: new Date(), onChange: (activities) => {
                            const { setStateData, data } = instance;
                            const newData = [...data, ...activities];
                            setStateData(newData.reduce((acc, activity) => {
                                acc[activity.id] = activity;
                                return acc;
                            }, {}));
                            onChange === null || onChange === void 0 ? void 0 : onChange(activities);
                        }, showDuration: true })),
                    label: 'Add a leave (unique day)',
                },
                {
                    key: 'leaveDays',
                    icon: 'fas-clock',
                    onClick: () => openModal(_jsx(ModalAddAbsenceActivity, { userId: user.asset, preSelectedDate: new Date(), onChange: (activities) => {
                            const { setStateData, data } = instance;
                            const newData = [...data, ...activities];
                            setStateData(newData.reduce((acc, activity) => {
                                acc[activity.id] = activity;
                                return acc;
                            }, {}));
                            onChange === null || onChange === void 0 ? void 0 : onChange(activities);
                        }, showDuration: true, period: true })),
                    label: 'Add a leave (several days)',
                },
            ],
        },
    ];
    return (_jsx(TableRedux, { tableId: tableId, columns: columns, data: activities, count: count, toggleButtons: ToggleButtons, onResourcesRequested: onChange, getInstance: getInstance }));
}
