import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
export const leavesSelector = (state) => state.leaves;
export const leavesDataSelector = createSelector(leavesSelector, (leaves) => leaves.data);
export const leavesByUserAndDateSelector = (userId, date) => createSelector(leavesSelector, (leaves) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return leaves.data.filter((leave) => moment(formattedDate).isBetween(moment.utc(leave.startDate).format('YYYY-MM-DD'), moment.utc(leave.endDate).format('YYYY-MM-DD'), 'day', '[]') && leave.user === userId);
});
export const leavesByUserAndDateRangeSelector = (userId, startDate, endDate) => createSelector(leavesSelector, (leaves) => {
    return leaves.data.filter((leave) => {
        const formattedDate = moment(leave.startDate).format('YYYY-MM-DD');
        return (moment(formattedDate).isBetween(moment.utc(startDate), moment.utc(endDate), 'month', '[]') &&
            leave.user === userId);
    });
});
