// @flow
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { sortBy, filter } from 'lodash'
import { MUIButton, Input } from 'app/components/Form'
import { getResources } from 'app/store/selectors/getResources'
import type { ID, Step, AssetTypes, DynamicApproval } from 'app/core/types'
import resources from 'app/store/resources'
import { Permission } from 'app/containers/Permissions'

import { useSelector } from 'react-redux'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { NODE_DIRECTORY, Node } from 'app/components/TreeDnD/Node'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import { getDynamicApprovalNode } from './getDynamicApprovalNode.jsx'
import { DisplayDynamicApproval } from './DisplayDynamicApprovals.jsx'
import classes from './DynamicApprovalsEditor.module.scss'
import { NewDynamicApprovalModal } from './NewDynamicApprovalsModal.jsx'

type DynamicApprovalsEditorProps = {|
  displayInfos: boolean,
  assetType: ?AssetTypes,
  style?: Object,
  steps: Array<Step>,
  trackingSchemaId: ?ID,
|}

export function DynamicApprovalsEditor(props: DynamicApprovalsEditorProps): React$Node {
  const { displayInfos, style, assetType, steps, trackingSchemaId } = props

  const [selectedAttrID, setSelectedAttrID] = useState<string | void>()
  const [search, setSearch] = useState<string>('')

  const projectID = useMemo(() => getProjectIdFromURL(), [])

  const dynamicApprovals = useSelector((state) =>
    getResources(state, 'dynamicApprovals', { project: projectID, assetType: assetType || undefined }, undefined),
  )

  useEffect(() => {
    if (projectID) {
      resources.dynamicApprovals.fetchByProject(projectID)
    }
  }, [])

  async function onEdit(dynamicApproval) {
    const response = await Promise.all([
      resources.dynamicApprovals.update(dynamicApproval),
      resources.trackingSchemas.updateDynamicApprovalByID({
        id: dynamicApproval.id,
        projectID,
        dynamicApproval,
      }),
    ])
    return response
  }

  async function onCreate(dynamicApproval) {
    const { resources: res } = await resources.dynamicApprovals.create({
      ...dynamicApproval,
      project: projectID,
    })
    setSelectedAttrID(res?.[0]?.id)
    return res
  }

  async function onDelete(selectedAttrID: ID) {
    const res = await Promise.all([
      resources.trackingSchemas.deleteItemByID({ id: selectedAttrID, projectID }),
      resources.dynamicApprovals.delete(selectedAttrID),
    ])
    return res
  }

  function onSearchDynamicApproval(event) {
    setSearch(event.target.value)
    setSelectedAttrID()
  }

  const filteredDynamicApprovals = useMemo(
    () =>
      sortBy(
        filter(dynamicApprovals, (attr: DynamicApproval): boolean =>
          attr.name.toLowerCase().includes(search.toLowerCase()),
        ),
        ['name'],
      ),
    [dynamicApprovals, search],
  )

  const rootNode = useMemo(() => {
    const rootNode = new Node({
      key: 'dynamicApprovals-root',
      name: 'Dynamic approvals',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: filteredDynamicApprovals.map((dynamicApproval, index) =>
        getDynamicApprovalNode({
          dynamicApproval,
          onDelete,
          onSelect: setSelectedAttrID,
          parentNode: rootNode,
        }),
      ),
    })

    return rootNode
  }, [filteredDynamicApprovals])

  return (
    <div className={classes.container} style={style}>
      <div className={classes.toolbar}>
        <div>
          <Input
            placeholder="Search a dynamic approval"
            value={search}
            onChange={onSearchDynamicApproval}
            style={{ width: 300 }}
            dataCy="dynApprovals-search"
          />
        </div>
        <Permission actions={['projet_follow-up_dynamic approval management_create']}>
          <NewDynamicApprovalModal
            title="New dynamic approval"
            onSave={onCreate}
            dynamicApproval={undefined}
            assetType={assetType}
            steps={steps}
          >
            <MUIButton disabled={!trackingSchemaId} data-testid="dynapp-editor-create-button">
              Create a dynamic approval
            </MUIButton>
          </NewDynamicApprovalModal>
        </Permission>
      </div>
      <div className={classes.subContainer}>
        <div className={classes.scrollBlock}>
          {!assetType ? (
            <div className={classes.noDynamicApprovalItem}>Please select a table</div>
          ) : !filteredDynamicApprovals.length ? (
            <div className={classes.noDynamicApprovalItem}>No dynamic approvals</div>
          ) : (
            <TreeDnD rootNode={rootNode} />
          )}
        </div>
        {displayInfos && selectedAttrID ? (
          <DisplayDynamicApproval
            attrID={selectedAttrID}
            steps={steps}
            Modal={({ children, dynamicApproval }) => (
              <NewDynamicApprovalModal
                title={`Edit dynamic approval ${dynamicApproval.name}`}
                onSave={onEdit}
                dynamicApproval={dynamicApproval}
                steps={steps}
              >
                {children}
              </NewDynamicApprovalModal>
            )}
          />
        ) : null}
      </div>
    </div>
  )
}
