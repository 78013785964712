var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    fetchLoading: false,
    data: [],
    count: 0,
    error: null,
};
export const fetchAllUserLeaves = createAsyncThunk('leaves/users/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.users.myLeaves({}, { page_size: 1000 });
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createLeave = createAsyncThunk('leaves/create', (newLeave_1, _a) => __awaiter(void 0, [newLeave_1, _a], void 0, function* (newLeave, { fulfillWithValue, rejectWithValue }) {
    try {
        const data = yield api.leaves.create(newLeave);
        return fulfillWithValue(data);
    }
    catch (err) {
        rejectWithValue(err);
    }
}));
export const updateLeave = createAsyncThunk('leaves/update', (leave_1, _a) => __awaiter(void 0, [leave_1, _a], void 0, function* (leave, { fulfillWithValue, rejectWithValue }) {
    try {
        const data = yield api.leaves.update(leave);
        return fulfillWithValue(data);
    }
    catch (err) {
        rejectWithValue(err);
    }
}));
export const deleteLeave = createAsyncThunk('leaves/delete', (leaveId_1, _a) => __awaiter(void 0, [leaveId_1, _a], void 0, function* (leaveId, { fulfillWithValue, rejectWithValue }) {
    try {
        if (!Array.isArray(leaveId)) {
            yield api.leaves.delete(leaveId);
        }
        else if (leaveId.length > 1) {
            const promises = leaveId.map((id) => {
                return api.leaves.delete(id);
            });
            yield Promise.all(promises);
        }
        else {
            yield api.leaves.delete(leaveId[0]);
        }
        return fulfillWithValue(leaveId);
    }
    catch (err) {
        return rejectWithValue(leaveId);
    }
}));
const { actions, reducer } = createSlice({
    name: 'leaves',
    initialState,
    reducers: {
        setLeaves: (state, { payload }) => {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL USER LEAVES
        builder.addCase(fetchAllUserLeaves.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllUserLeaves.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllUserLeaves.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // CREATE LEAVE
        builder.addCase(createLeave.pending, (state) => { });
        builder.addCase(createLeave.fulfilled, (state, { payload }) => {
            state.data = [...state.data, payload];
        });
        builder.addCase(createLeave.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // UPDATE LEAVE
        builder.addCase(updateLeave.pending, (state) => { });
        builder.addCase(updateLeave.fulfilled, (state, { payload }) => {
            const newData = state.data.map((leave) => {
                if (leave.id === payload.id)
                    return payload;
                return leave;
            });
            state.data = newData;
        });
        builder.addCase(updateLeave.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // DELETE LEAVE
        builder.addCase(deleteLeave.pending, (state) => { });
        builder.addCase(deleteLeave.fulfilled, (state, { payload }) => {
            if (Array.isArray(payload)) {
                let newData = [...state.data];
                payload.forEach((deletedLeaveId) => {
                    const leaveToDelete = newData.find((leave) => leave.id === deletedLeaveId);
                    newData = newData.filter((leave) => leave.id !== (leaveToDelete === null || leaveToDelete === void 0 ? void 0 : leaveToDelete.id));
                });
                state.data = [...newData, ...payload];
            }
            else {
                state.data = state.data.filter((leave) => leave.id !== payload);
            }
        });
        builder.addCase(deleteLeave.rejected, (state, { payload }) => {
            state.error = payload;
        });
    },
});
export const { setLeaves } = actions;
export default reducer;
