// @flow

export default {
  /**
   * CRUD
   */
  actions: {
    uri: 'actions',
    crud: true,
    collection: true,
  },
  actionPermissions: {
    uri: 'actionPermissions',
    crud: true,
    collection: true,
  },
  actionPermissionGroups: {
    uri: 'actionPermission-groups',
    crud: true,
    collection: true,
  },
  activities: {
    uri: 'activities',
    crud: true,
    collection: true,
  },
  activityApprovals: {
    uri: 'activityApprovals',
    crud: true,
    collection: true,
    subResources: {
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  approvalStatus: {
    uri: 'approvalStatus',
    crud: true,
    collection: true,
  },
  approvalStatusValues: {
    uri: 'approvalStatusValues',
    crud: true,
    collection: true,
  },
  approvalWorkflowsApprovalStatus: {
    uri: 'approvalWorkflow-approvalStatus',
    crud: true,
    collection: true,
  },
  approvalWorkflows: {
    uri: 'approvalWorkflows',
    crud: true,
    collection: true,
    subResources: {
      approvalStatus: {
        uri: '{id}/approvalStatus',
        method: 'GET',
      },
      graph: {
        uri: '{id}/graph',
        method: 'GET',
      },
    },
  },
  articles: {
    uri: 'articles',
    crud: true,
    collection: true,
    subResources: {
      children: {
        uri: '{id}/children',
        method: 'GET',
      },
      lastVersion: {
        uri: '{id}/lastVersion',
        method: 'GET',
      },
      versions: {
        uri: '{id}/versions',
        method: 'GET',
      },
    },
  },
  articleFlags: {
    uri: 'article-flags',
    crud: true,
    collection: true,
  },
  articleContents: {
    uri: 'articleContents',
    crud: true,
    collection: true,
  },
  articleMedias: {
    uri: 'article-medias',
    crud: true,
    collection: true,
  },
  assetFlags: {
    uri: 'asset-flags',
    crud: true,
    collection: true,
  },
  assetLinkTypes: {
    uri: 'assetLinkTypes',
    crud: true,
    collection: true,
  },
  assetMediaGroups: {
    uri: 'asset-mediaGroups',
    crud: true,
    collection: true,
  },
  assets: {
    uri: 'assets',
    crud: true,
    collection: true,
    subResources: {
      activity: {
        uri: '{id}/activity',
        method: 'GET',
      },
      allTakesValidationMedias: {
        uri: '{id}/allTakesValidationMedias',
        method: 'GET',
      },
      allTakesPinnedMedias: {
        uri: '{id}/allTakesPinnedMedias',
        method: 'GET',
      },
      articles: {
        uri: '{id}/articles',
        method: 'GET',
      },
      breakdown: {
        uri: '{id}/breakdown',
        method: 'GET',
      },
      children: {
        uri: '{id}/children',
        method: 'GET',
      },
      childrenWithLinks: {
        uri: '{id}/childrenWithLinks',
        method: 'GET',
      },
      childrenWithPostBoardLinks: {
        uri: '{id}/childrenWithPostBoardLinks',
        method: 'GET',
      },
      descendantWithTrackingDatas: {
        uri: '{id}/{trackingSchemaId}/descendantWithTrackingDatas',
        method: 'GET',
      },
      episodeUsage: {
        uri: '{id}/episodeUsage',
        method: 'GET',
      },
      flags: {
        uri: '{id}/flags',
        method: 'GET',
      },
      fullPath: {
        uri: '{id}/fullPath',
        method: 'GET',
      },
      links: {
        uri: '{id}/link',
        method: 'GET',
      },
      medias: {
        uri: '{id}/medias',
        method: 'GET',
      },
      postBoardLinks: {
        uri: '{id}/postBoardLinks',
        method: 'GET',
      },
      shotUsage: {
        uri: '{id}/{episodeId}/shotUsage',
        method: 'GET',
      },
      shortPath: {
        uri: '{id}/shortPath',
        method: 'GET',
      },
      tasks: {
        uri: '{id}/tasks',
        method: 'GET',
      },
      toc: {
        uri: '{id}/toc',
        method: 'GET',
      },
      topics: {
        uri: '{id}/topics',
        method: 'GET',
      },
      undelete: {
        uri: '{id}/undelete',
        method: 'GET',
      },
      usage: {
        uri: '{id}/usage',
        method: 'GET',
      },
      customAttributValues: {
        uri: 'customAttributValues',
        method: 'GET',
      },
      rootFolders: {
        uri: 'rootFolders',
        method: 'GET',
      },
    },
  },
  assetLinks: {
    uri: 'asset-links',
    crud: true,
    collection: true,
  },
  attributes: {
    uri: 'attributes',
    crud: true,
    collection: true,
  },
  categories: {
    uri: 'categories',
    crud: true,
    collection: true,
  },
  calculatedFields: {
    uri: 'calculatedFields',
    crud: true,
    collection: true,
    subResources: {
      blocks: {
        uri: 'blocks',
        method: 'GET',
      },
    },
  },

  collectiveAgreementTitles: {
    uri: 'collectiveAgreementTitles',
    crud: true,
    collection: true,
  },
  contractAttributes: {
    uri: 'contractAttributes',
    crud: true,
    collection: true,
  },
  contracts: {
    uri: 'contracts',
    crud: true,
    collection: true,
  },
  contractSecureDatas: {
    uri: 'contractSecureDatas',
    crud: true,
    collection: true,
  },
  jobCollectiveAgreementTitles: {
    uri: 'jobCollectiveAgreementTitles',
    crud: true,
    collection: true,
  },
  departments: {
    uri: 'departments',
    crud: true,
    collection: true,
  },
  dynamicApprovals: {
    uri: 'dynamicApprovals',
    crud: true,
    collection: true,
  },
  dynamicApprovalValues: {
    uri: 'dynamicApprovalValues',
    crud: true,
    collection: true,
    subResources: {
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  episodes: {
    uri: 'episodes',
    crud: true,
    subResources: {
      sequenceBreakdown: {
        uri: '{id}/sequenceBreakdown',
        method: 'GET',
      },
      shotBreakdown: {
        uri: '{id}/shotBreakdown',
        method: 'GET',
      },
    },
  },
  externalJobs: {
    uri: 'externalJobs',
    crud: true,
    collection: true,
  },
  fastScheduler: {
    uri: 'fastScheduler',
    crud: true,
    subResources: {
      run: {
        uri: 'run',
        method: 'POST',
      },
      dichotomy: {
        uri: 'dichotomy',
        method: 'POST',
      },
    },
  },
  files: {
    uri: 'files',
    crud: true,
    collection: true,
  },
  flags: {
    uri: 'flags',
    crud: true,
    collection: true,
  },
  groupCategoryPermissions: {
    uri: 'groupCategoryPermissions',
    crud: true,
    collection: true,
  },
  groups: {
    uri: 'groups',
    crud: true,
    subResources: {
      actionPermissions: {
        uri: '{id}/actionPermissions',
        method: 'GET',
      },
      allUsersWithActivities: {
        uri: 'allUsersWithActivities',
        method: 'GET',
      },
      users: {
        uri: '{id}/users',
        method: 'GET',
      },
      usersWithActivities: {
        uri: '{id}/usersWithActivities',
        method: 'GET',
      },
    },
  },
  groupUsers: {
    uri: 'groupUsers',
    crud: true,
  },
  groupPermissions: {
    uri: 'groupPermissions',
    crud: true,
    collection: true,
  },
  imputations: {
    uri: 'imputations',
    crud: true,
    collection: true,
  },
  jobs: {
    uri: 'jobs',
    crud: true,
    collection: true,
  },
  jobSkillLevels: {
    uri: 'jobSkillLevels',
    crud: true,
    collection: true,
  },
  leaves: {
    uri: 'leaves',
    crud: true,
    collection: true,
  },
  mediaGroups: {
    uri: 'mediaGroups',
    crud: true,
    collection: true,
  },
  medias: {
    uri: 'medias',
    crud: true,
    collection: true,
  },
  messages: {
    uri: 'messages',
    crud: true,
    collection: true,
  },
  ovmTokens: {
    uri: 'ovmTokens',
    crud: true,
    collection: true,
  },
  people: {
    uri: 'people',
    crud: true,
    collection: true,
    subResources: {
      personWithSchemaDatas: {
        uri: '{peopleSchemaId}/personWithSchemaDatas',
        method: 'GET',
      },
    },
  },
  personAttributes: {
    uri: 'personAttributes',
    crud: true,
    collection: true,
  },
  personAttributeValues: {
    uri: 'personAttributeValues',
    crud: true,
    collection: true,
  },
  peopleSchemas: {
    uri: 'personSchemas',
    crud: true,
    collection: true,
  },
  postBoardLinks: {
    uri: 'postBoardLinks',
    crud: true,
    collection: true,
    subResources: {
      notes: {
        uri: '{id}/notes',
        method: 'GET',
      },
    },
  },
  postBoardNotes: {
    uri: 'postBoardNotes',
    crud: true,
    collection: true,
    subResources: {
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  posts: {
    uri: 'posts',
    crud: true,
    collection: true,
  },
  presetFilters: {
    uri: 'filters',
    crud: true,
    collection: true,
  },
  privateDatas: {
    uri: 'privateDatas',
    crud: true,
  },
  projects: {
    uri: 'projects',
    crud: true,
    collection: true,
    subResources: {
      attributes: {
        uri: '{id}/availableAttributes',
        method: 'GET',
      },
      dynamicApprovals: {
        uri: '{id}/dynamicApprovals',
        method: 'GET',
      },
      episodes: {
        uri: '{id}/episodes',
        method: 'GET',
      },
      externalTrackingSchemas: {
        uri: '{id}/externalTrackingSchemas',
        method: 'GET',
      },
      groups: {
        uri: '{id}/groups',
        method: 'GET',
      },
      steps: {
        uri: '{id}/steps',
        method: 'GET',
      },
      tasks: {
        uri: '{id}/tasks',
        method: 'GET',
      },
      trackingSchemas: {
        uri: '{id}/trackingSchemas',
        method: 'GET',
      },
      users: {
        uri: '{id}/users',
        method: 'GET',
      },
    },
  },
  projectDepartments: {
    uri: 'projectDepartments',
    crud: true,
    collection: true,
  },
  projectDepartmentJobs: {
    uri: 'projectDepartmentJobs',
    crud: true,
    collection: true,
    subResources: {
      imputations: {
        uri: 'imputations',
        method: 'GET',
      },
    },
  },
  projectPlans: {
    uri: 'projectPlans',
    crud: true,
    collection: true,
  },
  progressionStatus: {
    uri: 'progressionStatus',
    crud: true,
    collection: true,
  },
  steps: {
    uri: 'steps',
    crud: true,
    collection: true,
  },
  stepPaths: {
    uri: 'stepPaths',
    crud: true,
    collection: true,
  },
  stepProjects: {
    uri: 'stepProjects',
    crud: true,
    collection: true,
  },
  stepWorkflows: {
    uri: 'stepWorkflows',
    crud: true,
    collection: true,
  },
  stepsWorkflowsAssociations: {
    uri: 'stepsWorkflowsAssociations',
    crud: true,
    collection: true,
  },
  stepGraphs: {
    uri: 'stepGraphs',
    crud: true,
    collection: true,
  },
  tasks: {
    uri: 'tasks',
    crud: true,
    collection: true,
    subResources: {
      activities: {
        uri: '{id}/activities',
        method: 'GET',
      },
      children: {
        uri: '{id}/children',
        method: 'GET',
      },
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
      currentApproval: {
        uri: '{id}/currentApproval',
        method: 'GET',
      },
      takes: {
        uri: '{id}/takes',
        method: 'GET',
      },
    },
  },
  taskViewers: {
    uri: 'task-viewers',
    crud: true,
    collection: true,
  },
  taskTypes: {
    uri: 'taskTypes',
    crud: true,
  },
  takes: {
    uri: 'takes',
    crud: true,
    collection: true,
    subResources: {
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  takeFlags: {
    uri: 'take-flags',
    crud: true,
    collection: true,
  },
  takeRefMedias: {
    uri: 'take-refMedias',
    crud: true,
    collection: true,
  },
  takeValidationMedias: {
    uri: 'take-validationMedias',
    crud: true,
    collection: true,
  },
  teamEpisodes: {
    uri: 'teamEpisodes',
    crud: true,
    collection: true,
    subResources: {
      dashboard: {
        uri: '{id}/dashboard',
        method: 'GET',
      },
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  teams: {
    uri: 'teams',
    crud: true,
    collection: true,
    subResources: {
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  threads: {
    uri: 'threads',
    crud: true,
    collection: true,
    subResources: {
      posts: {
        uri: '{id}/posts',
        method: 'GET',
      },
      lastReadedPost: {
        uri: '{id}/lastReadedPost',
        method: 'POST',
      },
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  topics: {
    uri: 'topics',
    crud: true,
    collection: true,
    subResources: {
      threads: {
        uri: '{id}/threads',
        method: 'GET',
      },
      history: {
        uri: '{id}/history',
        method: 'GET',
      },
    },
  },
  trackingSchemaAccesses: {
    uri: 'trackingSchemaAccesses',
    crud: true,
    collection: true,
  },
  trackingSchemas: {
    uri: 'trackingSchemas',
    crud: true,
    collection: true,
    subResources: {
      externals: {
        uri: 'externals',
        method: 'GET',
      },
    },
  },
  trombinoscope: {
    uri: 'trombinoscope',
    crud: true,
    collection: true,
    subResources: {
      layout: {
        uri: 'layout',
        method: 'GET',
      },
      personDatas: {
        uri: '{id}/personDatas',
        method: 'GET',
      },
    },
  },
  users: {
    uri: 'users',
    crud: true,
    subResources: {
      projects: {
        uri: '{id}/projects',
        method: 'GET',
      },
      notifications: {
        uri: '{id}/notifications',
        method: 'GET',
      },
      feeds: {
        uri: '{id}/feeds',
        method: 'GET',
      },
      activities: {
        uri: '{id}/activities',
        method: 'GET',
      },
      myActivities: {
        uri: 'myActivities',
        method: 'GET',
      },
      leaves: {
        uri: '{id}/leaves',
        method: 'GET',
      },
      myLeaves: {
        uri: 'myLeaves',
        method: 'GET',
      },
      myTasks: {
        uri: 'myTasks',
        method: 'GET',
      },
      myPastTasks: {
        uri: 'myPastTasks',
        method: 'GET',
      },
      settings: {
        uri: '{id}/settings',
        method: 'GET',
      },
    },
  },
  tableSettings: {
    uri: 'tableSettings',
    crud: true,
    collection: true,
  },
  notifications: {
    uri: 'notifications',
    crud: true,
    collection: true,
  },
  subscriptions: {
    uri: 'subscriptions',
    crud: true,
    collection: true,
  },

  /**
   * actions
   */
  autocomplete: {
    uri: 'autocomplete',
    method: 'GET',
  },
  searchAndReplaceBreakdown: {
    uri: 'searchAndReplaceBreakdown',
    method: 'POST',
  },
  searchAndDeleteBreakdown: {
    uri: 'searchAndDeleteBreakdown',
    method: 'POST',
  },
  searchAndAddBreakdown: {
    uri: 'searchAndAddBreakdown',
    method: 'POST',
  },
  search: {
    uri: 'search',
    method: 'POST',
  },
  changeEpisodicAssetParent: {
    uri: 'changeEpisodicAssetParent',
    method: 'POST',
  },
  changePassword: {
    uri: 'auth/password/change',
    method: 'POST',
  },
  checkSubscription: {
    uri: 'checkSubscription',
    method: 'POST',
  },
  config: {
    uri: 'config',
    method: 'GET',
  },
  confirmPassword: {
    uri: 'auth/password/reset/confirm',
    method: 'POST',
  },
  dashboards: {
    uri: 'dashboards',
    method: 'POST',
  },
  dynamicApprovalStatusEvolution: {
    uri: 'dynamicApprovalStatusEvolution',
    method: 'POST',
  },
  endPoints: {
    uri: 'endpoints',
    method: 'GET',
  },
  login: {
    uri: 'auth/login',
    method: 'POST',
  },
  logout: {
    uri: 'auth/logout',
    method: 'POST',
  },
  publicMessages: {
    uri: 'publicMessages',
    method: 'GET',
  },
  pushTo: {
    uri: 'pushTo',
    method: 'POST',
  },
  pushToColumn: {
    uri: 'pushToColumn',
    method: 'POST',
  },
  registration: {
    uri: 'auth/registration',
    method: 'POST',
  },
  replacePlaceHolder: {
    uri: 'replacePlaceHolder',
    method: 'POST',
  },
  upload: {
    uri: 'upload',
    method: 'FILE',
  },
  uploadb64: {
    uri: 'upload-b64',
    method: 'POST',
  },
  user: {
    uri: 'auth/user',
    method: 'GET',
  },
  sendEventToSentry: {
    uri: 'sendEventToSentry',
    method: 'POST',
  },
}
