var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    fetchLoading: false,
    updateLoading: false,
    data: [],
    // store data for a selected user
    userData: [],
    error: null,
    count: 0,
};
export const fetchAllActivities = createAsyncThunk('reduxActivities/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.activities.fetchAll();
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const fetchUserActivities = createAsyncThunk('reduxActivities/users/fetchAll', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ userId, queries }, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.users.activities({ id: userId }, queries);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const createActivity = createAsyncThunk('reduxActivities/create', (createdActivity_1, _a) => __awaiter(void 0, [createdActivity_1, _a], void 0, function* (createdActivity, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.activities.create(createdActivity);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const updateActivity = createAsyncThunk('reduxActivities/update', (updatedActivity_1, _a) => __awaiter(void 0, [updatedActivity_1, _a], void 0, function* (updatedActivity, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.activities.update(updatedActivity);
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
export const deleteActivity = createAsyncThunk('reduxActivities/delete', (deletedActivityIds_1, _a) => __awaiter(void 0, [deletedActivityIds_1, _a], void 0, function* (deletedActivityIds, { fulfillWithValue, rejectWithValue }) {
    try {
        const promises = deletedActivityIds.map((id) => {
            return api.activities.delete(id);
        });
        yield Promise.all(promises);
        return fulfillWithValue(deletedActivityIds);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { actions, reducer } = createSlice({
    name: 'reduxActivities',
    initialState,
    reducers: {
        setActivities: (state, { payload }) => {
            state.data = payload;
        },
        cleanUserActivities: (state) => {
            state.userData = [];
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL ACTIVITIES
        builder.addCase(fetchAllActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchAllActivities.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.fetchLoading = false;
        });
        builder.addCase(fetchAllActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // FETCH USER ACTIVITIES
        builder.addCase(fetchUserActivities.pending, (state) => {
            state.fetchLoading = true;
        });
        builder.addCase(fetchUserActivities.fulfilled, (state, { payload }) => {
            state.userData = payload.results;
            state.fetchLoading = false;
            state.count = payload.count;
        });
        builder.addCase(fetchUserActivities.rejected, (state, { payload }) => {
            state.error = payload;
            state.fetchLoading = false;
        });
        // CREATE ACTIVITY
        builder.addCase(createActivity.pending, (state) => { });
        builder.addCase(createActivity.fulfilled, (state, { payload }) => {
            state.data = [...state.data, payload];
            state.userData = [...state.userData, payload];
        });
        builder.addCase(createActivity.rejected, (state, { payload }) => {
            state.error = payload;
        });
        // UPDATE ACTIVITY
        builder.addCase(updateActivity.pending, (state) => {
            state.updateLoading = true;
        });
        builder.addCase(updateActivity.fulfilled, (state, { payload }) => {
            const newData = [...state.data].map((activity) => {
                if (activity.id === payload.id)
                    return payload;
                return activity;
            });
            const newUserData = [...state.userData].map((activity) => {
                if (activity.id === payload.id)
                    return payload;
                return activity;
            });
            state.data = newData;
            state.userData = newUserData;
            state.updateLoading = false;
        });
        builder.addCase(updateActivity.rejected, (state, { payload }) => {
            state.error = payload;
            state.updateLoading = false;
        });
        // DELETE ACTIVITY
        builder.addCase(deleteActivity.pending, (state) => { });
        builder.addCase(deleteActivity.fulfilled, (state, { payload }) => {
            if (Array.isArray(payload)) {
                let newData = [...state.data];
                let newUserData = [...state.userData];
                payload.forEach((deletedActivityId) => {
                    const activityToDelete = newData.find((aa) => aa.id === deletedActivityId);
                    newData = newData.filter((activity) => activity.id !== (activityToDelete === null || activityToDelete === void 0 ? void 0 : activityToDelete.id));
                    newUserData = newUserData.filter((activity) => activity.id !== (activityToDelete === null || activityToDelete === void 0 ? void 0 : activityToDelete.id));
                });
                state.data = newData;
                state.userData = newUserData;
            }
            else {
                state.data = state.data.filter((activity) => activity.id !== payload);
                state.userData = state.userData.filter((activity) => activity.id !== payload);
            }
        });
        builder.addCase(deleteActivity.rejected, (state, { payload }) => {
            state.error = payload;
        });
    },
});
export const { setActivities, cleanUserActivities } = actions;
export default reducer;
