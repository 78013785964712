// @flow
import React from 'react'
import { isNaN, filter, uniq, sortBy, map } from 'lodash'
import cx from 'classnames'
import type { Pipe, Option, ResourcesList, StepProject, Step } from 'app/core/types'
import { MUIButton, ButtonList, MUISelect, ColorPickerPopper } from 'app/components/Form'
import { Toggle } from 'app/components/Form/indexTS.ts'
import pipe from 'app/core/utils/pipe'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import resources from 'app/store/resources'
import Widget from 'app/components/Widget/Widget.jsx'
import { getResources } from 'app/store/selectors/getResources'
import { ModulableTree } from 'app/components/ModulableTree/ModulableTree.jsx'
import modulableTreeClasses from 'app/components/ModulableTree/styles/labelFullWidth.module.scss'
import { ModalTrigger, confirm } from 'app/components/Modal'
import { assetIcons } from 'app/components/Icons/assetsIcons.js'
import { assetsTypes } from 'app/core/constants/index.js'
import { permission, Permission } from 'app/containers/Permissions/index.js'
import { ObjectValues } from 'app/libs/flowPolyfills'
import { tableId as postboardModelID } from 'app/pages/Project/PostBoardModel/TablePostBoardModels.tsx'
import { tableId as postboardShotID } from 'app/pages/Project/PostBoardShot/TablePostBoardShots.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'

import ModalAddSteps from './ModalAddSteps.jsx'
import classes from './Steps.module.scss'
import { error } from '../../../../components/Notifications/notify.js'

const stepsTables = [
  { label: 'Tables Postboard Shots', value: postboardShotID },
  { label: 'Tables Postboard Models', value: postboardModelID },
]

type Props = {|
  stepProjects: ResourcesList<StepProject>,
|}

type State = {
  enableUpdateRank: boolean,
}

// Called after drag and drop, it filters on the intermediate items and update their rank
export const getUpdatedStepProjects = (resources: Object, from: number, to: number): Array<Object> => {
  const min = Math.min(from, to)
  const max = Math.max(from, to)

  return filter(resources, ({ rank }) => rank >= min && rank <= max).map(({ rank, id }) => {
    if (rank === from) return { id, rank: to }
    if (from < to) return { id, rank: rank - 1 }
    if (from > to) return { id, rank: rank + 1 }

    throw new Error('getUpdatedStepProjects error')
  })
}

// Called after delete, it filters on the items with a rank higher than the deleted one
export const getUpdatedStepProjectsAfterDelete = (resources: Object, deletedRank: number): Array<Object> => {
  return filter(resources, (resource) => resource.rank > deletedRank).map(({ rank, id }) => {
    return {
      id,
      rank: rank - 1,
    }
  })
}

// comment test for CI build
class StepsComponent extends React.Component<Props, State> {
  state = {
    enableUpdateRank: false,
  }

  removeStepProject(stepProject) {
    const { stepProjects } = this.props
    confirm({
      title: 'Delete',
      titleColor: '#E56D7A',
      render: (
        <div>
          Delete <b>{stepProject.stepInst.name}</b> step from project ?
        </div>
      ),
      onValidate: () => {
        const rankToDelete = stepProject.rank
        return resources.stepProjects.delete(stepProject.id).then(() =>
          resources.stepProjects.update(getUpdatedStepProjectsAfterDelete(stepProjects, rankToDelete), {
            batch: true,
          }),
        )
      },
      validateLabel: 'Delete',
      validateLabelColor: '#E56D7A',
    })
  }

  renderAddSteps = () => {
    const { enableUpdateRank } = this.state
    return (
      <ButtonList>
        <Permission actions={['projet_steps__add to project']}>
          <ModalTrigger modal={<ModalAddSteps />}>
            <MUIButton icon="fas-plus">Add steps</MUIButton>
          </ModalTrigger>
        </Permission>
        <Permission actions={['projet_steps__update rank']}>
          <Toggle
            value={enableUpdateRank}
            dataCy="steps"
            option1={{
              label: 'Edit',
              value: true,
            }}
            option2={{
              label: 'Locked',
              value: false,
              disabled: true,
            }}
            onChange={(enableUpdateRank) => this.setState({ enableUpdateRank })}
          />
        </Permission>
      </ButtonList>
    )
  }

  onDrop = (content, data, index) => {
    const { index: dragIndex } = content

    if (isNaN(dragIndex)) return Promise.resolve()

    if (Number(dragIndex) >= Number(index) && index !== 0) {
      index += 1
    }

    const itemToMove = data[dragIndex]
    data.splice(dragIndex, 1)
    data.splice(index > 0 ? index : 0, 0, itemToMove)

    return resources.stepProjects.update(
      data.map(({ stepInst, ...stepProject }, index) => ({ ...stepProject, rank: data.length - index })),
      { batch: true, updateBeforeRequest: true },
    )
  }

  testIfLoopExist(stepProjects: ResourcesList<StepProject>) {
    const links = map(stepProjects, (sp) => [sp.stepInst.id, sp.stepInst.metaStepInst?.id])

    function recursive(metaStepID, count = 0) {
      const step = links.find((s) => s[0] === metaStepID)
      if (step && count < links.length) return recursive(step[1], count + 1)
      return count >= links.length
    }

    for (const [step, metaStep] of links) {
      if (step === metaStep) return true
      if (recursive(metaStep)) return true
    }

    return false
  }

  onChangeColor = (color?: string, step: Step) => {
    return resources.steps.update({ id: step.id, color })
  }

  stepsProject() {
    const { stepProjects } = this.props
    const { enableUpdateRank } = this.state

    const data = ObjectValues(stepProjects)
      .sort((a, b) => (a.rank > b.rank ? 1 : -1))
      .reverse()
    const items = data.map((stepProject, index) => {
      const { stepInst } = stepProject

      const selectValue = stepProject.display
        ? stepProject.display.map((value) => stepsTables.find((opt) => opt.value === value)?.value).filter((_) => _)
        : []

      const originalDisplayValue = uniq(
        stepProject.display.filter((val) => !stepsTables.map((opt) => opt.value).includes(val)),
      )

      return {
        label: (
          <div className={classes.item}>
            <div className={cx(classes.itemCell, classes.first)} style={{ position: 'relative' }}>
              {enableUpdateRank && (
                <div style={{ position: 'absolute', left: 15, color: 'grey' }}>
                  <FontIcon icon="fas-grip-lines" />
                </div>
              )}
              {stepInst.name}
            </div>

            <div className={classes.itemCell}>
              <FontIcon icon={assetIcons(stepInst.assetType)} className="paddingRight5" />
              {assetsTypes[stepInst.assetType]}
            </div>

            <div className={classes.itemCell} style={{ pointerEvents: 'none' }}>
              <div className="fullWidth padding5 flex center wrap" style={{ pointerEvents: 'all' }}>
                {enableUpdateRank && permission(['projet_steps__update authorized tables']) ? (
                  <MUISelect
                    value={stepProject.stepInst.metaStep}
                    fullWidth={true}
                    options={sortBy(
                      sortBy(stepProjects, ['name'])
                        .filter((_stepProject) => _stepProject.stepInst.id !== stepInst.id)
                        .map((_stepProject) => ({
                          label: _stepProject.stepInst.name,
                          value: _stepProject.stepInst.id,
                          data: _stepProject.stepInst,
                        }))
                        .concat([{ label: '-', value: null }]),
                      ['label'],
                    )}
                    onChange={(option: Option) => {
                      if (
                        this.testIfLoopExist({
                          ...stepProjects,
                          [stepProject.id]: {
                            ...stepProject,
                            stepInst: { ...stepProject.stepInst, metaStep: option.value, metaStepInst: option.data },
                          },
                        })
                      ) {
                        error('There is a loop in your steps/metaSteps mapping')
                        return Promise.resolve()
                      }

                      return resources.steps.update({
                        id: stepProject.step,
                        metaStep: option.value,
                      })
                    }}
                  />
                ) : (
                  <div className={classes.itemCell}>{stepProject.stepInst.metaStepInst?.name}</div>
                )}
              </div>
            </div>

            <div className={classes.itemCell} style={{ pointerEvents: 'none' }}>
              <div
                className="fullWidth padding5 flex center wrap"
                style={{ pointerEvents: 'all', minWidth: '300px', maxWidth: '500px' }}
              >
                {enableUpdateRank && permission(['projet_steps__update authorized tables']) ? (
                  <MUISelect
                    value={selectValue}
                    options={stepsTables}
                    fullWidth={true}
                    multiple={true}
                    onChange={(values: Array<Option>) => {
                      return resources.stepProjects.update({
                        id: stepProject.id,
                        display: originalDisplayValue.concat(values.map(({ value }) => value)),
                      })
                    }}
                  />
                ) : (
                  stepProject.display &&
                  stepProject.display.map((value) => {
                    const tableName = stepsTables.find((opt) => opt.value === value)
                    return (
                      tableName && (
                        <div key={tableName.label} className={classes.tableItem}>
                          {tableName.label}
                        </div>
                      )
                    )
                  })
                )}
              </div>
            </div>

            <div className={classes.itemCell} style={{ pointerEvents: 'none', maxWidth: 50 }}>
              <div className="fullWidth fullHeight" style={{ pointerEvents: 'all' }}>
                <div
                  key="color"
                  data-testid="color-picker-group-button"
                  className={cx(classes.iconContainer, {
                    [classes.hoverColor]: ['#ffffff', 'transparent'].includes(stepInst.color),
                  })}
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: stepInst.color,
                    color: getColorFromBackground(stepInst.color),
                  }}
                >
                  <ColorPickerPopper
                    onChange={(color) => this.onChangeColor(color, stepInst)}
                    color={stepInst.color}
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
              </div>
            </div>

            <div className={classes.itemCell}>{stepProject.rank}</div>
          </div>
        ),
        dragContent: enableUpdateRank && {
          data: stepProject,
          index,
        },
        actions: enableUpdateRank &&
          permission(['projet_steps__remove from project']) && [
            {
              key: 'delete',
              onClick: () => this.removeStepProject(stepProject),
            },
          ],
        drop: ({ content }) => this.onDrop(content, data, index),
        key: stepProject.id,
      }
    })

    return [
      {
        label: (
          <div className={classes.item}>
            <div className={cx(classes.itemCell, classes.first)} style={{ fontWeight: 'bold', fontSize: 15 }}>
              Step
            </div>
            <div className={classes.itemCell} style={{ fontWeight: 'bold', fontSize: 15 }}>
              Asset type
            </div>
            <div className={classes.itemCell} style={{ fontWeight: 'bold', fontSize: 15 }}>
              Meta step
            </div>
            <div className={classes.itemCell} style={{ fontWeight: 'bold', fontSize: 15 }}>
              Authorized tables
            </div>
            <div className={classes.itemCell} style={{ fontWeight: 'bold', fontSize: 15, maxWidth: 50 }}>
              Color
            </div>
            <div className={classes.itemCell} style={{ fontWeight: 'bold', fontSize: 15 }}>
              Rank
            </div>
          </div>
        ),
        actions: enableUpdateRank &&
          permission(['projet_steps__remove from project']) && [
            { label: <div key="title-empty-zone" style={{ width: '28px' }} /> },
          ],
        drop: ({ content }) => this.onDrop(content, data, 0),
        items,
      },
    ]
  }

  render(): React$Node {
    return (
      <Widget style={{ width: '100%' }}>
        <ModulableTree
          classes={modulableTreeClasses}
          title={<div className={classes.title}>Step ranking</div>}
          treeProps={{
            hideOrigin: true,
            noExpand: true,
            noLevel: true,
            noDragIcon: true,
          }}
          Header={this.renderAddSteps()}
          draggableContentDir={false}
          items={this.stepsProject()}
        />
      </Widget>
    )
  }
}

const pipeInst: Pipe<{}, typeof StepsComponent> = pipe()

export const Steps: React$ComponentType<any> = pipeInst
  .connect((state, props) => {
    const stepProjects = getResources(state, 'stepProjects', { project: state.project?.id }, ['stepInst'])
    return {
      stepProjects,
      projectId: (state.project && state.project.id) || '',
    }
  })
  .request((props) => {
    const { projectId } = props
    return Promise.all([
      resources.stepProjects.fetchByProject(projectId),
      resources.steps.fetchAll({ params: { queries: { page_size: 1000 } } }),
    ])
  })
  .render(({ projectId, ...props }) => <StepsComponent {...props} />)
