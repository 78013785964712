// @flow
import React, { useState, useEffect, useMemo } from 'react'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors'
import Loader from 'app/components/Loader/Loader.jsx'
import type { ResourcesList, Category, PersonAttribute } from 'app/core/types'
import { DisplayAttribute, NewAttributeModal } from 'app/pages/Project/Settings/FollowUp/AttributesEditor'
import Widget from 'app/components/Widget/Widget.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { MUIButton, Input } from 'app/components/Form'
import { confirmDelete, openModal } from 'app/components/Modal'
import { ModalEditCategory } from 'app/containers/Modals/Categories/ModalEditCategory.jsx'
import { permission } from 'app/containers/Permissions/index.js'
import { documentTitle } from 'app/components/DocumentTitle/DocumentTitle.jsx'
import { NODE_DIRECTORY, Node, NODE_ITEM } from 'app/components/TreeDnD/Node'
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { responseAssetsTypes } from 'app/core/constants/assetsTypes.js'
import ModalGroupsAuthorizations from './ModalGroupsAuthorizations/ModalGroupsAuthorizations.tsx'

import classes from './PersonAttributesEditor.module.scss'

type Props = {||}

export type CategoryNodeData = {|
  category: Category,
  contentType: 'category',
|}

export type PersonAttributeNodeData = {|
  personAttribute: PersonAttribute,
  contentType: 'personAttribute',
  category: Category,
|}

export function PersonAttributesEditor(props: Props): React$Node {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [attribute, setAttribute] = useState<void | PersonAttribute>()
  const [categories, setCategories] = useState<ResourcesList<Category> | void>()
  const [search, setSearch] = useState<string>('')
  const [updateStoreKey, _setUpdateStoreKey] = useState<number | void>()

  const sortedCategories = useMemo(() => sortBy(categories, ['rank']), [categories])

  function setUpdateStoreKey() {
    _setUpdateStoreKey(Date.now())
  }

  useEffect(() => {
    setCategories(getResources(undefined, 'categories', undefined, ['personAttributesInst']))
  }, [updateStoreKey])

  useEffect(() => {
    setIsLoading(true)
    const config = { params: { queries: { page_size: 1000 } } }
    resources.categories.fetchAll(config).then(() => {
      setUpdateStoreKey()
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    documentTitle('Person attributes', 'page')
  }, [])

  const canEdit = useMemo(() => permission(['human resources_settings_person attributes_edit']), [])
  const canEditGroupPermissions = useMemo(() => {
    return permission(['human resources_settings_person attributes_set group permissions'])
  }, [])

  async function onSaveAttribute(personAttribute: $Shape<PersonAttribute>): Promise<any> {
    const res = await resources.personAttributes[personAttribute.id ? 'update' : 'create'](personAttribute)
    if (personAttribute.category && !categories?.[personAttribute.category]?.personAttributesInst) return {}
    const newAttribute = res.resources[0]
    setAttribute(newAttribute)
    setUpdateStoreKey()
    return res
  }

  function onDeleteAttribute(personAttribute: PersonAttribute) {
    confirmDelete({
      render: (
        <>
          Are you sure you want to delete <span className="bold">{personAttribute.name}</span> ?
        </>
      ),
      onValidate: async () => {
        const res = await resources.personAttributes.delete(personAttribute.id)
        if (
          personAttribute.category &&
          !categories?.[personAttribute.category]?.personAttributesInst?.[personAttribute.id]
        ) {
          setUpdateStoreKey()
          setAttribute()
        }
        return res
      },
      validateMessage: 'Attribute deleted',
    })
  }

  async function onSaveCategory(category: $Shape<Category>) {
    const res = await resources.categories[category.id ? 'update' : 'create'](category)
    setUpdateStoreKey()
    return res
  }

  async function onDeleteCategory(category: $Shape<Category>, personAttributes: Array<PersonAttribute>) {
    await resources.personAttributes.delete(
      personAttributes.map((pa) => pa.id),
      { batch: true },
    )
    const categoriesRes = await resources.categories.delete(category.id)
    setUpdateStoreKey()
    return categoriesRes
  }

  async function getGroupsDatas(category) {
    await Promise.all([
      resources.groupCategoryPermissions.fetchAll({
        params: { queries: { category__uuid: category.id, page_size: 1000 } },
      }),
      resources.assets.fetchAll({
        params: { queries: { assetType: responseAssetsTypes.group, project__uuid__isnull: true, page_size: 1000 } },
      }),
    ])
  }

  async function onCheckboxChange(id, type, value) {
    await resources.groupCategoryPermissions.update({ id, [type]: value })
  }

  function onChangeAttributesOrder(tree) {
    const toUpdate: PersonAttribute[] = []

    tree.children.forEach((node) => {
      const { category } = node.getData<CategoryNodeData>()
      node.children.forEach((node) => {
        const { personAttribute } = node.getData<PersonAttributeNodeData>()
        if (personAttribute.category !== category.id) {
          toUpdate.push({ ...personAttribute, category: category.id })
        }
      })
    })

    if (toUpdate.length) {
      return resources.personAttributes.update(toUpdate)
    }
    return Promise.resolve()
  }

  async function onAddGroup(groupId, categoryId) {
    try {
      await resources.groupCategoryPermissions.create({ group: groupId, category: categoryId })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function onRemoveGroup(id) {
    try {
      await resources.groupCategoryPermissions.delete(id)
    } catch (error) {
      throw new Error(error)
    }
  }

  const rootNode = useMemo(() => {
    const rootNode = new Node<null>({
      key: 'root',
      name: 'root',
      parent: undefined,
      data: null,
      type: NODE_DIRECTORY,
      children: sortedCategories.map((category, index) => {
        const { personAttributesInst, name } = category

        const sortedAttributes = sortBy(personAttributesInst, ['name', 'displayName']).filter((attr) =>
          !search ? true : attr.name.toLowerCase().includes(search.toLowerCase()),
        )

        const persAttr: $Shape<PersonAttribute> = { category: category.id }

        const categoryNode = new Node<CategoryNodeData>({
          key: category.id,
          collapsed: true,
          disableDrag: true,
          name: (
            <div className="flex row wrap alignCenter">
              <div className="noUserSelect">
                {name} <span className="lightgrey">({sortedAttributes.length})</span>
              </div>
              <div className={classes.categoryActions}>
                {canEdit
                  ? [
                      <NewAttributeModal
                        key="newAttribute"
                        edit={false}
                        title="Create new attribute"
                        onSave={onSaveAttribute}
                        categories={sortedCategories}
                        attribute={persAttr}
                      >
                        <Tooltip title={`Add a ${category.name.toLowerCase()} attribute`}>
                          <FontIcon icon="fas-plus" className={classes.categoryIcon} />
                        </Tooltip>
                      </NewAttributeModal>,
                      <Tooltip key="editCategory" title={`Edit category ${category.name}`}>
                        <FontIcon
                          icon="fas-edit"
                          onClick={() => openModal(<ModalEditCategory category={category} onSave={onSaveCategory} />)}
                          className={classes.categoryIcon}
                        />
                      </Tooltip>,
                      <Tooltip key="removeCategory" title={`Remove category ${category.name}`}>
                        <FontIcon
                          icon="fas-trash"
                          onClick={() => {
                            confirmDelete({
                              render: (
                                <span>
                                  Are you sure you want to delete the category
                                  <span className="marginLeft5 marginRight5 bold">
                                    {category.name} and its {sortedAttributes.length}
                                  </span>
                                  attributes?
                                </span>
                              ),
                              onValidate: () => onDeleteCategory(category, sortedAttributes),
                              validateMessage: 'Category deleted',
                            })
                          }}
                          className={classes.categoryIcon}
                        />
                      </Tooltip>,
                    ]
                  : null}
                {canEditGroupPermissions ? (
                  <Tooltip title="Set authorized groups">
                    <FontIcon
                      icon="fas-users"
                      tooltip="Set authorized groups"
                      onClick={() =>
                        openModal(
                          <ModalGroupsAuthorizations
                            category={category}
                            getGroupsDatas={getGroupsDatas}
                            onAddGroup={onAddGroup}
                            onRemoveGroup={onRemoveGroup}
                            onCheckboxChange={onCheckboxChange}
                          />,
                        )
                      }
                      className={classes.categoryIcon}
                    />
                  </Tooltip>
                ) : null}
              </div>
            </div>
          ),
          type: NODE_DIRECTORY,
          data: { category, contentType: 'category' },
          parent: rootNode,
          children: map(sortedAttributes, (personAttribute, index) => {
            return new Node<PersonAttributeNodeData>({
              key: personAttribute.id,
              name: (
                <div className={classes.categoryContainer} key={name} onClick={() => setAttribute(personAttribute)}>
                  {personAttribute.name}
                </div>
              ),
              parent: categoryNode,
              type: NODE_ITEM,
              icon: 'fas-grip-vertical',
              data: { personAttribute, contentType: 'personAttribute', category },
            })
          }),
        })
        return categoryNode
      }),
    })

    return rootNode
  }, [sortedCategories, search])

  return isLoading ? (
    <div className="fullWidth fullHeight flex center alignCenter">
      <Loader />
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes.toolbar}>
        <Input
          dataCy="input-search"
          placeholder="Search a person attribute"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          adornmentIcon="fas-search"
          styleContainer={{ maxWidth: 250 }}
        />
        {canEdit ? (
          <MUIButton
            onClick={() => openModal(<ModalEditCategory onSave={onSaveCategory} />)}
            icon="fas-plus"
            tooltip="Add a category"
            style={{ marginLeft: 20 }}
          />
        ) : null}
      </div>
      <div className={classes.panels}>
        <div className={classes.leftPanel}>
          {sortedCategories.length ? <TreeDnD rootNode={rootNode} onChange={onChangeAttributesOrder} /> : null}
        </div>
        {attribute ? (
          <div className={classes.rightPanel}>
            <Widget style={{ height: 'auto', width: '100%', position: 'sticky', top: 0 }}>
              <DisplayAttribute
                attribute={attribute}
                EditButtons={
                  canEdit ? (
                    <div className={classes.buttonsContainer}>
                      <NewAttributeModal
                        title={attribute.id ? `Edit ${attribute.name}` : 'Create new attribute'}
                        onSave={onSaveAttribute}
                        attribute={attribute}
                        edit={true}
                        categories={sortedCategories}
                      >
                        <FontIcon
                          className={classes.iconButton}
                          data-testid={cyLabelFormater('attribut-item-edit', attribute?.name)}
                          icon="fas-edit"
                        />
                      </NewAttributeModal>
                      <FontIcon
                        className={classes.iconButton}
                        data-testid={cyLabelFormater('attribut-item-edit', attribute?.name)}
                        icon="fas-trash"
                        onClick={() => onDeleteAttribute(attribute)}
                      />
                    </div>
                  ) : undefined
                }
              />
            </Widget>
          </div>
        ) : null}
      </div>
    </div>
  )
}
