// @flow
import React from 'react'
import { MUIButton, Autocomplete } from 'app/components/Form'
import pipe from 'app/core/utils/pipe'
import type { Pipe, ID, TrackingSchema, Match, History, ResourcesList } from 'app/core/types'
import { Permission } from 'app/containers/Permissions'
import { getResources } from 'app/store/selectors/getResources'

import ModalTrigger from 'app/components/Modal/ModalTrigger.jsx'
import classes from './SettingsFollowUp.module.scss'
import { sortTrackingSchemasOptions } from '../../ProductionManagement/FollowUp/utils'
import { ModalEditTrackingSchemaTable } from './ModalEditTrackingSchemaTable.jsx'

type Props = {|
  projectId: ID | null,
  tables: ResourcesList<TrackingSchema>,
  trackingSchemaId: ?ID,
  match: Match,
  history: History,
|}

class TrackingSchemasToolbarView extends React.PureComponent<Props> {
  // eslint-disable-next-line
  onSelectTable = ({ value: trackingSchemaId }: { value: ID }) => {
    const { match, history } = this.props
    history.pushWithParams(match, { trackingSchemaId })
  }

  render(): React$Node {
    const { tables, trackingSchemaId, projectId } = this.props

    const options = sortTrackingSchemasOptions(tables, 'sh')

    return (
      <div className={classes.TopToolbarContainer}>
        {projectId ? (
          <Permission actions={['projet_follow-up_table management_create']}>
            <ModalTrigger
              modal={
                <ModalEditTrackingSchemaTable
                  projectId={projectId}
                  handleNewTrackingSchema={(trackingSchema) => {
                    this.onSelectTable({ value: trackingSchema?.id || '' })
                  }}
                />
              }
            >
              <MUIButton color="primary" data-testid="followup-button-new-table">
                Create new table
              </MUIButton>
            </ModalTrigger>
          </Permission>
        ) : null}
        <Autocomplete
          options={options}
          fuseConfig={{ keys: ['label', 'label.props.children'] }}
          placeholder="Search an existing table"
          selected={trackingSchemaId}
          onChange={this.onSelectTable}
          searchable={true}
          style={{ marginLeft: '10px' }}
          cy="followup-table-list"
        />
      </div>
    )
  }
}

const pipeInst: Pipe<{ trackingSchemaId: ?ID }, typeof TrackingSchemasToolbarView> = pipe()

export const TrackingSchemasToolbar: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { match, history } = props
    const projectId = state.project ? state.project.id : null
    const tables = getResources(state, 'trackingSchemas', { project: projectId })
    return {
      trackingSchemaId: props.trackingSchemaId,
      projectId,
      tables,
      match,
      history,
    }
  })
  .render(({ location, ...rest }) => <TrackingSchemasToolbarView {...rest} />)
