/** @flow */
import React, { Fragment, useState, useEffect, useMemo } from 'react'
import { startsWith, sortBy } from 'lodash'
import type { ID } from 'app/core/types'
import { durationStr } from 'app/components/Duration/Duration.tsx'
import { getResources } from 'app/store/selectors'
import { MUIButton } from 'app/components/Form'

import { ModalAssignedUsers } from './ModalAssignedUsers.jsx'
import { mountModal } from '../../TableView/Elements/ModalEdition.jsx'
import getProjectIdFromURL from '../../../../core/utils/getProjectIdFromURL.js'
import type { UseFiltersOutput } from '../../hooks/useFilters'

type Props = {|
  users: Array<{
    fAssignedUser__uuid: ID,
    fAssignedUser__name: string,
    count: number,
    estimLength__total: number,
    frame__total: number,
    activities_total: number,
  }>,
  setFilters?: $ElementType<UseFiltersOutput, 'setUrlFilters'>,
  columnName?: string,
  tableId: ?string,
  hiddenColumns?: Array<number>,
|}

export function DisplayAssignedUsers(props: Props): React$Node {
  const { users, setFilters, columnName, tableId, hiddenColumns } = props

  const [value, setValue] = useState('')
  const [_users, setUsers] = useState([])

  function onChange(e: SyntheticInputEvent<HTMLInputElement>) {
    setValue(e.target.value)
  }

  function sortUsers(users) {
    const sortedUsers = sortBy(
      users.filter(({ fAssignedUser__name }) => startsWith(fAssignedUser__name, value)),
      ['fAssignedUser__name'],
    )
    setUsers(sortedUsers)
  }

  const projectId = getProjectIdFromURL()
  const currentProject = useMemo(() => getResources(undefined, 'assets', projectId), [projectId])
  const framerate = currentProject?.attributes?.framerate || currentProject?.attributes?.fps

  useEffect(() => {
    if (users) sortUsers(users)
  }, [value, users])

  function filterByAssignedUser(workerId) {
    if (!workerId || !columnName) return
    const filters = btoa(JSON.stringify([[columnName || 'Assigned user', 'inList', workerId]]))
    setFilters?.(filters, true, true)
  }

  const totalAssign = (users || []).reduce((count, user) => count + user.count, 0)

  return (
    <div style={{ height: '100%' }}>
      <div className="marginBottom5 flex spaceBetween" style={{ maxWidth: '100%', height: 30 }}>
        <input
          placeholder="Find by name..."
          value={value}
          onChange={onChange}
          style={{ maxWidth: '100%' }}
          data-testid="input-findAssignUser-stats"
        />
        <MUIButton
          onClick={() => mountModal(ModalAssignedUsers, tableId, { users: _users, framerate, hiddenColumns })}
          icon="fas-chart-pie"
          dataCy="stats-open-pie"
        />
      </div>
      <div
        className="fullHeight overflowYAuto relative"
        style={{ height: 'calc(100% - 30px)', border: '1px solid lightgrey' }}
      >
        <div
          className={`grid col${hiddenColumns ? 8 - (hiddenColumns?.length || 0) : 8}`}
          style={{ gridRowGap: 0, gridColumnGap: 2 }}
        >
          {hiddenColumns?.includes(0) ? null : (
            <div
              title="Name"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Name
            </div>
          )}
          {hiddenColumns?.includes(1) ? null : (
            <div
              title="Estim. sum"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Estim. sum
            </div>
          )}
          {hiddenColumns?.includes(2) ? null : (
            <div
              title="Frames"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Frames
            </div>
          )}
          {hiddenColumns?.includes(3) ? null : (
            <div
              title="Secondes"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Secondes
            </div>
          )}
          {hiddenColumns?.includes(4) ? null : (
            <div
              title="sec/day estim"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              sec/day estim
            </div>
          )}
          {hiddenColumns?.includes(5) ? null : (
            <div
              title="% assigned"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              % assigned
            </div>
          )}
          {hiddenColumns?.includes(6) ? null : (
            <div
              title="Assignements number"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Assignements number
            </div>
          )}
          {hiddenColumns?.includes(7) ? null : (
            <div
              title="Activities"
              className="textCenter sticky textNoWrap"
              style={{ backgroundColor: 'lightgrey', top: 0, padding: '1px 5px' }}
            >
              Activities
            </div>
          )}
          {_users.map((userData) => {
            const {
              fAssignedUser__uuid,
              fAssignedUser__name,
              count = 0,
              estimLength__total = 0,
              frame__total = 0,
              activities_total = 0,
            } = userData

            const seconds = framerate ? Math.round((frame__total / framerate) * 100) / 100 || 0 : '?'
            const secDay = framerate
              ? Math.round((frame__total / framerate / (estimLength__total / 60 / 60 / 7.8)) * 100) / 100 || 0
              : '?'
            const percAssigned = Math.round(((100 * count) / totalAssign) * 100) / 100 || 0

            return (
              <Fragment key={fAssignedUser__name}>
                {hiddenColumns?.includes(0) ? null : (
                  <div
                    style={{ padding: '1px 2px', whiteSpace: 'nowrap', cursor: 'pointer' }}
                    onClick={() => filterByAssignedUser(fAssignedUser__uuid)}
                  >
                    {fAssignedUser__name}
                  </div>
                )}
                {hiddenColumns?.includes(1) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>
                    {durationStr(Math.round(estimLength__total), 7.8)}
                  </div>
                )}
                {hiddenColumns?.includes(2) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{frame__total}</div>
                )}
                {hiddenColumns?.includes(3) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{seconds}</div>
                )}
                {hiddenColumns?.includes(4) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{secDay}</div>
                )}
                {hiddenColumns?.includes(5) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{percAssigned}%</div>
                )}
                {hiddenColumns?.includes(6) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{count}</div>
                )}
                {hiddenColumns?.includes(7) ? null : (
                  <div style={{ padding: '1px 2px', whiteSpace: 'nowrap' }}>{durationStr(activities_total, 7.8)}</div>
                )}
              </Fragment>
            )
          })}
        </div>
      </div>
    </div>
  )
}
