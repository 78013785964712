var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/** @flow */
import { useEffect, useMemo, useState } from 'react';
import { MUIButton, Input, MUISelect } from 'app/components/Form';
import { map, filter } from 'lodash';
import resources from 'app/store/resources';
import { getResources } from 'app/store/selectors';
import { confirmDelete } from 'app/components/Modal/index.js';
import { requestIsFulfilled } from 'app/core/utils/getRequestStatus';
import { TreeDnD } from 'app/components/TreeDnD/TreeDnD.jsx';
import { Node, NODE_DIRECTORY } from 'app/components/TreeDnD/Node';
import { useDispatch, useSelector } from 'react-redux';
import { getCalculatedFieldNode } from './getCalculatedFieldNode.jsx';
import ModalCreateCalculatedField from './ModalCreateCalculatedField';
import classes from './CalculatedFields.module.scss';
import { parse } from './calculatedFieldParser.js';
import { FormulaItem } from './FormulaItem.jsx';
import formulaItemClasses from './FormulaItem.module.scss';
import { fetchCalculatedFields, deleteCalculatedField, createCalculatedField, updateCalculatedField, setSearch, } from '../../../../../store/reducers/calculatedFields';
export const aggregationTypes = [
    { value: 'children', label: 'Childrens' },
    { value: 'assetLink', label: 'Asset links' },
    { value: 'childrenAssetLink', label: 'Childrens asset links' },
];
export function CalculatedFields(props) {
    const { projectId } = props;
    const dispatch = useDispatch();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [assetLinkType, setAssetLinkType] = useState();
    const [selected, setSelected] = useState(null);
    const [aggregation, setAggregation] = useState(null);
    const { calculatedFields: { data: calculatedFields, loading: cfLoading }, search, } = useSelector((state) => state.calculatedFields);
    const assetLinkTypes = getResources(undefined, 'assetLinkTypes', {
        name: 'breakdown',
    });
    const selectedCf = calculatedFields.find((cf) => cf.id === selected);
    useEffect(() => {
        setAssetLinkType(map(assetLinkTypes)[0]);
    }, [assetLinkTypes]);
    useEffect(() => {
        const params = { queries: { page_size: 1000 } };
        dispatch(fetchCalculatedFields({ projectId, params }));
        resources.assetLinkTypes.fetchAll();
    }, []);
    const onDelete = (calculatedField, index) => {
        confirmDelete({
            render: `Are you sure you want to delete ${calculatedField.name} ?`,
            onValidate: () => dispatch(deleteCalculatedField(calculatedField.id)),
        });
    };
    const onValidate = (calculatedField) => __awaiter(this, void 0, void 0, function* () {
        const data = Object.assign(Object.assign({}, calculatedField), { project: projectId, definition: calculatedField.definition });
        const response = yield dispatch(calculatedField.id ? updateCalculatedField(data) : createCalculatedField(data));
        if (requestIsFulfilled(response)) {
            setModalIsOpen(false);
        }
    });
    const filteredCalculatedFields = useMemo(() => filter(calculatedFields, (calculatedField) => calculatedField.name.includes(search)), [search, calculatedFields]);
    const parsedFormulaItems = parse(selected ? calculatedFields === null || calculatedFields === void 0 ? void 0 : calculatedFields[selected] : undefined);
    const rootNode = useMemo(() => {
        const rootNode = new Node({
            key: 'calculatedFields-root',
            name: 'Calculated fields',
            parent: undefined,
            data: null,
            type: NODE_DIRECTORY,
            children: map(filteredCalculatedFields, (cf, index) => getCalculatedFieldNode({
                onDelete: (calculatedField) => onDelete(calculatedField, index),
                onSelect: () => setSelected(cf.id),
                calculatedField: cf,
                aggregation,
                assetLinkType,
            })),
        });
        return rootNode;
    }, [filteredCalculatedFields, assetLinkType, aggregation]);
    return (_jsxs("div", { className: classes.container, "data-testid": "calculatedFields", children: [_jsxs("div", { className: classes.toolbar, children: [_jsx("div", { children: _jsx(Input, { placeholder: "Search in calculated fields", value: search, onChange: (e) => {
                                dispatch(setSearch(e.target.value));
                                setSelected(null);
                            }, style: { width: 300 }, dataCy: "calculatedFields-search" }) }), _jsxs("div", { className: "flex row noWrap", children: [_jsxs("div", { className: "relative fullWidth", children: [_jsx("div", { className: classes.aggregationLabel, children: "Aggregation" }), _jsx(MUISelect, { style: { minWidth: 160 }, dataCy: "calculatedFields-aggregateOpts", options: [...aggregationTypes, { value: null, label: 'None' }], value: aggregation, onChange: (opt) => setAggregation(opt.value) })] }), _jsx(MUIButton, { icon: "fas-plus", tooltip: "Create a calculated field", style: { marginLeft: 5 }, dataCy: "calculatedFields-create", onClick: () => {
                                    setSelected(null);
                                    setModalIsOpen(true);
                                } })] })] }), _jsxs("div", { className: classes.subContainer, children: [_jsxs("div", { className: classes.scrollBlock, "data-testid": "calculatedFields-listItems", children: [!cfLoading ? _jsx(TreeDnD, { rootNode: rootNode, nonReorderable: true }) : null, !filteredCalculatedFields.length && _jsx("div", { className: classes.noAttributeItem, children: "No calculated field" })] }), selectedCf ? (_jsxs("div", { className: classes.displayAttributeContainer, children: [_jsxs("div", { className: "flex row noWrap", style: { float: 'right' }, children: [_jsx(MUIButton, { icon: "fas-edit", variant: "text", onClick: () => setModalIsOpen(true), tooltip: `Update ${selectedCf.name}` }), _jsx(MUIButton, { icon: "fas-times", variant: "text", onClick: () => setSelected(null), tooltip: "Close" })] }), _jsxs("p", { className: classes.attributeInfo, children: [_jsx("span", { children: "Name:" }), " ", _jsx("span", { "data-testid": "calculatedField-name", children: selectedCf.name })] }), _jsxs("p", { className: classes.attributeInfo, children: [_jsx("span", { children: "Formula:" }), ' ', _jsx("span", { "data-testid": "calculatedField-formula", className: formulaItemClasses.formulaItemContainer, children: parsedFormulaItems.map((item, index) => (_jsx(FormulaItem, { formulaItem: item, index: index }, String(index)))) })] })] })) : null] }), modalIsOpen && (_jsx(ModalCreateCalculatedField, { calculatedField: selectedCf, setModalIsOpen: setModalIsOpen, onValidate: onValidate, cfLoading: cfLoading }))] }));
}
