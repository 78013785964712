// @flow
import * as React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import makeStyles from '@material-ui/styles/makeStyles'
import Button from '@material-ui/core/Button'
import type { ElementProps, CyProps, IconType } from 'app/core/types'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'

type Props = {|
  ...ElementProps,
  ...CyProps,
  action?: Function,
  buttonRef?: Function | Object,
  centerRipple?: boolean,
  children?: React.Element<any> | string | number | mixed,
  classes?: Object,
  component?: string | React.ComponentType<any>,
  disabled?: boolean,
  bgColor?: string,
  labelColor?: string,
  disableRipple?: boolean,
  disableTouchRipple?: boolean,
  disableElevation?: boolean,
  disableFocusRipple?: boolean,
  focusVisibleClassName?: string,
  onFocusVisible?: Function,
  TouchRippleProps?: Object,
  type?: string,
  style?: Object,
  tabIndex?: string | number,
  onClick?: Function,
  className?: string,
  tooltip?: string | React.Element<any>,
  tooltipProps?: Object,
  tooltipStyle?: Object,
  iconStyle?: Object,
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'validate' | 'error',
  fullWidth?: boolean,
  href?: string,
  mini?: boolean,
  size?: 'small' | 'medium' | 'large',
  variant?: 'text' | 'outlined' | 'contained',
  to?: string,
  icon?: IconType,
  loader?: boolean,
|}

const useStyles = makeStyles((theme) => ({
  root: ({ color, bgColor, disableElevation, disableRipple }) => ({
    '&:hover': {
      backgroundColor: bgColor,
      color: color || (bgColor && '#ffffff') || 'initial',
      boxShadow: '0 0 1000px rgba(0,0,0,0.1) inset',
    },
    '&:focus-within': disableRipple
      ? {
          backgroundColor: bgColor,
          color: color || '#ffffff',
          boxShadow: '0 0 1000px rgba(0,0,0,0.1) inset',
        }
      : {},
    '&:focus': disableRipple
      ? {
          backgroundColor: bgColor,
          color: color || (bgColor && '#ffffff') || 'initial',
          boxShadow: '0 0 1000px rgba(0,0,0,0.1) inset',
        }
      : {},
    '&:active': {
      boxShadow: disableElevation
        ? disableRipple
          ? '0 0 1000px rgba(0,0,0,0.3) inset'
          : undefined
        : '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);',
    },
    backgroundColor: bgColor,
    color: color || (bgColor && '#ffffff') || 'initial',
  }),
  label: {
    fontSize: 14,
  },
}))

export function MUIButton({
  children,
  tooltip,
  variant,
  size,
  color,
  style,
  to,
  icon,
  loader,
  bgColor,
  labelColor,
  classes,
  tooltipProps,
  tooltipStyle,
  iconStyle = {},
  disableElevation,
  disableRipple,
  // $FlowFixMe[prop-missing] $FlowFixMe Error when updating flow
  showloader,
  dataCy,
  buttonRef,
  ...props
}: Props): React$Node {
  const colorsClasses = useStyles({ color: labelColor, bgColor, disableElevation, disableRipple })

  let ButtonComp = (
    <Button
      variant={variant || 'contained'}
      size={size || 'small'}
      color={color || 'default'}
      disabled={loader}
      style={{ minWidth: 30, minHeight: 30, ...style }}
      classes={{
        ...classes,
        root: cx([(bgColor || labelColor) && colorsClasses.root, classes && classes.root]),
        label: colorsClasses.label,
      }}
      {...props}
      component={to ? Link : 'button'}
      data-testid={cyLabelFormater('button', dataCy || children || tooltip)}
      to={to}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      ref={buttonRef}
    >
      {loader ? (
        <LoaderSmall style={{ marginRight: 5 }} />
      ) : icon ? (
        <FontIcon icon={icon} style={children ? { marginRight: 5, ...iconStyle } : iconStyle} />
      ) : null}
      {children}
    </Button>
  )

  if (tooltip && !props.disabled)
    ButtonComp = (
      <Tooltip style={tooltipStyle} title={tooltip} {...tooltipProps}>
        <span>{ButtonComp}</span>
      </Tooltip>
    )

  return ButtonComp
}
