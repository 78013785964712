var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import * as React from 'react';
import cx from 'classnames';
import classes from './Duration.module.scss';
import { Validators } from '../Form';
// TODO : convert this component to functional component OR update this.refs (actually deprecated)
export class Duration extends React.Component {
    constructor(props) {
        super(props);
        this.units = {
            d: (this.props.hoursInDay || 7.8) * 3600,
            h: 3600,
            m: 60,
        };
        this.initValues = (value) => {
            const { hide = {} } = this.props;
            const md = hide.days === true ? value : value % this.units.d;
            const mh = hide.hours === true ? value : md % this.units.h;
            return {
                d: hide.days === true ? 0 : Math.trunc(value / this.units.d),
                h: hide.hours === true ? 0 : Math.trunc(md / this.units.h),
                m: hide.minutes === true ? 0 : Math.trunc(mh / this.units.m),
            };
        };
        this.onChange = (unit) => (event) => {
            if (Number.isNaN(Number(event.currentTarget.value)) || Number(event.currentTarget.value) > Number.MAX_SAFE_INTEGER)
                return;
            const { onChange } = this.props;
            const newState = { [unit]: event.currentTarget.value };
            this.setState(newState, () => {
                if (onChange)
                    onChange(this.getSum());
            });
        };
        this.getSum = () => {
            const { d, h, m } = this.state;
            return d * this.units.d + h * this.units.h + m * this.units.m;
        };
        this.onFocus = (unit) => (event) => {
            const { onFocus } = this.props;
            if (onFocus)
                onFocus(event);
            // @ts-ignore
            this.setState({ focus: unit }, () => this.refs[unit].select());
        };
        this.onBlur = (event) => {
            const { onChange, onBlur } = this.props;
            if (event.relatedTarget &&
                // @ts-ignore
                !event.relatedTarget.isEqualNode(this.refs.d) &&
                // @ts-ignore
                !event.relatedTarget.isEqualNode(this.refs.h) &&
                // @ts-ignore
                !event.relatedTarget.isEqualNode(this.refs.m) &&
                // @ts-ignore
                !event.relatedTarget.isEqualNode(this.refs.additionnalInput)) {
                const value = this.getSum();
                if (onChange)
                    onChange(value);
                if (onBlur)
                    onBlur(value, event);
            }
        };
        this.onKeyDown = (unit) => (event) => {
            const { onDelete, onEnter, onCancel } = this.props;
            const { focus } = this.state;
            switch (event.key) {
                case 'ArrowUp':
                    event.nativeEvent.stopImmediatePropagation();
                    if (Number(this.state[unit]) >= Number.MAX_SAFE_INTEGER)
                        break;
                    this.setState((state) => ({ [unit]: Number(state[unit]) >= 0 ? Number(state[unit]) + 1 : 0 }));
                    break;
                case 'ArrowDown':
                    event.nativeEvent.stopImmediatePropagation();
                    this.setState((state) => ({ [unit]: Number(state[unit]) > 1 ? Number(state[unit]) - 1 : 0 }));
                    break;
                case 'Backspace':
                    // @ts-ignore
                    if (!this.state[unit] && (focus === 'm' || focus === null))
                        this.refs.h && this.refs.h.select();
                    // @ts-ignore
                    if (!this.state[unit] && focus === 'h')
                        this.refs.d && this.refs.d.select();
                    if (!this.state[unit] && focus === 'd' && onDelete)
                        onDelete(event);
                    break;
                case 'Escape':
                    if (onCancel)
                        onCancel();
                    break;
                case 'Enter':
                    if (onEnter)
                        onEnter(this.getSum(), event);
                    break;
                default:
                    break;
            }
        };
        this.state = Object.assign(Object.assign({}, this.initValues(!props.value || Number.isNaN(props.value) ? 0 : props.value)), { focus: null, error: null });
    }
    componentDidMount() {
        const { inputsRef } = this.props;
        if (inputsRef)
            inputsRef(this.refs);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState(this.initValues(nextProps.value || 0));
        }
    }
    render() {
        const _a = this.props, { hoursInDay, onDelete, onEnter, onCancel, value, onBlur, isRequired, validators, validatorRef, inputsRef, inputsProps, inputsClassName, hide = {}, disabled, onChange, center, additionnalInput } = _a, props = __rest(_a, ["hoursInDay", "onDelete", "onEnter", "onCancel", "value", "onBlur", "isRequired", "validators", "validatorRef", "inputsRef", "inputsProps", "inputsClassName", "hide", "disabled", "onChange", "center", "additionnalInput"]);
        const { d, h, m, error } = this.state;
        function getLenght(value) {
            return Math.trunc(value).toString().length;
        }
        const dWidth = `calc(${getLenght(d)}ch + 15px)`;
        const hWidth = `calc(${getLenght(h)}ch + 15px)`;
        const mWidth = `calc(${getLenght(m)}ch + 15px)`;
        return (_jsxs("div", Object.assign({ className: cx(classes.container, error ? classes.error : undefined, disabled ? classes.disabled : undefined) }, props, { children: [hide.days !== true ? (_jsxs("div", { className: "flex alignCenter fullHeight flex1", style: { minWidth: dWidth }, children: [_jsx("input", Object.assign({ "data-testid": "duration-input-d", className: cx(classes.input, inputsClassName), ref: "d", onChange: this.onChange('d'), style: { textAlign: 'end' }, onFocus: this.onFocus('d'), onBlur: this.onBlur, value: Math.trunc(d), onKeyDown: this.onKeyDown('d'), disabled: disabled }, inputsProps)), _jsx("span", { className: classes.unit, children: "d" })] })) : null, hide.hours !== true ? (_jsxs("div", { className: `flex alignCenter fullHeight ${hide.days ? 'flex1' : ''}`, style: { minWidth: hWidth }, children: [_jsx("input", Object.assign({ "data-testid": "duration-input-h", className: cx(classes.input, inputsClassName), ref: "h", onChange: this.onChange('h'), style: {
                                width: !hide.days ? `calc(${getLenght(h)}ch + 15px)` : undefined,
                                textAlign: 'end',
                            }, onFocus: this.onFocus('h'), onBlur: this.onBlur, value: Math.trunc(h), onKeyDown: this.onKeyDown('h'), placeholder: "0", disabled: disabled }, inputsProps)), _jsx("span", { className: classes.unit, children: "h" })] })) : null, hide.minutes !== true ? (_jsxs("div", { className: `flex alignCenter fullHeight ${hide.hours || center ? 'flex1' : ''}`, style: { minWidth: mWidth }, children: [_jsx("input", Object.assign({ "data-testid": "duration-input-m", className: cx(classes.input, inputsClassName), ref: "m", onChange: this.onChange('m'), style: { width: mWidth, textAlign: 'end' }, onFocus: this.onFocus('m'), onBlur: this.onBlur, value: Math.trunc(m), onKeyDown: this.onKeyDown('m'), placeholder: "0", disabled: disabled }, inputsProps)), _jsx("span", { className: classes.unit, children: "m" })] })) : null, error ? _jsx("div", { className: classes.errorLabel, children: error }) : null, _jsx(Validators, { ref: (c) => {
                        if (validatorRef)
                            validatorRef(c);
                    }, className: classes.fieldError, value: this.getSum() > 0 ? this.getSum() : undefined, onSuccess: () => this.setState({ error: null }), onError: (error) => this.setState({ error }), validators: Object.assign({ required: isRequired }, validators), hide: true }), additionnalInput === null || additionnalInput === void 0 ? void 0 : additionnalInput({
                    onFocus: (val) => this.onFocus('additionnalInput')(val),
                    onKeyDown: (event) => {
                        const { onEnter, onCancel } = this.props;
                        switch (event.key) {
                            case 'Escape':
                                if (onCancel)
                                    onCancel();
                                break;
                            case 'Enter':
                                if (onEnter && !event.shiftKey)
                                    onEnter(this.getSum(), event);
                                break;
                            default:
                                break;
                        }
                    },
                    onBlur: this.onBlur,
                    ref: 'additionnalInput',
                })] })));
    }
}
Duration.defaultProps = {
    value: 0,
    hoursInDay: 7.8,
};
export const durationStr = (value, hoursInDay, hide = {}, format) => {
    const units = {
        day: (hoursInDay || 7.8) * 3600,
        hour: 3600,
        minute: 60,
    };
    const minusDays = hide.days === true ? value : value % units.day;
    const minusHours = hide.hours === true ? minusDays : minusDays % units.hour;
    const days = Math.trunc(value / units.day);
    const hours = Math.trunc(minusDays / units.hour);
    const minutes = Math.trunc(minusHours / units.minute);
    if (format) {
        return format
            .replace('[D]', String(Math.abs(days)))
            .replace('[H]', String(Math.abs(hours)))
            .replace('[M]', String(Math.abs(minutes)));
    }
    return `${(value || 0) < 0 ? '-' : ''}${hide.days !== true ? `${Math.abs(days)}d ` : ''}${hide.hours !== true ? `${Math.abs(hours)}h ` : ''}${hide.minutes !== true ? `${Math.abs(minutes)}m` : ''}`;
};
export function DurationRead(_a) {
    var { value, hoursInDay = 7.8, hide } = _a, props = __rest(_a, ["value", "hoursInDay", "hide"]);
    if ((!value && value !== 0) || Number.isNaN(value))
        return null;
    let hiddenValues = {};
    if (hide)
        hiddenValues = hide;
    else if (value < hoursInDay * 3600) {
        hiddenValues = { days: true };
    }
    return durationStr(value, hoursInDay, hiddenValues);
}
